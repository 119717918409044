import moment from 'moment'
import { autorun, computed, decorate, observable } from 'mobx'

import { convertMultiValue, SingletonStore, CaseClient } from 'common'

/**
 * Data store for the total number of open cases.
 * @extends SingletonStore
 */
const TotalOpenCases = class extends SingletonStore {
  /**
   * Invoke the super class's constructor, configure autorun behaviors.
   */
  constructor() {
    super()

    autorun(() => {
      this.refresh()
    })
  }

  // Observable
  initialized = false
  group

  /**
   * Computed
   * Compute the total number of open cases.
   * @return {Integer} the total number of open cases
   */
  get total() {
    if (!this.result || !this.result.length) return 0
    return this.result[0].count
  }

  /**
   * Fetch the results from the server.
   * @return {Promise} an HTTP Promise
   */
  fetch() {
    if (!this.initialized) return []

    const params = {
      groupBy: 'resolution',
      resolution: 'null',
    }

    if (this.group) params.group = convertMultiValue(this.group)

    return CaseClient.countBy(params)
  }
}

decorate(TotalOpenCases, {
  initialized: observable,
  group: observable,
  total: computed,
})

const totalOpenCases = new TotalOpenCases()

/**
 * Data store for the number of open cases assigned to a specific owner.
 * @extends SingletonStore
 */
class OwnerOpenCases extends SingletonStore {
  /**
   * Invoke the super class's constructor, configure autorun behaviors.
   */
  constructor() {
    super()

    autorun(() => {
      this.refresh()
    })
  }

  // Observables
  initialized
  owner
  group

  /**
   * Computed
   * Compute the total number of open cases.
   * @return {Integer} the total number of open cases
   */
  get total() {
    if (!this.result || !this.result.length) return 0

    return this.result.find(c => !c.group).count
  }

  /**
   * Fetch the results from the server.
   * @return {Promise} an HTTP Promise
   */
  fetch() {
    if (!this.initialized) return []
    const owner = this.owner

    const params = {
      groupBy: 'resolution',
      resolution: 'null',
    }

    if (owner) params.owner = owner
    if (this.group) params.group = convertMultiValue(this.group)

    return CaseClient.countBy(params)
  }
}

decorate(OwnerOpenCases, {
  initialized: observable,
  owner: observable,
  group: observable,
  total: computed,
})

const ownerOpenCases = new OwnerOpenCases()

/**
 * Data store for the number of open cases with no assigned owner.
 * @extends SingletonStore
 */
class UnassignedOpenCases extends SingletonStore {
  /**
   * Invoke the super class's constructor, configure autorun behaviors.
   */
  constructor() {
    super()

    autorun(() => {
      this.refresh()
    })
  }

  // Observable
  initialized = false
  group

  /**
   * Computed
   * Compute the total number of unassigned open cases.
   * @return {Integer} the total number of unassigned open cases
   */
  get total() {
    if (!this.result || !this.result.length) return 0

    return this.result.find(c => !c.group).count
  }

  /**
   * Fetch the results from the server.
   * @return {Promise} an HTTP Promise
   */
  fetch() {
    if (!this.initialized) return []

    const params = {
      groupBy: 'resolution',
      resolution: 'null',
      owner: 'null',
    }

    if (this.group) params.group = convertMultiValue(this.group)

    return CaseClient.countBy(params)
  }
}

decorate(UnassignedOpenCases, {
  initialized: observable,
  total: computed,
  group: observable,
})

const unassignedOpenCases = new UnassignedOpenCases()

/**
 * Data store for the number of recently changed cases. Filterable by owner.
 * @extends SingletonStore
 */
class RecentlyChangedOpenCases extends SingletonStore {
  /**
   * Invoke the super class's constructor, configure autorun behaviors.
   */
  constructor() {
    super()

    autorun(() => {
      this.refresh()
    })
  }

  // Observables
  initialized = false
  owner
  group
  lastModified = moment().subtract(6, 'days')

  /**
   * Computed
   * Compute the total number of recently changed cases.
   * @return {Integer} the total number of recently changed cases
   */
  get total() {
    if (!this.result || !this.result.length) return 0
    return this.result.map(c => c.count).reduce((a, b) => a + b)
  }

  /**
   * Fetch the results from the server.
   * @return {Promise} an HTTP Promise
   */
  fetch() {
    if (!this.initialized) return []
    const params = {
      groupBy: 'resolution',
      resolution: 'null',
      lastModifiedAfter: this.lastModified,
    }

    if (this.owner) {
      params.owner = this.owner
    }
    if (this.group) params.group = convertMultiValue(this.group)

    return CaseClient.countBy(params)
  }
}

decorate(RecentlyChangedOpenCases, {
  initialized: observable,
  lastModified: observable,
  owner: observable,
  total: computed,
})

const recentlyChangedOpenCases = new RecentlyChangedOpenCases()

/**
 * Primary data store for all open case reports.
 */
class OpenCasesStore {
  /**
   * Configure autorun methods, assign values to child data stores on change.
   */
  constructor() {
    autorun(() => {
      totalOpenCases.owner = this.owner
      totalOpenCases.group = this.group
      totalOpenCases.initialized = this.initialized

      recentlyChangedOpenCases.owner = this.owner
      recentlyChangedOpenCases.group = this.group
      recentlyChangedOpenCases.initialized = this.initialized

      ownerOpenCases.owner = this.owner
      ownerOpenCases.group = this.group
      ownerOpenCases.initialized = this.initialized

      unassignedOpenCases.owner = this.owner
      unassignedOpenCases.group = this.group
      unassignedOpenCases.initialized = this.initialized
    })
  }

  /**
   * Update the filtering options for the data store.
   * @param {Object} query - the new filters to apply
   */
  setFilters(query) {
    if (query.openCasesOwner === 'all') query.openCasesOwner = null
    if (query.group) this.group = query.group
    this.owner = query.openCasesOwner
    this.initialized = true
  }

  // Observables
  owner = null
  group = null
  initialized = false

  /**
   * Get the total number of open cases.
   * @return {Integer} the total number of cases
   */
  get total() {
    return totalOpenCases.total
  }

  /**
   * Get the total number of open cases for a specific owner. Defaults to 'all'
   * users (similary to this.total)
   * @return {Integer} the total number of cases
   */
  get ownerTotal() {
    if (this.owner) return ownerOpenCases.total
    else return ownerOpenCases.total - this.unassignedTotal
  }

  /**
   * Get the total number of unassigned cases.
   * @return {Integer} the total number of cases
   */
  get unassignedTotal() {
    return unassignedOpenCases.total
  }

  /**
   * Get the total number of recently modified cases.
   * @return {Integer} the total number of cases
   */
  get recentlyModifiedTotal() {
    return recentlyChangedOpenCases.total
  }

  /**
   * Evaluate whether or not the child stores are still loading.
   * @return {Boolean} result of the child store loading statuses
   */
  get loading() {
    return (
      totalOpenCases.loading ||
      recentlyChangedOpenCases.loading ||
      ownerOpenCases.loading ||
      unassignedOpenCases.loading
    )
  }

  /**
   * Helper method to clear out all child stores.
   */
  clear() {
    totalOpenCases.clear()
    ownerOpenCases.clear()
    recentlyChangedOpenCases.clear()
    unassignedOpenCases.clear()
  }

  /**
   * Helper method to trigger the refresh method of the child stores.
   */
  refresh() {
    totalOpenCases.refresh()
    ownerOpenCases.refresh()
    recentlyChangedOpenCases.refresh()
    unassignedOpenCases.refresh()
  }
}

decorate(OpenCasesStore, {
  initialized: observable,
  owner: observable,
  group: observable,
})

export default new OpenCasesStore()
