import { action, decorate, observable, reaction } from 'mobx'
import moment from 'moment'

class MultiRecordCompositeSectionFilterStore {
  constructor() {
    this.disposer = reaction(
      () => [this.filterTerm],
      () => this.filterGroups()
    )
  }

  disposer

  // observables
  filterTerm = ''
  groups
  matchingGroups
  nonMatchingGroups
  searchVisible = false

  tearDown = () => this.disposer()

  // action
  setFilterTerm = term => (this.filterTerm = term)

  // action
  setGroups = groups => (this.groups = groups)

  // action
  setMatchingGroups = matchingGroups => (this.matchingGroups = matchingGroups)

  // action
  setNonMatchingGroups = nonMatchingGroups =>
    (this.nonMatchingGroups = nonMatchingGroups)

  // action
  setSearchVisible = searchVisible => (this.searchVisible = searchVisible)

  // action
  filterTieredValues = (tieredValues, groupId) => {
    const matchingTieredValues = []
    const searchTerm = this.filterTerm.toLowerCase()
    tieredValues.forEach(tieredValue => {
      if (tieredValue?.value?.toLowerCase().includes(searchTerm)) {
        return matchingTieredValues.push(groupId)
      }
      if (tieredValue?.type === 'ADDRESS') {
        const addressElements = [
          'address1',
          'address2',
          'city',
          'state',
          'zipCode',
        ]

        addressElements.forEach(element => {
          const addressElement = tieredValue?.addressDetails?.[element]
          if (typeof addressElement === 'string') {
            if (addressElement.toLowerCase().includes(searchTerm)) {
              return matchingTieredValues.push(groupId)
            }
          }
        })
      }
      // handles dates
      if (tieredValue?.type === 'DATE') {
        if (
          moment(tieredValue.date)
            .utc()
            .format('M/D/YYYY h:mm A')
            .toLowerCase()
            .includes(searchTerm)
        ) {
          return matchingTieredValues.push(groupId)
        }
      }
      // handles Prim. Care names under Encounters
      if (tieredValue?.links) {
        tieredValue.links.forEach(link => {
          if (link.personName.toLowerCase().includes(searchTerm)) {
            return matchingTieredValues.push(groupId)
          }
        })
      }
      if (tieredValue?.name) {
        if (tieredValue.name.toLowerCase().includes(searchTerm)) {
          return matchingTieredValues.push(groupId)
        }
      }
      // Checking child layers
      if (tieredValue?.tieredValues) {
        return matchingTieredValues.push(
          ...this.filterTieredValues(tieredValue?.tieredValues, groupId)
        )
      }
    })
    return matchingTieredValues
  }

  // action
  filterGroups = () => {
    const matchingGroupIds = []
    const searchTerm = this.filterTerm.toLowerCase()
    const groups = (this.groups || []).filter(g => g.fields)
    if (groups.length > 0) {
      groups.forEach((group, idx) => {
        // adding id to each group so we can filter duplicates
        group.id = idx + 1
        if (group?.fieldName?.toLowerCase().includes(searchTerm)) {
          return matchingGroupIds.push(group.id)
        }
        group.fields.forEach(field => {
          if (field?.value?.toLowerCase().includes(searchTerm)) {
            return matchingGroupIds.push(group.id)
          } else if (field.tieredValues) {
            return matchingGroupIds.push(
              ...this.filterTieredValues(field.tieredValues, group.id)
            )
          }
          return field
        })
      })

      this.setNonMatchingGroups(
        groups.filter(group => !matchingGroupIds.includes(group.id))
      )
      this.setMatchingGroups(
        groups.filter(group => matchingGroupIds.includes(group.id))
      )
    }
  }
}

decorate(MultiRecordCompositeSectionFilterStore, {
  filterTerm: observable,
  groups: observable,
  matchingGroups: observable,
  nonMatchingGroups: observable,
  searchVisible: observable,
  setFilterTerm: action,
  setGroups: action,
  setMatchingGroups: action,
  setNonMatchingGroups: action,
  setSearchVisible: action,
  filterGroups: action,
})

export { MultiRecordCompositeSectionFilterStore }
