import moment from 'moment'
import { autorun, computed, decorate, observable } from 'mobx'

import { convertMultiValue, SingletonStore, CaseClient } from 'common'

/**
 * Data store for components that need to access information about the age of cases.
 * @extends SingletonStore
 */
class CaseAgesStore extends SingletonStore {
  /**
   * Invoke the super class's constructor, configure autorun methods.
   */
  constructor() {
    super()

    autorun(() => {
      this.refresh()
    })
  }

  // Observables
  initialized = false
  owner = null
  selectedSegment = null
  group = null

  /**
   * Computed
   * Total up all range values and return that single value.
   * @return {Integer} total number of open cases
   */
  get total() {
    return (
      this.totalUpToFifteen +
      this.totalFifteenToThirty +
      this.totalThirtyToSixty +
      this.totalMoreThanSixty
    )
  }

  /**
   * Computed
   * Total number of open cases in the last fifteen days.
   * @return {Integer} total number of open cases
   */
  get totalUpToFifteen() {
    return this.findTotalFor(0, 15)
  }

  /**
   * Computed
   * Total number of open cases that are 16-30 days old.
   * @return {Integer} total number of open cases
   */
  get totalFifteenToThirty() {
    return this.findTotalFor(15, 30)
  }

  /**
   * Computed
   * Total number of open cases that are 1-2 months old.
   * @return {Integer} total number of open cases
   */
  get totalThirtyToSixty() {
    return this.findTotalFor(30, 60)
  }

  /**
   * Computed
   * Total number of open cases that are more than two months old.
   * @return {Integer} total number of open cases
   */
  get totalMoreThanSixty() {
    return this.findTotalFor(60, null)
  }

  /**
   * Extract the counts for the provided date range and return the sum of those
   * counts. Will return 0 if there are no results to evaluate.
   *
   * @param {Integer} endOffset - (optional) the end of the range being searched
   * @param {Integer} startOffset - (optional) the start of the range being searched
   * @return {Integer} the total for the provided range.
   */
  findTotalFor(endOffset, startOffset) {
    let startDate = null,
      endDate = null

    // no 0, undefined, null, or otherwise falsy startOffset values are
    // accepted. endOffset should always be bigger than 0, if present.
    if (startOffset)
      startDate = moment()
        .subtract(startOffset, 'days')
        .utc()
        .startOf('day')

    if (endOffset)
      endDate = moment()
        .subtract(endOffset, 'days')
        .utc()
        .startOf('day')
        .subtract(1, 'second')

    const dates = this.result?.filter(c => {
      const date = moment(c.group)
      if (startDate && endDate) {
        return date <= endDate && date >= startDate
      } else if (endDate) {
        return date <= endDate
      } else {
        return date >= startDate
      }
    })

    if (dates?.length) {
      return dates.map(c => c.count).reduce((a, b) => a + b)
    } else {
      return 0
    }
  }

  /**
   * Apply query parameters to the store's observed properties. Useful when
   * leveraging the store in an environment that receives parameters from a
   * page route.
   * @param {Object} query - an Object that holds the query parameters to
   *   apply to the store
   */
  setFilters(query) {
    if (query.caseAgesOwner === 'all') {
      query.caseAgesOwner = null
    }

    this.owner = query.caseAgesOwner
    this.initialized = true
    this.group = query.group
  }

  /**
   * Retrieve the counts for all open cases, grouped by create date.
   * @return {Promise} Promise object
   */
  fetch() {
    if (!this.initialized) return []

    const params = {
      groupBy: 'created',
      window: 'day',
      resolution: 'null',
    }

    if (this.owner) params.owner = this.owner
    if (this.group) params.group = convertMultiValue(this.group)

    return CaseClient.countBy(params)
  }
}

decorate(CaseAgesStore, {
  initialized: observable,
  owner: observable,
  group: observable,
  selectedSegment: observable,
  total: computed,
  totalUpToFifteen: computed,
  totalFifteenToThirty: computed,
  totalThirtyToSixty: computed,
  totalMoreThanSixty: computed,
})

export default new CaseAgesStore()
