import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import { HalUtils } from 'common'
import { Card } from '../../../ui/Card'
import UserCardHeader from './UserCardHeader'
import UserCardFields from './UserCardFields'
import classnames from 'classnames'
import styles from '../index.module.scss'
import SelectButton from '../SelectButton'

const UserCard = ({
  cases,
  disabled,
  onSelect,
  openLinkNew,
  selectable,
  selected,
  user,
  hideDob,
}) => {
  return (
    <Card
      className={classnames(styles.personCard, {
        'is-disabled': disabled,
        'is-selected': selected,
      })}
    >
      <UserCardHeader
        cases={cases}
        fullName={user.fullName}
        id={HalUtils.getId(user)}
        instance={user.instance}
        openLinkNew={openLinkNew}
        source={user.source}
        tags={user.tags}
      />
      <UserCardFields
        dateOfBirth={user.dateOfBirth}
        hideDob={hideDob}
        recentDepartment={user.recentDepartment}
        recentOrganization={user.recentOrganization}
        recentRoleName={user.recentRoleName}
        roles={user.roles}
        userId={user.userId}
      >
        {selectable && !disabled && (
          <SelectButton onSelect={onSelect} selected={selected} />
        )}
      </UserCardFields>
    </Card>
  )
}

UserCard.propTypes = {
  cases: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  openLinkNew: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  user: PropTypes.shape({
    dateOfBirth: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    instance: PropTypes.string,
    recentDepartment: PropTypes.string,
    recentOrganization: PropTypes.string,
    recentRoleName: PropTypes.string,
    roles: mobxPropTypes.arrayOrObservableArrayOf(
      PropTypes.shape({
        department: PropTypes.string,
        name: PropTypes.string,
        organization: PropTypes.string,
      })
    ),
    source: PropTypes.string,
    tags: mobxPropTypes.arrayOrObservableArrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      })
    ),
    userId: PropTypes.string,
  }).isRequired,
  hideDob: PropTypes.bool,
}

UserCard.defaultProps = {
  cases: [],
  disabled: false,
  onSelect: null,
  openLinkNew: false,
  selectable: false,
  selected: false,
}

export default UserCard
