import React from 'react'
import PropTypes from 'prop-types'
import { ToastContainer } from 'react-toastify'

const DefaultClose = ({ closeToast }) => (
  <button className="toastify__close" type="button" onClick={closeToast}>
    <i className="material-icons icon-close" />
  </button>
)

DefaultClose.propTypes = {
  closeToast: PropTypes.func,
}

/**
 * Stateless component for creating a container for toast notifications. Should
 * be injected into the Viewport (and only needs to be added once). Options set
 * on this container are defaults for the toast display, but can be overridden
 * by toast instances.
 *
 * Available options here:
 * https://www.npmjs.com/package/react-toastify#toastcontainer-type--react-component
 */
const NotificationContainer = () => (
  <ToastContainer
    position="bottom-left"
    type="default"
    autoClose={5000}
    closeOnClick={false}
    hideProgressBar
    newestOnTop={false}
    draggable={false}
    closeButton={<DefaultClose />}
  />
)

export default NotificationContainer
