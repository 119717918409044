import React from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { decorate, observable, computed } from 'mobx'
import { observer } from 'mobx-react'

import { Button, Input, MultilineInput } from '../../../ui'

import NotificationStore from '../../../stores/NotificationStore'
import UserTagsStore from '../stores/UserTagsStore'

import styles from './index.module.scss'

const UserTagCreateForm = observer(
  class UserTagCreateForm extends React.Component {
    static propTypes = {
      linkTo: PropTypes.string.isRequired,
      history: PropTypes.shape({
        replace: PropTypes.func,
      }),
    }

    // Observable
    name = null
    description = ''

    // Computed
    get canSubmit() {
      return this.validateName(this.name)
    }

    validateName = name => /[0-9a-zA-Z]/.test(name)

    onSubmit = e => {
      e.preventDefault()
      if (!this.canSubmit) return

      const name = this.name && this.name.trim()
      const description = this.description && this.description.trim()

      UserTagsStore.create({
        name,
        description,
      }).then(
        () => {
          const content = (
            <span>
              <i className="material-icons icon-check_circle" />
              Successfully added user tag
            </span>
          )
          NotificationStore.add({ level: 'success', content })
          this.props.history.replace('/user-tags')
        },
        () => {
          const content = (
            <span>
              <i className="material-icons icon-warning" />
              There was a problem creating this user tag
            </span>
          )
          NotificationStore.add({ level: 'warning', content })
        }
      )
    }

    // only set the canSubmit flag when the name value changes and isn't empty
    onNameChange = value => {
      this.name = value
    }

    onDescriptionChange = value => {
      this.description = value
    }

    render() {
      const { linkTo } = this.props

      return (
        <form className={styles.form} onSubmit={this.onSubmit}>
          <Input
            autoFocus
            autoFocusDelay={800}
            bordered
            error={!this.validateName(this.name)}
            label="Tag Name"
            margin="md"
            onChange={this.onNameChange}
            placeholder="Add a tag name"
            value={this.name || ''}
            name="create-user-tag-input"
          />
          <div className="input-section">
            <MultilineInput
              label="Description"
              placeholder="Add an optional description"
              value={this.description}
              onChange={this.onDescriptionChange}
              bordered
            />
          </div>
          <div className={styles.formActions}>
            <Button
              className="create-form__submit"
              type="submit"
              value="Create Tag"
              disabled={!this.name || !this.canSubmit}
            />
            <Link to={linkTo} className="button button--link">
              Cancel
            </Link>
          </div>
        </form>
      )
    }
  }
)

decorate(UserTagCreateForm, {
  name: observable,
  description: observable,
  canSubmit: computed,
})

UserTagCreateForm.displayName = 'UserTagCreateForm'

export default withRouter(UserTagCreateForm)
