import BrowserAdjustments from '../BrowserAdjustments'
import EventDetailedStore from '../../stores/EventDetailedStore'
import ChartStore from '../../stores/ChartStore'

/**
 * Layer responsible for drawing individual access circles. A sub-component of RowLayer.
 */
class CircleLayer {
  clickedEvent(metalog) {
    const curYKey = ChartStore.selectedKey
    const newYKey = ChartStore.yKey(metalog)
    const delay = curYKey === newYKey ? 0 : 500
    setTimeout(() => {
      EventDetailedStore.focusOn(metalog)
    }, delay)
  }

  render(selection, detailedData) {
    const me = this
    const transition = ChartStore.scrollTransition
    const dateCircle = selection.selectAll('g.access').data(
      d => detailedData[d.key] || [],
      d => d.id
    )
    // new nodes
    this.update(dateCircle, transition)
    const circles = dateCircle
      .enter()
      .append('g')
      .classed('access', true)
    circles
      .append('circle')
      .attr('r', '6px')
      .attr('data-cy', 'access-circle')
      .on('click', d => {
        me.clickedEvent(d)
      })

    this.update(circles, false)

    dateCircle.exit().remove()
  }

  update(selection, transition) {
    transition = transition !== false
    selection
      .classed('flagged', d => EventDetailedStore.isFlagged(d))
      .classed('drawer', d => d.id === EventDetailedStore.detailViewId)

    if (transition) selection = selection.transition().duration(500)

    selection.attr('transform', d => {
      const date = d.eventDate
      const x = ChartStore.brushedXScale(date) + ChartStore.margin.left
      const y =
        ChartStore.rowHeight / 2 - 0.33 + BrowserAdjustments.rowSpacing * 0.33
      const trans = `translate(${x},${y})`
      return trans
    })
  }
}

export default new CircleLayer()
