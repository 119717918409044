import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import { DropdownList } from 'react-widgets'
import { ValueComponent, ItemComponent } from './dropdownComponents'
import styles from './index.module.scss'

const Aliases = ({ aliases, current, disabled, findLink, mode }) => {
  const options = []

  const mapAliases = aliases =>
    aliases
      .map(alias => {
        return Object.assign(alias.people[1], {
          id: alias.ids[1].id,
          _links: alias.ids[1]._links,
          mode,
        })
      })
      .sort((a, b) => {
        const value1 = a?.source + '' + (a?.instance || '')
        const value2 = b?.source + '' + (b?.instance || '')

        if (value1 < value2) return -1
        else if (value1 > value2) return 1

        return 0
      })

  options.push(...mapAliases(aliases))

  // add the current user or patient to options as the first value
  if (current) options.unshift(current)

  return (
    <div className={styles.container} data-cy="alias-container">
      <DropdownList
        data={options}
        disabled={disabled || options.length === 1}
        value={options[0]}
        onChange={value => findLink(value)}
        valueComponent={ValueComponent}
        itemComponent={ItemComponent}
      />
    </div>
  )
}

const aliasProps = mobxPropTypes.arrayOrObservableArrayOf(
  PropTypes.shape({
    ids: mobxPropTypes.arrayOrObservableArrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        _links: PropTypes.shape({
          self: PropTypes.shape({
            href: PropTypes.string,
          }),
        }),
        people: PropTypes.shape({}),
      })
    ),
  })
)

Aliases.propTypes = {
  aliases: aliasProps.isRequired,
  current: PropTypes.shape({}),
  disabled: PropTypes.bool,
  findLink: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['user', 'patient']).isRequired,
}

Aliases.defaultProps = {
  disabled: false,
}

export default Aliases
