import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import styles from './index.module.scss'
import SmartReference from '../SmartReference'

const PrivacyAssessments = ({
  headline,
  reasons,
  linkTo,
  smartRefProps,
  suspicionScore,
  lastUpdatedByAG,
}) => {
  /* eslint-disable react/prop-types */
  const {
    caseId,
    caseNumber,
    maxEventDate,
    minEventDate,
    patientId,
    userId,
  } = smartRefProps

  return (
    <div className={styles.privacyWrapper}>
      <p className={styles.label}>Assessments</p>
      <div className={styles.reasonHeadline} data-cy="privacy-assessment-link">
        {linkTo ? (
          <Link to={linkTo}>
            {headline}
            <i className="material-icons icon-view_day forScreen" />
          </Link>
        ) : (
          <p>{headline}</p>
        )}
      </div>
      <div className={styles.splitColumn}>
        {reasons &&
          reasons.slice(1).map(reason => (
            <div className={styles.reason} key={Math.random()}>
              {smartRefProps ? (
                <SmartReference
                  caseId={caseId}
                  userId={userId}
                  description={reason.description}
                  maxEventDate={maxEventDate}
                  minEventDate={minEventDate}
                  patientId={patientId}
                  caseNumber={caseNumber}
                />
              ) : (
                reason.description
              )}
            </div>
          ))}
      </div>
      <div>
        {suspicionScore && (
          <div className={styles.reasonFooter}>
            Suspicion Score:{' '}
            {suspicionScore ? Math.round(suspicionScore * 100) : <i>Unknown</i>}
          </div>
        )}
        {lastUpdatedByAG && (
          <div className={styles.reasonFooter}>
            {' '}
            Assessments and suspicion score generated based on data available:{' '}
            {moment.utc(lastUpdatedByAG).format('l')}{' '}
          </div>
        )}
      </div>
    </div>
  )
}

PrivacyAssessments.propTypes = {
  headline: PropTypes.string,
  reasons: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({ description: PropTypes.string })
  ),
  linkTo: PropTypes.string,
  smartRefProps: PropTypes.shape({}), // validation already in place in SmartReference
  suspicionScore: PropTypes.number,
  lastUpdatedByAG: PropTypes.string,
}

PrivacyAssessments.defaultProps = {
  headline: '',
  reasons: [],
  suspicionScore: null,
  lastUpdatedByAG: null,
  linkTo: '',
  smartRefProps: {
    caseId: '',
    caseNumber: '',
    maxEventDate: '',
    minEventDate: '',
    patientId: '',
    userId: '',
  },
}

export default PrivacyAssessments
