import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import { Tooltip } from '../../../ui';
import styles from '../index.module.scss';
import cn from 'classnames';
import { FILTER_TARGET, NOT_APPLICABLE } from './const';

const NameCell = ({ location }) => {
  let name,
    showIcon = false;

  // Need to filter out location strings like 'mongodb:primary?database=...' for security reasons
  if (
    typeof location === 'string' &&
    location.length &&
    location.includes('/')
  ) {
    name = location.substr(location.lastIndexOf('/') + 1);
    showIcon = location.includes('backfill');
    FILTER_TARGET.forEach(t => (name = name.replace(t, '')));
  }

  return (
    <Cell>
      <div
        className={cn(styles.nameCell, {
          [styles.showIcon]: showIcon,
        })}
      >
        <span title={name} data-cy="fdd-delivery-table-name">{name || NOT_APPLICABLE}</span>
        {showIcon && (
          <Tooltip content="Backfill" placement="bottom">
            <i className="material-icons icon-restore" />
          </Tooltip>
        )}
      </div>
    </Cell>
  );
};

NameCell.propTypes = {
  location: PropTypes.string,
};

export default NameCell;
