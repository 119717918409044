import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './index.module.scss'

const Header = ({ topText, bottomText, extraText }) => {
  const { text, headerWrapper, label, headline, light } = styles
  return (
    <div className={headerWrapper} data-cy="entity-header-wrapper">
      <p className={cn(text, label)}>{topText}</p>
      <p className={cn(text, headline)}>
        {bottomText}
        {extraText && <span className={light}> {extraText}</span>}
      </p>
    </div>
  )
}

Header.propTypes = {
  topText: PropTypes.string,
  bottomText: PropTypes.string,
  extraText: PropTypes.string,
}

Header.defaultProps = {
  topText: 'Unknown',
  bottomText: 'Unknown',
  extraText: '',
}

export default Header
