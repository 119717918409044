import React from 'react'
import PropTypes from 'prop-types'
import { DropdownList } from 'react-widgets'
import { observer } from 'mobx-react'
import classnames from 'classnames'

import {
  RouterContainer,
  HalUtils,
  LoginStore,
  convertMultiValue,
} from 'common'

/**
 * Dropdown menu for the list of available app users.
 * @extends React.Component
 */
const AppUserSelect = observer(
  ({ paramName, users, value, meLabel, inverted, dropUp }) => {
    const options = () => {
      const userArray = [...users]
      // Prepend "All Users" and "My Label" to the front of the options array
      const options = [
        {
          value: 'all',
          label: 'All Users',
        },
        {
          value: LoginStore.id,
          label: meLabel,
        },
      ].concat(
        userArray.map(u => ({
          value: HalUtils.getId(u),
          label: `${u.firstName} ${u.lastName}`,
        }))
      )
      const currentUserIndex = options.findIndex(
        o => o && o.value === LoginStore.id && o.label === LoginStore.fullName
      )

      if (currentUserIndex !== -1) options.splice(currentUserIndex, 1)

      return options
    }

    /**
     * Utility method to help combine new query parameters with existing query
     * parameters.
     * @param {Object} params - the new query parameters to be applied
     * @return {Object} the parameters Object
     */
    const combineParams = params => {
      return Object.assign({}, RouterContainer.query || {}, params)
    }

    /**
     * Event handler for the onChange event fired by the dropdownlist. Bound to
     * the class instance context.
     * @param {Object} e - the Event that triggered the handler
     */
    const onChange = e => {
      if (paramName) {
        let params = {}
        params[paramName] = e.value
        params = combineParams(params)
        if (params.group) params.group = convertMultiValue(params.group)
        localStorage.setItem(paramName, e.value)
        RouterContainer.go('/', params)
      }
    }

    /**
     * Render the component to the DOM.
     * @return {Object} React DOM Object
     */
    return (
      <DropdownList
        dropUp={dropUp}
        className={classnames({
          'dropdown--link': inverted,
          'dropdown--inverse': inverted,
        })}
        data={options()}
        valueField="value"
        textField="label"
        onChange={onChange}
        value={value || 'all'}
      />
    )
  }
)

AppUserSelect.propTypes = {
  paramName: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  value: PropTypes.string,
  meLabel: PropTypes.string,
  inverted: PropTypes.bool,
  dropUp: PropTypes.bool,
}

AppUserSelect.defaultProps = {
  dropUp: false,
  meLabel: 'My Cases',
}

AppUserSelect.displayName = 'AppUserSelect'

export default AppUserSelect
