import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import classnames from 'classnames'
import { Authenticated } from 'common'

import SearchForm from '../../ui/registry/RegistrySearch'
import CreateForm from '../CreateForm'
import PatientCardContainer from '../../people/cards/PatientCardContainer'
import { usePrevious } from '../../utils/hooks'

/**
 * Component responsible for rendering the patient search and patient form
 * portions of the single VIP registration workflow.
 *
 * @param store
 * @param paramsId
 * @param patient
 * @param setPatient
 * @param isLoading
 * @param infoById
 * @param pathname
 * @param resetCreateStore
 * @param resetSearchStore
 * @param selectedPatientId
 * @param setType
 * @return {JSX.Element}
 * @constructor
 */
const CreateView = ({
  store, // Only passed to child legacy components.
  paramsId,
  patient,
  setPatient,
  isLoading,
  infoById,
  pathname,
  resetCreateStore,
  resetSearchStore,
  selectedPatientId,
  setType,
}) => {
  useEffect(() => {
    setType('patients')
  }, [setType])

  const previousParamsId = usePrevious(paramsId)

  useEffect(() => {
    if (!previousParamsId && paramsId) {
      // We have chosen a user to view in the form.
      setPatient(paramsId)
    } else if (previousParamsId && !paramsId) {
      // We have gone back to the search view.
      resetCreateStore()
    }
  }, [previousParamsId, paramsId, setPatient, resetCreateStore])

  const resetAll = useCallback(() => {
    resetCreateStore()
    resetSearchStore()
  }, [resetCreateStore, resetSearchStore])

  // Reset stores on unmount.
  useEffect(() => resetAll, [resetAll])

  const nextLink = () => {
    if (selectedPatientId) {
      const registration = infoById(selectedPatientId)

      // this patient already has a VIP registration, redirect to that record
      if (registration?.length > 0) {
        const regId = registration[0].id
        return `${regId}`
      }
      // else go to the create view
      return `${pathname}/patient/${selectedPatientId}`
    }
    return pathname
  }

  const makeSelection = () => {
    if (selectedPatientId) {
      setPatient(selectedPatientId)
    }
    resetSearchStore()
  }

  const renderAddButton = () => (
    <Link
      to={nextLink()}
      onClick={makeSelection}
      type="button"
      className={classnames('button', { 'disabled-link': !selectedPatientId })}
      disabled={!selectedPatientId}
      data-cy="select-a-patient--button"
    >
      Select a Patient
    </Link>
  )

  const renderCard = data => {
    if (data._links.patient) {
      return (
        <article className="card_holder full_width margin_collapse">
          <PatientCardContainer patient={data} openLinkNew />
        </article>
      )
    }
  }

  return (
    <Authenticated permission="VIP_REGISTRATION_CREATE">
      <div className="view-content">
        <div className="row no-gutter margin_collapse">
          <div className="col-xs-10 col-xs-offset-1">
            <section
              className={classnames('view-content__body registry', {
                hidden: isLoading,
              })}
            >
              <header className="page-header">
                <h2>Add VIP Registration</h2>
                <p
                  className={classnames({
                    hidden: !!patient,
                  })}
                >
                  Add a Patient to create a VIP registration. Once a
                  registration is saved it cannot be deleted, only expired.
                </p>
              </header>
              <div
                className={classnames({
                  hidden: !patient,
                })}
              >
                {patient && renderCard(patient)}
              </div>
              <div
                className={classnames({
                  hidden: !!patient,
                })}
              >
                <SearchForm sourceStore={store} />
                <ul className="registry__search-actions list-inline">
                  <li>{renderAddButton()}</li>
                  <li>
                    <Link to="/vips" className="button button--link">
                      Cancel
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className={classnames({
                  hidden: !patient,
                })}
              >
                <CreateForm store={store} reset={resetAll} />
              </div>
            </section>
          </div>
        </div>
      </div>
    </Authenticated>
  )
}

CreateView.propTypes = {
  store: PropTypes.object,
  patient: PropTypes.object,
  setPatient: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pathname: PropTypes.string,
  paramsId: PropTypes.string,
  infoById: PropTypes.func.isRequired,
  resetCreateStore: PropTypes.func.isRequired,
  resetSearchStore: PropTypes.func.isRequired,
  setType: PropTypes.func,
  selectedPatientId: PropTypes.string,
}

export default CreateView
