import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import { updateQueryParams } from './helpers'
import { useQueryFromURI } from '../hooks/useQueryFromURI'
import DataFeedGroupEvaluation from '../DataFeedGroupEvaluation'
import { Loader } from '../../ui'
import { Pandy } from 'common'
import classnames from 'classnames'
import styles from './index.module.scss'
import DataFeedEvaluationNavContainer from '../DataFeedEvaluationNavContainer'
import DataFeedEvaluationAsideContainer from '../DataFeedEvaluationAsideContainer'
import { DEFAULT_QUERY } from '../stores/EvaluationStore'

const DataFeedEvaluationView = ({
  requiresAttentionGroups = [],
  noIssuesGroups = [],
  query,
  setQueryFromURI,
  history,
  location,
  loaded,
  failure,
  failureStatus,
}) => {
  useQueryFromURI(
    query,
    setQueryFromURI,
    history,
    '/data-feed/evaluation',
    DEFAULT_QUERY
  )

  const handleRowSelect = selectedRow => {
    history.push(
      updateQueryParams({ query, location, selectedRow, showAsidePanel: true })
    )
  }

  const handleCollapse = (tableData, selectedRow) => {
    if (tableData.find(row => row.id === selectedRow)) {
      const newQuery = { ...query, selectedRow: undefined }
      history.push(
        updateQueryParams({ query: newQuery, location, showAsidePanel: false })
      )
    }
  }

  const renderRequiresAttention = requiresAttentionGroups.map(g => (
    <DataFeedGroupEvaluation
      key={g.id}
      tableData={g.tableData}
      groupName={g.groupName}
      status={'danger'}
      counts={g.counts}
      handleRowClick={handleRowSelect}
      handleCollapse={handleCollapse}
      selectedRow={query.selectedRow}
    />
  ))

  const renderNoIssues = noIssuesGroups.map(g => (
    <DataFeedGroupEvaluation
      key={g.id}
      tableData={g.data}
      groupName={g.groupName}
      counts={g.counts}
    />
  ))

  return (
    <>
      <DataFeedEvaluationNavContainer />
      <Loader loaded={loaded} failure={failure} failureStatus={failureStatus}>
        <Pandy
          visible={
            loaded &&
            requiresAttentionGroups.length === 0 &&
            noIssuesGroups === 0
          }
          mood="happy"
        >
          <div className="speechBubble">
            <span>Nothing to report.</span>
          </div>
        </Pandy>
        <div className={styles.main}>
          <div className={classnames('fadeIn', styles.wrapper)}>
            <div className={styles.tableLayout} data-cy="fdd-evaluation-table">
              {requiresAttentionGroups.length ? (
                <h1 data-cy="fdd-requires-attention">Requires Attention</h1>
              ) : null}
              <div
                className={styles.groups}
                data-cy="fdd-requires-attention-groups"
              >
                {renderRequiresAttention}
              </div>
              {noIssuesGroups.length ? (
                <h1 data-cy="fdd-no-issues">No Issues Detected</h1>
              ) : null}
              <div className={styles.groups} data-cy="fdd-no-issues-groups">
                {renderNoIssues}
              </div>
            </div>
          </div>
          <DataFeedEvaluationAsideContainer />
        </div>
      </Loader>
    </>
  )
}

DataFeedEvaluationView.propTypes = {
  loaded: PropTypes.bool,
  failure: PropTypes.string,
  failureStatus: PropTypes.number,
  requiresAttentionGroups: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  noIssuesGroups: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  setQueryFromURI: PropTypes.func.isRequired,
  query: PropTypes.shape({
    createdAfter: PropTypes.string,
    createdBefore: PropTypes.string,
    showFilters: PropTypes.string,
    selectedRow: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
}

export default DataFeedEvaluationView
