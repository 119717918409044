import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CaseIncidentsFilterBarContainer from '../../incidents/CaseIncidentsFilterBarContainer'
import CaseMedicationIncidentsContainer from '../../incidents/CaseMedicationIncidentsContainer'
import styles from './index.module.scss'
import PrintTitle from '../../../ui/PrintTitle'
import IncidentsToPrintContainer from '../../print/IncidentsToPrintContainer'
import { INCIDENTS_NONE } from '../../print/stores/IncidentsPrintOptionsStore/consts'
import { Loader } from '../../../ui'

/*
Case incidents view
Responsible for
a. putting together incidents' components
b. updating filter search criteria to case store
 */
const Incidents = ({
  setIncidentFilterParams,
  query,
  incidentsForPrintMainOption,
  caseIncidentsLoading,
}) => {
  useEffect(() => {
    setIncidentFilterParams(query)
  })

  return (
    <div className={styles.wrapper} data-cy="incidents-wrapper">
      {incidentsForPrintMainOption !== INCIDENTS_NONE && (
        <PrintTitle>Incidents</PrintTitle>
      )}
      <>
        <div className={styles.hideForPrint}>
          <Loader loaded={!caseIncidentsLoading}>
            <CaseIncidentsFilterBarContainer />
            <CaseMedicationIncidentsContainer />
          </Loader>
        </div>
        <IncidentsToPrintContainer />
      </>
    </div>
  )
}

Incidents.propTypes = {
  setIncidentFilterParams: PropTypes.func.isRequired,
  query: PropTypes.shape({}),
  incidentsStore: PropTypes.shape({}),
  incidentsForPrintMainOption: PropTypes.string,
  caseIncidentsLoading: PropTypes.bool,
}

Incidents.defaultProps = {
  query: {},
  incidentsStore: {},
}

export default Incidents
