import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import classnames from 'classnames'
import moment from 'moment'

import { Authenticated } from 'common'

import { DatePicker, DocumentTitle, MultilineInput } from '../../ui'
import Actions from './Actions'
import Field from '../../ui/Field'
import styles from './index.module.scss'
import PatientCardContainer from '../../people/cards/PatientCardContainer'

const View = ({
  isLoading,
  paramsId,
  id,
  reason,
  tags,
  endDate,
  created,
  createdByFullName,
  lastModified,
  lastModifiedByFullName,
  status,
  hasPermission,
  setValue,
  update,
  patient,
}) => {
  const [editingReason, setReason] = useState('')
  // undefined/null has no meaning in terms of the UI, maybe we can use another data type like a boolean instead
  const [editingEndDate, setEditingEndDate] = useState(undefined)
  const [renewing, setRenewing] = useState(false)
  const [dirty, setDirty] = useState(false)

  useEffect(() => {
    if (!reason) {
      setReason('')
    } else {
      setReason(reason)
    }
  }, [reason])

  useEffect(() => {
    if (endDate) setEditingEndDate(moment(endDate))
  }, [endDate])

  useEffect(() => {
    if (id !== paramsId) setValue('id', paramsId)
  }, [id, setValue, paramsId])

  useEffect(() => {
    const dateDirty =
      (editingEndDate && !editingEndDate.isSame(endDate, 'day')) ||
      editingEndDate === null
    const reasonDirty = editingReason !== reason
    setDirty(reasonDirty || renewing || dateDirty)
  }, [editingEndDate, editingReason, endDate, reason, renewing])

  const renderCards = () => {
    const cards = []

    if (patient) {
      cards.push(<PatientCardContainer key="patientCard" patient={patient} />)
    }

    return (
      <article className="card_holder full_width margin_collapse">
        {cards}
      </article>
    )
  }

  const onDateChange = date => {
    const isValid =
      date &&
      moment(date)
        .startOf('day')
        .isSameOrAfter(moment().startOf('day'))
    if (isValid) setEditingEndDate(moment(date))
    else setEditingEndDate(null)
  }

  const handleSubmit = (expireNow = false) => {
    const updated = { expireNow }
    if (dirty) {
      updated.reason = editingReason
      if (!expireNow)
        updated.endDate = editingEndDate
          ? editingEndDate.format('YYYY-MM-DD')
          : null
    }
    update(updated)
  }

  const reset = () => {
    setReason('')
    setEditingEndDate(undefined)
    setRenewing(false)
  }

  const renew = () => {
    setRenewing(true)
    setEditingEndDate(null)
  }

  const disabled = !hasPermission || (status === 'EXPIRED' && !renewing)

  const renderForm = () => (
    <form className="record__create-form record__form">
      <ul className="no-gutter">
        <div className={styles.vipData}>
          <Field
            width={'inherit'}
            author={createdByFullName}
            value={moment(created).format('M/D/YYYY')}
            label={'Created By'}
          />
          <Field
            width={'inherit'}
            author={lastModifiedByFullName}
            value={lastModified ? moment(lastModified).format('M/D/YYYY') : '-'}
            label={'Last Modified'}
          />
          <Field
            width={'inherit'}
            value={tags.length ? tags.join(', ') : '-'}
            label={'VIP Tags'}
          />
        </div>
        <div className={classnames(styles.infoCard)}>
          <li className={styles.expirationLi}>
            <DatePicker
              disabled={disabled}
              className={styles.smallMargin}
              label="Expiration Date - Optional"
              onChange={(date, valid) => onDateChange(date, valid, 'endDate')}
              minDate={moment.now()}
              value={editingEndDate === undefined ? endDate : editingEndDate}
            />
            {!disabled && (
              <p className={styles.expirationText}>
                Specify a date in the future when the VIP Registration should
                expire.
              </p>
            )}
          </li>
          <li>
            <MultilineInput
              value={editingReason}
              label="VIP Registration Reason"
              onChange={val => setReason(val)}
              readOnly={disabled}
              bordered
              darkBackground={disabled}
              maxRows={5}
              data-cy="view-vip--multiline-input"
            />
          </li>
        </div>
        <Actions
          handleSubmit={handleSubmit}
          renew={renew}
          reset={reset}
          renewing={renewing}
          disabled={disabled}
          status={status}
          dirty={dirty}
        />
      </ul>
    </form>
  )

  const renderStatus = () => {
    return (
      <h3>
        <span
          className={classnames('label label-danger', {
            hidden: status !== 'EXPIRED',
          })}
        >
          Expired &ndash; {moment(endDate).format('l')}
        </span>
      </h3>
    )
  }

  return (
    <Authenticated permission="VIP_REGISTRATION_VIEW">
      <DocumentTitle text="VIP Registration" />
      <div className="view-content">
        <section
          className={classnames('view-content__body', {
            hidden: isLoading,
          })}
        >
          <div className="row no-gutter">
            <div className="col-xs-10 col-xs-offset-1">
              <header className="page-header">
                <ul className="list-inline">
                  <li>
                    <h2>{`${renewing ? 'Renew ' : ''}VIP Registration`}</h2>
                  </li>
                  <li>{renderStatus()}</li>
                </ul>
              </header>
              {renderCards()}
              {patient && renderForm()}
            </div>
          </div>
        </section>
      </div>
    </Authenticated>
  )
}

View.propTypes = {
  status: PropTypes.string,
  patient: PropTypes.shape({}),
  reason: PropTypes.string,
  endDate: PropTypes.string,
  tags: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.string),
  createdByFullName: PropTypes.string,
  created: PropTypes.string,
  lastModified: PropTypes.string,
  lastModifiedByFullName: PropTypes.string,
  isLoading: PropTypes.bool,
  hasPermission: PropTypes.bool,
  setValue: PropTypes.func,
  update: PropTypes.func,
  paramsId: PropTypes.string,
  id: PropTypes.string,
}

export default View
