import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Button = ({ className, invert, value, 'data-cy': dataCy, ...other }) => (
  <input
    className={classnames(className, 'button', {
      'button--inverse': invert,
    })}
    value={value || ''}
    data-cy={dataCy}
    {...other}
  />
)

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  invert: PropTypes.bool,
  value: PropTypes.string,
  'data-cy': PropTypes.string,
}

Button.defaultProps = {
  type: 'button',
  className: '',
  invert: false,
  value: '',
}

export default Button
