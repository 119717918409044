import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import MedicationIncidentFilter from '../MedicationIncidentFilter'
import styles from './index.module.scss'

const CaseIncidentsFilterBar = ({
  incidentRangeFilterOptions,
  incidentStatusFilterOptions,
  incidentTypeFilterOptions,
  incidentFilterParams,
  caseId,
  history,
}) => {
  const filters = [
    { opts: incidentTypeFilterOptions, param: 'type' },
    { opts: incidentRangeFilterOptions, param: 'range' },
    { opts: incidentStatusFilterOptions, param: 'status' },
  ]
  return (
    <div className={styles.wrapper}>
      {filters.map(filter => (
        <MedicationIncidentFilter
          filterOptions={filter.opts}
          targetParam={filter.param}
          incidentFilterParams={incidentFilterParams}
          caseId={caseId}
          history={history}
          key={Math.random()}
          data-cy={`incident-${filter.param}`}
        />
      ))}
    </div>
  )
}

const filterOptionType = mobxPropTypes.arrayOrObservableArrayOf(
  PropTypes.shape({})
)
CaseIncidentsFilterBar.propTypes = {
  incidentRangeFilterOptions: filterOptionType,
  incidentStatusFilterOptions: filterOptionType,
  incidentTypeFilterOptions: filterOptionType,
  incidentFilterParams: PropTypes.shape({}).isRequired,
  caseId: PropTypes.string.isRequired,
  history: PropTypes.shape({}).isRequired,
}

CaseIncidentsFilterBar.defaultProps = {
  incidentRangeFilterOptions: [],
  incidentStatusFilterOptions: [],
  incidentTypeFilterOptions: [],
}

export default CaseIncidentsFilterBar
