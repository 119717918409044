import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'

const BulkActionsAside = ({ children }) => {
  return (
    <aside className={styles.aside} data-cy="vip-bulk-action--icon">
      <header className={styles.header}>
        <span>Bulk Actions</span>
        <i className="material-icons icon-done_all" />
      </header>
      {children}
    </aside>
  )
}

BulkActionsAside.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BulkActionsAside
