import React from 'react'
import * as d3 from 'd3'
import moment from 'moment'
import { computed, decorate } from 'mobx'
import { observer } from 'mobx-react'
import {
  formatSuspiciousActivityLink,
  AlertCategoryStore,
  formatCategoryName,
  RouterContainer,
  GroupStore,
} from 'common'

import { ChartCanvas, DonutChart } from '../../ui/charts'

import CasesReportCountStore from '../stores/CasesReportCountStore'

const NewCasesChart = observer(
  class NewCasesChart extends React.Component {
    canvasSize = 229

    /**
     * COMPUTED
     * Compute the total value of items in the chart
     * @return {Number} the calculated value.
     */
    get total() {
      return CasesReportCountStore.newCases.reduce(
        (total, c) =>
          c && Object.prototype.hasOwnProperty.call(c, 'count')
            ? total + c.count
            : total,
        0
      )
    }

    /*
     * Event handler for mouseover event. Highlights the hovered-over segment and
     * sets the value of that segment in the store as the "active" segment.
     * Updates the large
     * "total" value in the chart.
     * @param {Object} d - the data object for the active segment
     */
    mouseover = d => {
      CasesReportCountStore.newCasesActive = d.data.group
      d3.selectAll(
        `.chart__donut-segment:not([data-group='${d.data.group.replace(
          /'/g,
          "\\'"
        )}'])`
      )

      d3.select(`[data-group="${d.data.group}"]`).style('fill', '#FFFFFF')

      d3.select('.chart__donut-total-value').text(d.data.count)
    }

    /*
     * Event handler for mouseout event. Resets the store, other chart segments,
     * and "total" value to their non-active values.
     */
    mouseout = () => {
      CasesReportCountStore.newCasesActive = null
      d3.selectAll('.chart__donut-segment').style('fill', '#BFD4F5')

      d3.select('.chart__donut-total-value').text(this.total)
    }

    /*
     * Event handler for click events; this will send the user to the case
     * listing based upon a specific segment.
     * @param {Object} d - a reference to the segment being interacted with
     */
    click = d => {
      d3.event.stopPropagation()

      const datum = d.data.group
      const params = {}

      params.showFilters = true

      if (CasesReportCountStore.range !== null)
        params.createdRange = CasesReportCountStore.range
      if (CasesReportCountStore.newOwner !== null)
        params.owner = CasesReportCountStore.newOwner

      if (CasesReportCountStore.range === 'custom') {
        const range = CasesReportCountStore.getRange()
        params.createdAfter = moment(range[0]).format('YYYY-MM-DD')
        params.createdBefore = moment(range[1]).format('YYYY-MM-DD')
      }

      if (datum === 'Other') {
        params.category = 'null'
      } else {
        const category = {
          ...AlertCategoryStore.categories.find(
            c => formatCategoryName(c) === datum
          ),
        }

        params.category = formatSuspiciousActivityLink(category)

        // The selected item might start with "<group name>:"
        // If so, we can find that group and incorporate it into the filters.
        const group = GroupStore.groups.find(
          g => g.name === formatCategoryName(category).split(':')[0]
        )
        if (group) {
          params.group = group.id
        }
      }

      RouterContainer.go('/cases', params)
    }

    /**
     * Render the component to the DOM.
     * @return {Object} React DOM Object
     */
    render() {
      CasesReportCountStore.margin = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }

      return (
        <article className="dataviz">
          <ChartCanvas
            className="non-viewbox"
            height={this.canvasSize}
            width={this.canvasSize}
          >
            <DonutChart
              canvasSize={this.canvasSize}
              store={CasesReportCountStore}
              dataProp="newCases"
              click={this.click}
              mouseover={this.mouseover}
              mouseout={this.mouseout}
            />
          </ChartCanvas>
        </article>
      )
    }
  }
)

decorate(NewCasesChart, {
  total: computed,
})

NewCasesChart.displayName = 'NewCasesChart'

export default NewCasesChart
