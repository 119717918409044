import React from 'react'
import * as d3 from 'd3'
import { decorate, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import $ from 'jquery'

import { RouterContainer } from 'common'
import Store from '../../stores/Store'
import EncounterLayer from '../EncounterLayer'
import VerticalRuleLayer from '../VerticalRuleLayer'
import FullRowLayer from '../FullRowLayer'
import GutterLayer from '../GutterLayer'
import TimeEntryLayerContainer from '../layers/TimeEntryLayerContainer'
import ChartStore from '../../stores/ChartStore'
import MetalogStore from '../../stores/MetalogStore'

/**
 * The main chart displayed within the activity view. This displays accesses, cases, and other
 * fundamental entities in a time-based chart.
 * @extends React.Component
 */
const FullActivityChart = observer(
  class FullActivityChart extends React.Component {
    // Observable
    mainElement = null

    constructor(props) {
      super(props)
      this.main = React.createRef()

      reaction(
        () => [
          Store.focus,
          Store.userIdParam,
          Store.patientIdParam,
          Store.activeFiltersCount,
        ],
        this.resetHeight
      )

      reaction(
        () => [MetalogStore.loading],
        () => {
          // Addresses a Chrome bug where nothing draws when arriving on this page via the back button
          if (!MetalogStore.loading)
            setTimeout(() => $('#clip rect').attr('height', '99999px'), 200)
        }
      )
    }

    componentDidMount() {
      this.mainElement = this.main.current
      this.svg = d3.select(this.mainElement)
      this.resetHeight()
    }

    componentDidUpdate() {
      this.resetHeight()
    }

    resetHeight = () => {
      // update the actual height of the svg based on the max y extent
      if (
        !ChartStore.yScale ||
        !this.mainElement ||
        !this.mainElement.parentNode
      )
        return
      const maxY = d3.max(ChartStore.yScale.range()) - 1
      const rowHeight = ChartStore.rowHeight
      const height = rowHeight + maxY

      if (isNaN(height)) return
      this.svg.attr('height', height)

      // Track the height of the chart wrapper so we can determine if special
      // handling is needed due to the presence of a scrollbar
      ChartStore.height = $('.scrollable .events_chart_wrapper').height()
    }

    render() {
      const clipTrans = `translate(${ChartStore.margin.left +
        ChartStore.padding.left}, 0)`

      return (
        <svg ref={this.main} className="chart accesses_chart" width="100%">
          <defs>
            <clipPath id="clip">
              <rect
                width={ChartStore.visualizationWidth}
                height="99999px"
                transform={clipTrans}
              />
            </clipPath>
          </defs>
          <EncounterLayer />
          <FullRowLayer
            scrollable={this.mainElement && this.mainElement.parentNode}
            routerContainer={RouterContainer}
            store={Store}
            data-cy="event-row"
          />
          <TimeEntryLayerContainer />
          <VerticalRuleLayer />
          <GutterLayer
            leftMargin={ChartStore.margin.left}
            leftPadding={ChartStore.padding.left}
            rightPadding={ChartStore.padding.right}
          />
        </svg>
      )
    }
  }
)

decorate(FullActivityChart, {
  mainElement: observable,
})

FullActivityChart.displayName = 'FullActivityChart'

export default FullActivityChart
