import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ExpandoSection = ({
  label,
  subLabel,
  visible,
  icon,
  onClick,
  className,
  expandoClassName,
  children,
  'data-cy': dataCy,
}) => {
  const anchorCn = classnames('button', 'button--list', className),
    liCn = classnames({ 'expando-active': visible }),
    iconCn = classnames('material-icons', className, {
      'icon-close': visible,
      [icon]: !visible,
    }),
    expandoCn = classnames('expando', 'marg-neg_horiz', expandoClassName, {
      'expando-hidden': !visible,
    })

  return (
    <li className={liCn} data-cy="case-aside-expando">
      <p onClick={onClick} className={anchorCn} data-cy={dataCy}>
        {label}
        <span className="text-subtle">{subLabel}</span>
        <i className={iconCn} />
      </p>
      <div className={expandoCn}>{children}</div>
    </li>
  )
}

ExpandoSection.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.number,
  visible: PropTypes.bool,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  expandoClassName: PropTypes.string,
  children: PropTypes.node,
  'data-cy': PropTypes.string,
}

export default ExpandoSection
