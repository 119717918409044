import React from 'react'
import PropTypes from 'prop-types'
import { Cell } from 'fixed-data-table-2'
import Tooltip from '../../../ui/Tooltip'
import { getIconAndTooltipContent } from './helper'

const IconCell = ({
  data,
  _getIconAndTooltipContent = getIconAndTooltipContent, // for unit test purposes
}) => {
  const { source, type, search, advancedSearch } = data
  const { icon, tooltipContent } = _getIconAndTooltipContent({
    dataClass: data.class,
    source,
    type,
    search,
    advancedSearch,
  })

  const iconEl = (
    <i className={`material-icons ${icon}`} data-cy={tooltipContent} />
  )

  return (
    <Cell className="event-icon" data-cy="tool-tips">
      {tooltipContent ? (
        <Tooltip content={tooltipContent}>{iconEl}</Tooltip>
      ) : (
        iconEl
      )}
    </Cell>
  )
}

IconCell.propTypes = {
  data: PropTypes.shape({
    advancedSearch: PropTypes.shape({}),
    class: PropTypes.string.isRequired,
    search: PropTypes.string,
    source: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  _getIconAndTooltipContent: PropTypes.func,
}

export default IconCell
