import React from 'react'
import { computed, observable, decorate, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { BaseURL, SamlConfigStore } from 'common'
import PropTypes from 'prop-types'
import SamlProperty from '../SamlProperty'
import NotificationStore from '../../../stores/NotificationStore'
import classnames from 'classnames'

const SamlConfig = observer(
  class SamlConfig extends React.Component {
    // observables
    samlProperties = []

    static propTypes = {
      onDirtyChange: PropTypes.func.isRequired,
    }

    constructor() {
      super()
      reaction(
        () => [this.dirty],
        ([dirty]) => {
          this.props.onDirtyChange(dirty)
        }
      )
    }

    // computed
    get dirty() {
      return this.samlProperties.filter(p => p != null && p.dirty).length > 0
    }

    handleSubmit(e) {
      e.preventDefault()
      if (!this.dirty) return
      const props = {}
      this.samlProperties
        .filter(p => p != null)
        .forEach(p => {
          props[p.propKey] = `${p.currentValue}`
        })
      SamlConfigStore.update({ properties: props })
        .then(() => {
          const content = (
            <span>
              <i className="material-icons icon-check_circle" />
              SAML settings updated successfully
            </span>
          )
          NotificationStore.add({ level: 'success', content })
        })
        .catch(() => {
          const content = (
            <span>
              <i className="material-icons icon-warning" />
              An error occurred during the update. Please check connectivity or
              contact support.
            </span>
          )
          NotificationStore.add({ level: 'warning', content })
        })
    }

    render() {
      const { lastModified, properties } = SamlConfigStore

      return (
        <div id="settings__wrapper-SAML_Config" className="wrapper">
          <div className="card_header">
            <h1>SAML Configuration</h1>
            <div>
              <span
                className={classnames('prot-a', { muted: !this.dirty })}
                onClick={this.handleSubmit.bind(this)}
                role="button"
              >
                Save Changes
              </span>
            </div>
          </div>
          <form className="content other">
            <p>
              Provide this{' '}
              <a href={BaseURL.service('login/saml2/default/metadata')}>link</a>{' '}
              to the customer SAML administrator. They should also provide a
              metadata URL for the identity provider (IDP) which is then used to
              configure this form.
            </p>
            <SamlProperty
              propKey="required"
              name="SAML Required"
              description="This disables the form login from the main page."
              type="checkbox"
              properties={properties}
              ref={c => this.samlProperties.push(c)}
            />
            <SamlProperty
              propKey="metadata.url"
              name="IDP Metadata URL"
              description="IDP settings and certificate will be retrieved from the specified URL. The retrieved settings
                  take priority over any other settings. If Metadata URL is not configured, the next three settings are required."
              properties={properties}
              ref={c => this.samlProperties.push(c)}
            />
            <SamlProperty
              propKey="entityid"
              name="IDP Entity ID"
              properties={properties}
              ref={c => this.samlProperties.push(c)}
            />
            <SamlProperty
              propKey="single_sign_on_service.url"
              name="IDP Single Signon Redirect URL"
              properties={properties}
              ref={c => this.samlProperties.push(c)}
            />
            <SamlProperty
              propKey="x509cert"
              name="IDP X509 Cert"
              type="textarea"
              properties={properties}
              ref={c => this.samlProperties.push(c)}
            />
            <SamlProperty
              propKey="username.attribute"
              name="(Optional) IDP Username Attribute"
              description="Use this attribute key to find the value to use as a username. This is used if the IDP
                  does not provide the email address as the NameID."
              properties={properties}
              ref={c => this.samlProperties.push(c)}
            />
            <SamlProperty
              propKey="single_logout_service.url"
              name="(Optional) IDP Single Logout Redirect URL"
              properties={properties}
              ref={c => this.samlProperties.push(c)}
            />
            <SamlProperty
              propKey="onelogin.saml2.sp.nameidformat"
              name="(Optional) SP Name ID Format"
              properties={properties}
              ref={c => this.samlProperties.push(c)}
            />
            <SamlProperty
              propKey="onelogin.saml2.security.sign_metadata"
              name="Sign Metadata"
              description="Indicates a requirement for the Metadata of this SP to be signed. Turn this OFF for ADFS."
              type="checkbox"
              properties={properties}
              ref={c => this.samlProperties.push(c)}
            />
            <SamlProperty
              propKey="onelogin.saml2.security.want_assertions_signed"
              name="Want Assertions Signed"
              description="Indicates a requirement for assertion elements received by this SP to be signed."
              type="checkbox"
              properties={properties}
              ref={c => this.samlProperties.push(c)}
            />
            <br />
            <h3>
              <i className="material-icons icon-notifications tip" />
              Tip: Use incognito mode to test the configuration
            </h3>
            <h5>Last Modified: {lastModified}</h5>
          </form>
        </div>
      )
    }
  }
)

decorate(SamlConfig, {
  samlProperties: observable,
  dirty: computed,
})

SamlConfig.displayName = 'SamlConfig'

export default SamlConfig
