import React from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import CaseStore from '../stores/CaseStore';
import CaseView from '../CaseView';
import PatientEncountersStore from '../../patient/stores/PatientEncountersStore';
import EmployeeActivityStore from '../../employee/stores/EmployeeActivityStore';
import NewAppExperienceStore from '../../stores/NewAppExperienceStore';

export function mapProps({
  CaseStore,
  EmployeeActivityStore,
  PatientEncountersStore,
  match,
}) {
  sessionStorage.setItem('caseId', match.params.caseId);
  return {
    caseId: match.params.caseId,
    setCaseId: CaseStore.setCaseId,
    number: CaseStore.number,
    failureStatus: CaseStore.failureStatus,
    failure: CaseStore.failure,
    result: CaseStore.result,
    patientId: CaseStore.patientId,
    userId: CaseStore.userId,
    caseCreatedDate: CaseStore.created,
    setIdForPatientEncounters: PatientEncountersStore.setPatientId,
    setIdForEmployeeActivity: EmployeeActivityStore.setUserId,
    setPatientEncountersPivotDate: PatientEncountersStore.setTimebase,
    caseDirty: CaseStore.dirty,
    caseStore: CaseStore,
  };
}

const CaseViewContainer = withRouter(
  observer(({ match }) => {
    const hasV2CasePage = NewAppExperienceStore.appSettingsEditValues.casePage;
    if (hasV2CasePage) {
      window.location.replace(
        process.env.NODE_ENV === 'production'
          ? `/v2/case/index.html?caseId=${match.params.caseId}`
          : `http://localhost:8080/case?caseId=${match.params.caseId}`
      );
    }
    return !hasV2CasePage ? (
      <CaseView
        {...mapProps({
          CaseStore,
          EmployeeActivityStore,
          PatientEncountersStore,
          match,
        })}
      />
    ) : null;
  })
);

CaseViewContainer.displayName = 'CaseViewContainer';

export default CaseViewContainer;
