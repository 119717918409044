import moment from 'moment-timezone'
import { autorun, observable, computed, action, reaction, decorate } from 'mobx'
import {
  AccessClient,
  HalUtils,
  MedicationAdministrationClient,
  MedicationDiscrepancyClient,
  MedicationHandlingClient,
  MedicationOrderClient,
  MedicationUtils,
  PagedStore,
  RouterContainer,
  PermissionStore,
  TimeEntryClient,
  VisibleSystemStore,
  mapValuesToArray,
} from 'common'

import Store from '../Store'
import EmployeeAuthorizationsStore from '../../../stores/EmployeeAuthorizationsStore'
import PatientAuthorizationsStore from '../../../stores/PatientAuthorizationsStore'
import ChartStore from '../ChartStore'
import DiversionActivityStore from '../DiversionActivityStore'

const DEFAULT_LOG_SORT = 'asc'
const LOWER_BOUNDS_DATE = moment(0)
const UPPER_BOUNDS_DATE = moment('2100-01-01')
const PAGE_SIZE = 2000

const ACCESS = 'access'
const ADMINISTRATION = 'administration'
const DISCREPANCY = 'discrepancy'
const HANDLING = 'handling'
const ORDER = 'order'
const TIME_ENTRY = 'timeEntry'

const formatDetailUpdate = (details, field, isAmount) => {
  // find occurrences of the field changing as part of an order update
  const updates = details.filter(entry => entry[1][field] !== undefined)
  if (updates.length) {
    // first occurrence should be in the description, asterisked if it was changed later
    let description = isAmount
      ? MedicationUtils.formatAmount(updates[0][1][field])
      : updates[0][1][field]
    if (updates.length > 1) description += '*'
    return description
  }
}

class DetailedStore extends PagedStore {
  constructor(autoRefresh = true) {
    super()
    if (autoRefresh) {
      autorun(() => {
        this.refresh()
      })
    }
  }

  // Computed
  get limitReached() {
    return this.size >= PAGE_SIZE
  }

  // Observable
  sortDir = DEFAULT_LOG_SORT
}

decorate(DetailedStore, {
  sortDir: observable,
  limitReached: computed,
})

class AccessDetailedStore extends DetailedStore {
  fetch() {
    const sort = [`dateOfAccess,${this.sortDir}`]
    const { activeUsers, activePatients, userIdParam, fromDate, toDate } = Store
    if (userIdParam && Store.accessesVisible) {
      const fp = Store.filterParams
      return AccessClient.getByUsersAndPatient(
        activeUsers.split(),
        activePatients.split(),
        fromDate,
        toDate.clone().add(1, 'days'),
        Store.includeUOA,
        fp.filterField,
        fp.filterValues,
        sort,
        PAGE_SIZE
      )
    }

    return []
  }

  postProcess(item) {
    item.class = ACCESS
    item.eventDate = moment(item.dateOfAccess)

    return item
  }
}

class AdministrationDetailedStore extends DetailedStore {
  fetch() {
    const sort = [`startTime,${this.sortDir}`]
    const { activeUsers, activePatients, userIdParam, fromDate, toDate } = Store

    if (
      PermissionStore.has('MEDICATION_ADMINISTRATION_VIEW') &&
      userIdParam &&
      Store.administrationsVisible
    ) {
      const fp = Store.filterParams
      return MedicationAdministrationClient.getByUsersAndPatient(
        activeUsers.split(),
        activePatients.split(),
        fromDate,
        toDate.clone().add(1, 'days'),
        fp.filterField,
        fp.filterValues,
        sort,
        PAGE_SIZE
      )
    }

    return []
  }

  postProcess(item) {
    item.class = ADMINISTRATION
    item.eventDate = moment(item.startTime)
    item.eventDescription = MedicationUtils.formatAdministration(item)

    return item
  }
}

class DiscrepancyDetailedStore extends DetailedStore {
  fetch() {
    const sort = [`startTime,${this.sortDir}`]
    const { activeUsers, userIdParam, patientIdParam, fromDate, toDate } = Store

    if (
      PermissionStore.has('MEDICATION_DISCREPANCY_VIEW') &&
      userIdParam &&
      (!patientIdParam || Store.includeUOA) &&
      Store.discrepanciesVisible
    ) {
      const fp = Store.filterParams
      return MedicationDiscrepancyClient.getByUsers(
        activeUsers.split(),
        fromDate,
        toDate.clone().add(1, 'days'),
        fp.filterField,
        fp.filterValues,
        sort,
        PAGE_SIZE
      )
    }

    return []
  }

  postProcess(item) {
    const { activeUsers } = Store

    item.class = DISCREPANCY
    item.eventDescription = MedicationUtils.formatDiscrepancy(item)
    item.eventDate = moment(item.resolutionTime)

    if (activeUsers.includes(item.resolvedBy && item.resolvedBy.id)) {
      item.user = item.resolvedBy
    } else {
      item.user = item.witnessedBy
    }

    return item
  }
}

class HandlingDetailedStore extends DetailedStore {
  fetch() {
    const sort = [`eventTime,${this.sortDir}`]
    const { activeUsers, activePatients, userIdParam, fromDate, toDate } = Store

    if (
      PermissionStore.has('MEDICATION_HANDLING_VIEW') &&
      userIdParam &&
      Store.handlingsVisible
    ) {
      const fp = Store.filterParams
      return MedicationHandlingClient.getByUsersAndPatient(
        activeUsers.split(),
        activePatients.split(),
        fromDate,
        toDate.clone().add(1, 'days'),
        Store.includeUOA,
        fp.filterField,
        fp.filterValues,
        sort,
        PAGE_SIZE
      )
    }

    return []
  }

  postProcess(item) {
    item.class = HANDLING
    item.eventDate = moment(item.eventTime)
    item.eventDescription = MedicationUtils.formatHandling(item)

    if (item.medicationOrder && !item.medicationOrder.description) {
      item.medicationOrder.description = MedicationUtils.formatMedications(
        item.medicationOrder.medications,
        true
      )
    }

    return item
  }
}

class OrderDetailedStore extends DetailedStore {
  fetch() {
    const sort = [`startTime,${this.sortDir}`]
    const { activeUsers, activePatients, userIdParam, fromDate, toDate } = Store

    if (
      PermissionStore.has('ORDER_VIEW') &&
      userIdParam &&
      Store.ordersVisible
    ) {
      const fp = Store.filterParams
      return MedicationOrderClient.getByUsersAndPatient(
        activeUsers.split(),
        activePatients.split(),
        fromDate,
        toDate.clone().add(1, 'days'),
        fp.filterField,
        fp.filterValues,
        sort,
        PAGE_SIZE
      )
    }

    return []
  }

  postProcess(item) {
    const { activeUsers } = Store

    item.class = ORDER
    item.user = item.createdBy
    item.eventDate = moment(item.startTime)

    item.eventDescription = ''
    const detailEntries = Object.entries(item.details || {}).sort()

    const strength = formatDetailUpdate(detailEntries, 'strength', true)
    const volume = formatDetailUpdate(detailEntries, 'volume', true)
    const route = formatDetailUpdate(detailEntries, 'route', false)
    const frequency = formatDetailUpdate(detailEntries, 'frequency', false)
    const medication =
      item.medications &&
      MedicationUtils.formatMedications(item.medications, true)

    if (strength && volume) item.eventDescription += `${strength}/${volume} `
    else if (strength) item.eventDescription += `${strength} `
    else if (volume) item.eventDescription += `${volume} `

    item.eventDescription +=
      medication || item.description || 'Unknown Medication'

    if (frequency) item.eventDescription += ` ${frequency}`
    if (route) item.eventDescription += ` ${route}`

    if (activeUsers.includes(item.createdBy && item.createdBy.id)) {
      item.user = item.createdBy
    } else {
      item.user = item.orderedBy
    }

    return item
  }
}

class TimeEntryStore extends DetailedStore {
  constructor() {
    super(false)
    reaction(
      () => [this.shouldFetch, this.fetchParams],
      () => {
        this.refresh()
      }
    )
  }

  // Computed
  get shouldFetch() {
    return (
      PermissionStore.has('TIME_ENTRY_VIEW') &&
      Store.isUserFocus &&
      Store.timeEntriesVisible
    )
  }

  // Computed
  get fetchParams() {
    const sort = [`startTime,${this.sortDir}`]
    const { activeUsers, fromDate, toDate } = Store

    return [activeUsers.split(), fromDate, toDate.clone().add(1, 'days'), sort]
  }

  fetch() {
    if (this.shouldFetch) {
      return TimeEntryClient.getByUsers(...this.fetchParams)
    }

    return []
  }

  postProcess(item) {
    item.class = TIME_ENTRY
    item.id = HalUtils.getId(item)
    return item
  }

  get customResults() {
    const sort = this.sortDir
    const res = this.results
    const { fromDate, toDate } = Store
    const toDateWithPadding = toDate.clone().add(1, 'days')
    const postProcessed = []
    const startOrEnd = res.filter(
      r => (!r.start && r.end) || (r.start && !r.end)
    )
    const startAndEnd = res.filter(r => r.start && r.end)

    startAndEnd.map(r => {
      const startItem = Object.assign({}, r)
      const endItem = Object.assign({}, r)

      startItem.eventDate = moment(startItem.start)
      startItem.eventDescription = `Start - ${startItem.startReason ||
        'Reason Not Provided'}`

      endItem.id = endItem.id + '_end'
      endItem.eventDate = moment(endItem.end)
      endItem.eventDescription = `End - ${startItem.endReason ||
        'Reason Not Provided'}`

      if (toDateWithPadding.isBefore(endItem.end))
        return postProcessed.push(startItem)
      if (fromDate.isAfter(startItem.start)) return postProcessed.push(endItem)
      return postProcessed.push(startItem, endItem)
    })

    startOrEnd.map(r => {
      const prefix = r.start ? 'Start - ' : 'End - '
      let reason
      if (r.start && r.startReason) reason = r.startReason
      else if (r.end && r.endReason) reason = r.endReason
      else reason = r.startReason || r.endReason || 'Reason Not Provided'
      r.eventDate = moment(r.start) || moment(r.end)
      r.eventDescription = prefix + reason
      return postProcessed.push(r)
    })

    const sorted = postProcessed.sort((a, b) => {
      if (sort === 'asc') return a.eventDate - b.eventDate
      return b.eventDate - a.eventDate
    })
    if (sorted.length && Store.timeEntriesVisible) return sorted
    return []
  }
}

decorate(TimeEntryStore, {
  customResults: computed,
  shouldFetch: computed,
  fetchParams: computed,
})

const accesses = new AccessDetailedStore()
const administrations = new AdministrationDetailedStore()
const discrepancies = new DiscrepancyDetailedStore()
const handlings = new HandlingDetailedStore()
const orders = new OrderDetailedStore()
const incidents = DiversionActivityStore.collatedIncidentsByKey
const timeEntries = new TimeEntryStore()

const stores = [
  accesses,
  administrations,
  discrepancies,
  handlings,
  orders,
  timeEntries,
]

class EventDetailedStore {
  constructor({ visibleSystemStore }) {
    this.visibleSystemStore = visibleSystemStore

    reaction(
      () => [
        Store.activeUsers,
        Store.activePatients,
        Store.fromDate,
        Store.toDate,
        Store.focus,
      ],
      () => {
        this.detailFocus = null
        this.scrollToInc = null
        this.clearFlags()
        DiversionActivityStore.clearFlags()
      }
    )

    reaction(
      () => [RouterContainer.query.focusEvent, this.results],
      () => {
        if (RouterContainer.query.focusEvent) {
          const eventIndex = this.detailedIndexMap.get(
            RouterContainer.query.focusEvent
          )
          if (eventIndex !== undefined && eventIndex < this.results.length) {
            this.detailFocus = this.results[eventIndex]
          }
        }
      }
    )

    reaction(
      () => [
        RouterContainer.query.focusEvent,
        DiversionActivityStore.collatedIncidentsByKey,
      ],
      () => {
        if (RouterContainer.query.focusEvent) {
          const inc = DiversionActivityStore.collatedIncidentsByKey.find(
            inc => inc.id === RouterContainer.query.focusEvent
          )
          if (inc) this.setIncidentFocus(inc)
        }
      }
    )

    reaction(
      () => this.detailView,
      detailView => {
        if (detailView && detailView.dateOfAccess) {
          ChartStore.updateDateOfAccess(detailView.dateOfAccess)
        }
      }
    )

    // reset focus if the currently focused event was just filtered
    reaction(
      () => [this.visibleSystemStore.filteredSources],
      () => {
        if (
          this.detailFocus?.source &&
          this.visibleSystemStore.filteredSources.includes(
            this.detailFocus.source
          )
        ) {
          this.detailFocus = null
        }
      }
    )
  }

  // Observables
  sortDir = DEFAULT_LOG_SORT
  flagged = observable.map()
  /**
   * The row in the table with focus (highlighted)
   */
  detailFocus = null
  scrollToInc = null
  // End Of Observables

  // Action
  changeSort() {
    this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc'
    stores.forEach(s => {
      s.sortDir = this.sortDir
    })
  }

  setIncidentFocus = focus => {
    if (Store.activeTab !== 'Incidents') Store.setDrawerTab('Incidents')
    this.detailFocus = focus
    this.scrollToInc = focus && focus.id
  }

  // Action
  clearScrollToInc = () => {
    this.scrollToInc = null
  }

  // Computed
  get loading() {
    return stores.map(s => s.loading).reduce((a, b) => a || b)
  }

  // Computed
  get paging() {
    return stores.map(s => s.paging).reduce((a, b) => a || b)
  }

  // Computed
  get failure() {
    return stores.map(s => s.failure).reduce((a, b) => a || b)
  }

  // Computed
  get size() {
    return stores.map(s => s.size).reduce((a, b) => a + b)
  }

  // Computed
  get hasMore() {
    return stores.map(s => s.hasMore).reduce((a, b) => a || b)
  }

  // Computed
  get limitReached() {
    return (
      !this.loading && stores.map(s => s.limitReached).reduce((a, b) => a || b)
    )
  }

  // Computed
  get results() {
    return this.merge(
      accesses.results,
      administrations.results,
      discrepancies.results,
      handlings.results,
      orders.results,
      timeEntries.customResults
    ).filter(
      event =>
        !event.source ||
        !this.visibleSystemStore.filteredSources.includes(event.source)
    )
  }

  // Computed
  get detailedIndexMap() {
    const idMap = this.results.map((i, idx) => [i.id, idx])

    return new Map(idMap)
  }

  // Computed
  get detailFocusIndex() {
    const detailFocus = this.detailFocus
    const detailMap = this.detailedIndexMap

    if (detailFocus && detailMap) {
      const newIndex = detailMap.get(detailFocus.id)
      if (newIndex || newIndex === 0) {
        return newIndex
      }
    }
    return null
  }

  /**
   * COMPUTED
   * The row in the table displayed in the drawer
   */
  get detailView() {
    if (this.detailFocusIndex !== undefined) {
      return this.results[this.detailFocusIndex]
    }
    return null
  }

  // Computed
  get detailViewId() {
    if (this.detailView) return this.detailView.id
    return null
  }

  // Computed
  get someFlagged() {
    if (!this.results) return false
    if (this.flagged.size === 0) return false
    return true
  }

  // Computed
  get someNotFlagged() {
    if (!this.results) return false
    if (
      this.results.filter(
        e =>
          e.class === 'access' &&
          e.user &&
          e.user.id === Store.userIdParam &&
          e.patient
      ).length === this.flagged.size
    )
      return false
    return true
  }

  // Computed
  get authorizations() {
    const auths = []

    if (Store.isUserFocus && Store.activeUsers) {
      Store.activeUsers.split(',').forEach(u => {
        ;(EmployeeAuthorizationsStore.infoById.get(u) || []).forEach(auth =>
          auths.push(auth)
        )
      })
    } else if (Store.isPatientFocus && Store.activePatients) {
      Store.activePatients.split(',').forEach(p => {
        ;(PatientAuthorizationsStore.infoById.get(p) || []).forEach(auth =>
          auths.push(auth)
        )
      })
    }

    return auths.map(auth => {
      const a = Object.assign({}, auth)

      a.start = moment(a.start)
      if (a.requestedEnd) a.requestedEnd = moment(a.requestedEnd)
      if (a.revocationDate) a.revocationDate = moment(a.revocationDate)

      // set the "end" value to revocation date (if present) or requested end
      // date (if no revocation date)
      a.end = a.revocationDate || a.requestedEnd

      return a
    })
  }

  // Computed
  get authorizationsByYKey() {
    const auths = {}

    this.authorizations.forEach(auth => {
      const key = auth.patient.id + auth.user.id
      let endDate = auth.end
      let indefiniteEnd = false

      if (!auths[key]) auths[key] = []

      // special handling for the end date (if missing): add a speculative
      // far-future end date, set indefiniteEnd to true
      if (!endDate) {
        indefiniteEnd = true
        endDate = moment().add(100, 'years')
      }

      auths[key].push({
        id: auth.id,
        startDate: auth.start.toDate(),
        displayStartDate: auth.start.format('l'),
        endDate: endDate.toDate(),
        displayEndDate: indefiniteEnd ? 'indefinite' : endDate.format('l'),
        type: auth.type,
      })
    })

    return auths
  }

  merge(a, b, c, d, e, f) {
    const boundedDate =
      this.sortDir === 'asc' ? UPPER_BOUNDS_DATE : LOWER_BOUNDS_DATE
    const highest = [a, b, c, d, e, f]
      .map(arr => {
        // return the top element of the array
        if (arr.length > 0) return arr[0]
        // if the array has no more elements at this point, then we know that there were no more
        // matches in that set. return something that is very low to avoid length errors later
        return { eventDate: boundedDate }
      })
      .reduce((a, b) => {
        const aDate = a.eventDate
        const bDate = b.eventDate

        if (this.sortDir === 'asc') return aDate.isBefore(bDate) ? a : b
        return aDate.isAfter(bDate) ? a : b
      })
    // if the highest score is one of the bounded dates elts, then we don't want to return it
    if (highest.eventDate.isSame(boundedDate)) return []
    // return the highest elt merged with a recursive call with remaining elts from each stack
    if (a.length > 0 && highest === a[0])
      return [highest].concat(this.merge(a.slice(1), b, c, d, e, f))
    if (b.length > 0 && highest === b[0])
      return [highest].concat(this.merge(a, b.slice(1), c, d, e, f))
    if (c.length > 0 && highest === c[0])
      return [highest].concat(this.merge(a, b, c.slice(1), d, e, f))
    if (d.length > 0 && highest === d[0])
      return [highest].concat(this.merge(a, b, c, d.slice(1), e, f))
    if (e.length > 0 && highest === e[0])
      return [highest].concat(this.merge(a, b, c, d, e.slice(1), f))
    if (f.length > 0 && highest === f[0])
      return [highest].concat(this.merge(a, b, c, d, e, f.slice(1)))

    return []
  }

  refresh() {
    stores.forEach(s => {
      s.refresh()
    })
  }

  nextPage() {
    if (!this.hasMore || this.loading || this.paging) return

    stores.forEach(s => {
      s.nextPage()
    })
  }

  focusOn = event => {
    if (Store.activeTab !== 'Events') Store.setDrawerTab('Events')
    if (event.startTime) {
      const start = moment(event.startTime)
      const end = moment(event.endTime)
      const inRange = this.results.filter(
        d => start.isSameOrBefore(d.eventDate) && end.isSameOrAfter(d.eventDate)
      )
      if (inRange.length > 0) this.detailFocus = inRange[0]
    } else this.detailFocus = event
  }

  isActive = (store, showAll) => {
    return Boolean(store.size) || showAll
  }

  // Computed
  get eventTypes() {
    // show all options if we aren't focused on a single row of events
    const showAll = !(Store.userIdParam && Store.patientIdParam)
    // these names must match up in Store.js

    return [
      { name: 'Accesses', active: this.isActive(accesses, showAll) },
      {
        name: 'Administrations',
        active: this.isActive(administrations, showAll),
      },
      { name: 'Discrepancies', active: this.isActive(discrepancies, showAll) },
      { name: 'Handlings', active: this.isActive(handlings, showAll) },
      { name: 'Incident Summary', active: this.isActive(incidents, showAll) },
      {
        name: 'Incident Summary & Event Details',
        active: this.isActive(incidents, showAll),
      },
      { name: 'Orders', active: this.isActive(orders, showAll) },
      { name: 'Time Entries', active: this.isActive(timeEntries, false) },
    ]
  }

  toggleFlag(event) {
    const id = event.id
    if (this.flagged.has(id)) {
      this.flagged.delete(id)
    } else {
      this.flagged.set(id, event)
    }
  }

  toggleFlags() {
    if (this.someFlagged) {
      this.clearFlags()
    } else {
      // set flags in an intermediate map for performance
      const tmpMap = new Map()

      this.results
        .filter(
          e =>
            e.class === 'access' &&
            e.user &&
            e.user.id === Store.userIdParam &&
            e.patient
        )
        .forEach(i => {
          tmpMap.set(i.id, i)
        })
      this.flagged = tmpMap
    }
  }

  isFlagged(event) {
    const id = event.id
    if (this.flagged.has(id)) return true
    if (event.startTime && ChartStore.selectedKey === ChartStore.yKey(event)) {
      // it's a metalog, so we just see if the metalog contains a flagged access
      const matching = mapValuesToArray(this.flagged).find(v => {
        const d = v.dateOfAccess
        const { startTime, endTime } = event
        return startTime <= d && d <= endTime
      })
      return Boolean(matching)
    }
    return false
  }

  addFlag(event) {
    const id = event.id
    this.flagged.set(id, event)
  }

  clearFlags() {
    this.flagged = observable.map()
  }

  // Computed
  get selectedIncident() {
    return Store.focus === 'employee_incidents'
      ? DiversionActivityStore.collatedIncidentsByKey.find(
          inc => inc.id === (this.detailFocus && this.detailFocus.id)
        )
      : null
  }
}

decorate(EventDetailedStore, {
  // observables
  sortDir: observable,
  flagged: observable,
  detailFocus: observable,
  scrollToInc: observable,
  // computeds
  limitReached: computed,
  loading: computed,
  paging: computed,
  failure: computed,
  size: computed,
  hasMore: computed,
  results: computed,
  detailedIndexMap: computed,
  detailFocusIndex: computed,
  detailView: computed,
  detailViewId: computed,
  selectedIncident: computed,
  someFlagged: computed,
  someNotFlagged: computed,
  authorizations: computed,
  authorizationsByYKey: computed,
  eventTypes: computed,
  // actions
  changeSort: action,
  clearFlags: action,
  setIncidentFocus: action,
  clearScrollToInc: action,
})

const store = new EventDetailedStore({
  visibleSystemStore: VisibleSystemStore,
})
export { store as EventDetailedStore }
export default store
