import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { autorun, computed, decorate, observable, reaction } from 'mobx'
import { observer } from 'mobx-react'
import moment from 'moment'
import $ from 'jquery'
import { Link } from 'react-router-dom'

import { AlertCategoryStore, PermissionStore, RouterContainer } from 'common'
import MetalogStore from '../stores/MetalogStore'
import EventDetailedStore from '../stores/EventDetailedStore'
import ChartStore from '../stores/ChartStore'
import DiversionActivityStore from '../stores/DiversionActivityStore'
import Store from '../stores/Store'
import TopNav from '../TopNav'
import ActivityDescriptionStore from '../stores/ActivityDescriptionStore'
import AssessmentStore from '../stores/AssessmentStore'
import SmartReferenceStore from '../stores/SmartReferenceStore'
import CaseStore from '../stores/CaseStore'
import DrawerHeader from '../drawer/DrawerHeader'
import DrawerNav from '../drawer/DrawerNav'
import DrawerIncidents from '../drawer/DrawerIncidents'

import PatientBoxContainer from '../../patient/PatientBoxContainer'
import PatientEncountersStore from '../../patient/stores/PatientEncountersStore'
import EmployeeActivityStore from '../../employee/stores/EmployeeActivityStore'
import EmployeeBoxContainer from '../../employee/EmployeeBoxContainer'
import EventDetails from '../eventTableDetails/EventDetails'
import ActivityVisualization from '../ActivityVisualization'
import EventTable from '../EventTable'
import ActivityFilters from '../ActivityFilters'
import ActivityOptionsContainer from '../options/ActivityOptionsContainer'
import XAxisChartContainer from '../xaxis/XAxisChartContainer'
import CasePromotion from '../CasePromotion'
import AssessmentsForCaseTypeContainer from '../drawer/ActivityViewAssessmentsForCaseTypeContainer'

import ResizeWatcher from '../../utils/ResizeWatcher'
import {
  DocumentTitle,
  Loader,
  Tooltip,
  SysAlert,
  ApplyBodyClassName,
} from '../../ui'
import { IncidentContext } from '../../ui/IncidentGroupList/Context'
import IncidentNoteAndResolutionStore from '../../stores/IncidentNoteAndResolutionStore'
import styles from './index.module.scss'
import { ScrollIntoViewContext } from '../../ui/context'

const dateParamFormat = 'MM-DD-YYYY'

const HIDDEN = 0
const HALF_HEIGHT = 1
const FULL_HEIGHT = 2

const ROW_HEIGHT = 50

const ActivityView = observer(
  class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        storeParamEmployeeId: Store.params.employeeId,
        storeParamsPatientId: Store.params.patientId,
      }
    }

    static propTypes = {
      match: PropTypes.shape({
        params: PropTypes.shape({
          patientId: PropTypes.string,
          employeeId: PropTypes.string,
        }),
        url: PropTypes.string,
      }),
    }
    incidentStore = IncidentNoteAndResolutionStore({
      incidentsStore: DiversionActivityStore,
    })
    activityDescriptionStore = ActivityDescriptionStore

    assessmentsSection

    // Observables
    logsVisiblePreference = HALF_HEIGHT
    tableHeight = 0
    tableWidth = 0

    // NOT observable
    disposers = []

    // Refs
    table = React.createRef()
    main = React.createRef()

    componentDidMount() {
      const { match } = this.props

      Store.employeePg1Visible = true
      Store.patientPg1Visible = true
      Store.params = match.params
      PatientEncountersStore.setPatientId(match.params.patientId)
      EmployeeActivityStore.setUserId(match.params.employeeId)

      // Watch the main tag for size changes.
      this.resizeWatcher = new ResizeWatcher(this.main.current, () => {
        this.onMainWidthChange()
        this.updateTableSize()
      })
      this.disposers.push(() => this.resizeWatcher.off())

      this.onMainWidthChange()
      this.updateTableSize()

      // If the user is on a different type of row now, revert to a tab that exists
      this.disposers.push(
        reaction(
          () => [Store.focus],
          () => {
            if (!Store.drawerTabs.includes(Store.activeDrawerTab)) {
              Store.activeDrawerTab = Store.drawerTabs[0]
            }
          },
          { fireImmediately: true }
        )
      )

      this.disposers.push(
        autorun(() => {
          const fd = RouterContainer.query.fromDate
          const td = RouterContainer.query.toDate

          const fdm = moment(fd, dateParamFormat)
          const tdm = moment(td, dateParamFormat)

          const newFrom =
            fd && fdm.isValid
              ? fdm
              : moment()
                  .subtract(1, 'weeks')
                  .startOf('day')

          const newTo = td && tdm.isValid ? tdm : moment()

          if (!newFrom.isSame(Store.fromDate, 'day')) Store.fromDate = newFrom
          if (!newTo.isSame(Store.toDate, 'day')) Store.toDate = newTo
        })
      )

      this.disposers.push(() => {
        PatientEncountersStore.setPatientId(null)
        EmployeeActivityStore.setUserId(null)
      })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if (
        prevState.storeParamEmployeeId !== nextProps.match.params.employeeId ||
        prevState.storeParamsPatientId !== nextProps.match.params.patientId
      ) {
        Store.employeePg1Visible = true
        Store.patientPg1Visible = true

        if (prevState.storeParamEmployeeId)
          SmartReferenceStore.userChanged(
            Store.params.employeeId,
            nextProps.match.params.employeeId
          )

        Store.params = nextProps.match.params
        PatientEncountersStore.setPatientId(nextProps.match.params.patientId)

        return {
          storeParamEmployeeId: nextProps.match.params.employeeId,
          storeParamsPatientId: nextProps.match.params.patientId,
        }
      } else {
        return null
      }
    }

    componentDidUpdate(prevProps) {
      this.updateTableSize()
      this.onMainWidthChange()
      // scroll the drawers to the top whenever the view changes data
      $('.reset_scroll_on_update').scrollTop(0)

      if (this.props.match.url !== prevProps.match.url) {
        this.incidentStore.reset()
      }
    }

    componentWillUnmount() {
      this.disposers.forEach(d => d())
      SmartReferenceStore.reset()
      Store.setOptionsVisible(false)
    }

    // Computed
    get logsVisible() {
      if (!MetalogStore.loading && MetalogStore.filteredMetalogs.length === 0)
        return false
      if (Store.badDates) return false
      if (
        Store.mode === 'is--employee_summary' ||
        Store.mode === 'is--patient_summary'
      )
        return false
      if (this.noEventsWarning) return false
      return true
    }

    // Computed
    get dataVisVisible() {
      return (
        this.logsVisiblePreference !== FULL_HEIGHT ||
        (Store.mode && Store.mode.includes('summary'))
      )
    }

    // Computed
    get noEventsWarning() {
      if (
        Store.user && // user selected
        Store.patient && // patient row selected
        ChartStore.selectedKey && // chart row is selected
        !MetalogStore.loading && // metalog store has finished loading
        MetalogStore.filteredMetalogs.length !== 0 && // metalog store contains some results
        Store.user.id === Store.userIdParam && // store is done switching between aliases
        !ChartStore.yKeys.includes(ChartStore.selectedKey) // results don't include the selected chart row
      ) {
        return (
          <>
            No events found between {this.fullName(Store.user)}{' '}
            <strong>{this.sourceAndInstance(Store.user)}</strong> and{' '}
            {this.fullName(Store.patient)}{' '}
            <strong>{this.sourceAndInstance(Store.patient)}</strong> in the
            selected time range.
          </>
        )
      }

      return null
    }

    // Computed
    get maxChartWarning() {
      if (MetalogStore.limitReached && EventDetailedStore.limitReached)
        return 'Max number of chart and table events reached. Export to view the full list of events.'
      if (MetalogStore.limitReached)
        return 'Max number of chart events reached. Export to view the full list of events.'

      return null
    }

    // Computed
    get maxEventsWarning() {
      // If events AND metalogs are maxed out, handle with a single warning above
      if (
        Store.user &&
        Store.focus !== 'employee_incidents' &&
        EventDetailedStore.limitReached &&
        !MetalogStore.limitReached
      )
        return 'Max number of table events reached. Export to view the full list of events.'

      return null
    }

    // Computed
    get suspicionScores() {
      if (Store.focus === 'employee_incidents')
        return DiversionActivityStore.suspicionScores
      return AssessmentStore.scores
    }

    // Computed
    get hasOverlappingCaseAssessments() {
      const flaggedAssessments = [...DiversionActivityStore.flagged.values()]
      if (!flaggedAssessments.length) return false
      const openCases =
        CaseStore.openDiversionCasesByUserId[flaggedAssessments[0]?.user?.id]

      // going forward there should only ever be one open case per user, but filtering for each case is a safety in case there are legacy or edge case duplications
      // using some here to short the iteration as we only need to know if there is one or more
      return (
        openCases &&
        openCases.some(({ analyticAssessments = [] }) =>
          // the array of analyticAssessments from each case
          analyticAssessments.some(cAssessment =>
            // each selected assessment from the activity view
            flaggedAssessments.some(
              // has overlap if the assessment overlaps the current assessment minDate exclusive of case assessment end time
              assessment =>
                moment(cAssessment.minEventDate).isBetween(
                  assessment.startTime,
                  assessment.endTime,
                  undefined,
                  '[)'
                ) ||
                // or the assessment overlaps the current assessment maxDate exclusive of case assessment start time
                moment(cAssessment.maxEventDate).isBetween(
                  assessment.startTime,
                  assessment.endTime,
                  undefined,
                  '(]'
                )
            )
          )
        )
      )
    }

    // This method calculates EventLogs/Assessments table Height Only
    // The rest of the Activity Chart layout now uses Flexbox
    // TODO: See if this can also use Flexbox and have the animation working
    updateTableSize = () => {
      let newH = ROW_HEIGHT
      if (this.logsVisible) {
        // <main> is the parent element of Activity Chart
        // the events & assessment tab is half the height after substracting export/sorting tab's height
        newH = $('main').height() / 2 - 62.5
        if (this.logsVisiblePreference > 1) {
          // Fullscreen Logs height (expanded)
          newH = $('main').height()
        } else if (Store.optionsVisible) {
          // Substract export/sorting tab options when it's open
          newH -= ROW_HEIGHT / 2
        }
      }
      // For event nav bar
      newH -= 60
      if (this.noEventsWarning || this.maxChartWarning) newH -= 15
      if (this.maxEventsWarning) newH -= 15
      // +1 for overlapping the border
      this.tableHeight = newH + 1 || 0

      const tableEl = this.table.current
      if (tableEl !== null) {
        this.tableWidth = tableEl.offsetWidth
      }
    }

    onMainWidthChange = () => {
      if (this.main.current) {
        ChartStore.setWidth(this.main.current && this.main.current.clientWidth)
      }
    }

    fullName = person => {
      const fn = person.firstName
      const ln = person.lastName

      let name = ''
      if (fn) name += ` ${fn}`
      if (ln) name += ` ${ln}`
      return name
    }

    /**
     * Format source and optional instance for use within the "no events found"
     * warning message.
     *
     * @param source {string}
     * @param instance {string}
     * @return {string}
     */
    sourceAndInstance = ({ source, instance }) => {
      return `(${source}${instance ? ` [${instance}]` : ''})`
    }

    toggleLogs = upOrDown => {
      if (upOrDown === 'up') {
        ++this.logsVisiblePreference
      } else {
        --this.logsVisiblePreference
      }
    }

    logTip = upOrDown => {
      if (upOrDown === 'up') {
        if (this.logsVisiblePreference === HALF_HEIGHT) {
          return 'Fullscreen Logs'
        }
        return 'Toggle up'
      }
      if (this.logsVisiblePreference === HALF_HEIGHT) {
        return 'Minimize Logs'
      }
      return 'Toggle Down'
    }

    renderLogControls = createCaseType => {
      const controls = []

      const openDiversionCasesByUserId = CaseStore.openDiversionCasesByUserId
      const flaggedAssessments = [...DiversionActivityStore.flagged.values()]

      if (createCaseType) {
        const permissions = ['CASE_CREATE']
        if (createCaseType === 'PrivacyCase')
          permissions.push('PRIVACY_ASSESSMENT_VIEW')

        // new case promotion button
        controls.push(
          <CasePromotion
            key="create-case"
            categories={AlertCategoryStore.categories}
            flaggedEvents={EventDetailedStore.flagged}
            flaggedAssessments={flaggedAssessments}
            openDiversionCasesByUserId={openDiversionCasesByUserId}
            permissions={permissions}
            createCaseType={createCaseType}
            onSubmit={newCase => CaseStore.createCase(newCase)}
            addAssessments={CaseStore.addAssessmentsToDiversionCase}
            hasOverlappingCaseAssessments={this.hasOverlappingCaseAssessments}
          />
        )
      }

      // UOA Merging
      if (
        this.logsVisiblePreference !== HIDDEN &&
        MetalogStore.userOnlyMetalogs.length !== 0 &&
        Store.focus === 'employee'
      ) {
        if (Store.uoaMergedPreference) {
          controls.push(
            <span key="merge-uoa">
              <Tooltip content="Exclude User Only Events">
                <i
                  onClick={Store.toggleMergedUOA.bind(Store)}
                  className="material-icons icon-call_split prot-a"
                />
              </Tooltip>
            </span>
          )
        } else {
          controls.push(
            <span key="merge-uoa">
              <Tooltip content="Include User Only Events">
                <i
                  onClick={Store.toggleMergedUOA.bind(Store)}
                  className="material-icons icon-call_merge prot-a"
                />
              </Tooltip>
            </span>
          )
        }
      }

      // Shrink table
      if (this.logsVisiblePreference !== HIDDEN) {
        controls.push(
          <span key="shrink-table" data-cy="min-logs">
            <Tooltip content={this.logTip('down')}>
              <i
                onClick={() => this.toggleLogs('down')}
                className="material-icons icon-keyboard_arrow_down prot-a"
              />
            </Tooltip>
          </span>
        )
      }

      // Grow table
      if (this.logsVisiblePreference !== FULL_HEIGHT) {
        controls.push(
          <span key="grow-table" data-cy="max-logs">
            <Tooltip content={this.logTip('up')}>
              <i
                onClick={() => this.toggleLogs('up')}
                className="material-icons icon-keyboard_arrow_up prot-a"
              />
            </Tooltip>
          </span>
        )
      }

      return (
        <div className="log_controls" data-cy="event-log-control">
          {controls}
        </div>
      )
    }

    renderDrawer() {
      const isDiversionTab = Store.focus === 'employee_incidents'
      const assessments = isDiversionTab
        ? DiversionActivityStore.statisticsWithoutOutOfBoundIncidents
        : AssessmentStore.assessments
      const createCaseType =
        DiversionActivityStore.flagged.size && 'DiversionCase'
      const loaded = isDiversionTab
        ? !DiversionActivityStore.statisticStore.loading
        : !AssessmentStore.loading

      const assessmentsSection = this.assessmentsSection

      if (Store.activeDrawerTab === 'Events') {
        return (
          <section
            ref={this.table}
            className="eventLogs"
            style={{
              height: this.logsVisiblePreference
                ? this.tableHeight
                : ROW_HEIGHT,
            }}
            data-cy="event-column"
          >
            {this.renderLogControls(
              EventDetailedStore.someFlagged && 'PrivacyCase'
            )}
            <Loader loaded={!EventDetailedStore.loading}>
              <EventDetails
                getActivityDescription={this.activityDescriptionStore.get}
                activityDefsMap={this.activityDescriptionStore.defsMap}
              />
              <EventTable
                canCheck={PermissionStore.hasAll([
                  'CASE_CREATE',
                  'PRIVACY_ASSESSMENT_VIEW',
                ])}
                width={this.tableWidth}
                height={this.tableHeight}
              />
            </Loader>
          </section>
        )
      }
      if (Store.activeDrawerTab === 'Incidents') {
        return (
          <IncidentContext.Provider
            value={{
              incidentStateStore: this.incidentStore,
            }}
          >
            <section
              className="event-box"
              style={{
                height: this.logsVisiblePreference
                  ? this.tableHeight
                  : ROW_HEIGHT,
              }}
            >
              <Loader loaded={!DiversionActivityStore.incidentStore.loading}>
                <DrawerIncidents
                  drawerControls={this.renderLogControls()}
                  onSelectEvent={EventDetailedStore.setIncidentFocus}
                  onSelectIncident={EventDetailedStore.focusOn}
                  rangeStart={Store.fromDate}
                  rangeEnd={Store.toDate}
                  statistics={DiversionActivityStore.statistics}
                  scrollToInc={EventDetailedStore.scrollToInc}
                  clearScrollToInc={EventDetailedStore.clearScrollToInc}
                  selectedIncident={
                    EventDetailedStore.detailFocus &&
                    EventDetailedStore.detailFocus.id
                  }
                  personLink={(id, personType) =>
                    Store[`get${personType.capitalizeFirstLetter()}Link`]({
                      [`${personType}Id`]: id,
                    })
                  }
                  hasUnsavedNotes={this.incidentStore.hasUnsavedNotes}
                />
              </Loader>
            </section>
          </IncidentContext.Provider>
        )
      }
      if (Store.activeDrawerTab === 'Assessments') {
        return (
          <section
            className="event-box"
            style={{
              height: this.logsVisiblePreference
                ? this.tableHeight
                : ROW_HEIGHT,
            }}
          >
            <Loader loaded={loaded}>
              <DrawerHeader count={assessments.length} type="Assessment">
                {this.renderLogControls(createCaseType)}
              </DrawerHeader>
              <section
                className={styles.assessments}
                ref={node => {
                  this.assessmentsSection = node
                }}
                data-cy="assessments-text"
              >
                <ScrollIntoViewContext.Provider
                  value={
                    assessmentsSection
                      ? {
                          scrollIntoView: node => {
                            if (node) {
                              $(assessmentsSection).animate(
                                {
                                  scrollTop: node.offsetTop - 10,
                                },
                                500
                              )
                            }
                          },
                        }
                      : null
                  }
                >
                  <AssessmentsForCaseTypeContainer />
                </ScrollIntoViewContext.Provider>
              </section>
            </Loader>
          </section>
        )
      }
    }

    renderFiltersAndOptions() {
      if (this.dataVisVisible) {
        return (
          <span>
            <ActivityFilters />
            <ActivityOptionsContainer />
          </span>
        )
      }
      return null
    }

    render() {
      const cn = classnames('activity_view__body', Store.mode)

      const accessVisClasses = classnames('dataVis', {
        hiddenLogs: !this.logsVisible,
        optionsVisible: Store.optionsVisible,
        fullLogs:
          this.logsVisiblePreference === FULL_HEIGHT && this.logsVisible,
      })
      return (
        <div className={cn}>
          <DocumentTitle text={Store.activityViewDocumentTitle} />
          <ApplyBodyClassName className="activity_view" />
          <TopNav />
          <div className="activity_view__content">
            {!Store.hidePatientColumn && (
              <PatientBoxContainer forPage="activity" />
            )}
            <main ref={this.main}>
              {this.renderFiltersAndOptions()}
              {Boolean(this.maxChartWarning) ||
              Boolean(this.noEventsWarning) ? (
                <SysAlert
                  visible
                  level="warning"
                  position="relative"
                  hideable={false}
                >
                  {this.maxChartWarning || this.noEventsWarning}
                </SysAlert>
              ) : null}
              {SmartReferenceStore.correctFocus && !MetalogStore.loading ? (
                <SysAlert
                  visible={SmartReferenceStore.correctFocus}
                  level="flag"
                  position="relative"
                  hideable
                  onHide={SmartReferenceStore.reset}
                >
                  {SmartReferenceStore.caseLink ? (
                    <Link to={`/case/${SmartReferenceStore.caseLink}`}>
                      {`Case ${SmartReferenceStore.caseNumber}`}
                    </Link>
                  ) : null}
                  &nbsp;
                  {SmartReferenceStore.description}
                </SysAlert>
              ) : null}
              <ActivityVisualization className={accessVisClasses} />
              {this.dataVisVisible ? <XAxisChartContainer /> : null}
              {this.logsVisible && (
                <DrawerNav
                  activeTab={Store.activeDrawerTab}
                  onChange={Store.setDrawerTab}
                  suspicionScores={this.suspicionScores}
                  tabs={Store.drawerTabs}
                  type={
                    Store.focus === 'employee_incidents'
                      ? 'Diversion'
                      : 'Privacy'
                  }
                />
              )}
              {this.maxEventsWarning ? (
                <SysAlert
                  visible
                  level="warning"
                  position="relative"
                  hideable={false}
                >
                  {this.maxEventsWarning}
                </SysAlert>
              ) : null}
              {this.logsVisible && this.renderDrawer()}
            </main>
            {!Store.hideUserColumn && (
              <EmployeeBoxContainer forPage="activity" />
            )}
          </div>
        </div>
      )
    }
  }
)

decorate(ActivityView, {
  logsVisiblePreference: observable,
  eventsVisible: observable,
  tableHeight: observable,
  tableWidth: observable,
  logsVisible: computed,
  dataVisVisible: computed,
  hasOverlappingCaseAssessments: computed,
  maxChartWarning: computed,
  maxEventsWarning: computed,
  noEventsWarning: computed,
  suspicionScores: computed,
})

ActivityView.displayName = 'ActivityView'

export default ActivityView
