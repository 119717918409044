import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { Authenticated } from 'common'

import CaseLink from '../../ui/CaseLink'
import DeleteForm from '../../ui/DeleteForm'
import Modal from '../../ui/Modal'
import AsideHelpers from '../../ui/AsidePanel/AsideHelpers'
import { AsidePanel } from '../../ui'

import styles from './index.module.scss'

const PersonTagView = observer(
  class PersonTagView extends React.Component {
    static propTypes = {
      store: PropTypes.object.isRequired,
      tagsStore: PropTypes.object.isRequired,
      panelLinkTo: PropTypes.string.isRequired,
      cases: PropTypes.number,
      caseId: PropTypes.string,
      panelTitle: PropTypes.string,
      panelHeading: PropTypes.string,
      redirectTo: PropTypes.string,
      personLink: PropTypes.string,
    }

    static defaultProps = {
      panelTitle: 'Tag Detail',
      panelHeading: 'Tagged',
    }

    destroy = e => {
      e.preventDefault()
      const { store, redirectTo } = this.props

      const deletePromise = () =>
        store.removeCurrentTag().then(() => {
          AsideHelpers.closePanel(redirectTo)
        })

      Modal.show(
        <Modal closable>
          <DeleteForm
            modal
            description="This action will remove this tag from this assignee; it will not delete the tag from the system."
            remove
            subject="tag"
            submit={deletePromise}
          />
        </Modal>
      )
    }

    renderName() {
      const { store, personLink } = this.props
      const name = <span>{store.activeUser && store.activeUser.fullName}</span>

      if (personLink) {
        return <Link to={personLink}>{name}</Link>
      }
      return name
    }

    renderCases() {
      const { store, cases, caseId } = this.props

      if (store.activeUser) {
        return (
          <CaseLink user={store.activeUserId} caseId={caseId} cases={cases}>
            <span>
              {cases} Case{cases === 1 ? '' : 's'}
            </span>
          </CaseLink>
        )
      }
    }

    render() {
      const { panelTitle, panelLinkTo, panelHeading } = this.props

      return (
        <AsidePanel
          isOpen
          title={panelTitle}
          closeLinkTo={panelLinkTo}
          className={styles.asidePanel}
          actionNavHeight
        >
          <section className="pane-grid">
            <div className="pane-grid__row">
              <div className="item">
                <h4>{panelHeading}</h4>
                <span>{this.renderName()}</span>
              </div>
              <div className="item">
                <h4>Unresolved Cases</h4>
                <span>{this.renderCases()}</span>
              </div>
            </div>
          </section>
          <Authenticated permission="PERSON_TAG_ASSIGN">
            <section className="row no-gutter">
              <div className="form__actions">
                <span
                  onClick={this.destroy}
                  className={classnames(
                    'text-danger float-right prot-a',
                    styles.removeTag
                  )}
                  data-cy="user-tag-remove-button"
                >
                  Remove Tag
                </span>
              </div>
            </section>
          </Authenticated>
        </AsidePanel>
      )
    }
  }
)

PersonTagView.displayName = 'PersonTagView'

export default PersonTagView
