import React from 'react'
import PropTypes from 'prop-types'
import { FullName } from 'common'
import styles from './index.module.scss'

export const ValueComponent = ({ item }) => {
  return (
    <div className={styles.valueInput}>
      <strong>{item.userId ? 'EMR Record' : 'Medical Record'} </strong>
      {item.source}
      {item.instance && ' '}
      {item.instance}
    </div>
  )
}

const itemProps = {
  mode: PropTypes.string,
  source: PropTypes.string,
  instance: PropTypes.string,
  userId: PropTypes.string,
  patientId: PropTypes.string,
}

ValueComponent.propTypes = {
  item: PropTypes.shape(itemProps),
}

ValueComponent.defaultProps = {
  item: {},
}

export const ItemComponent = ({ item }) => {
  return (
    <div>
      <strong>
        {item.source}
        {item.instance && ' '}
        {item.instance}
      </strong>{' '}
      <FullName person={item} condensed />
    </div>
  )
}

ItemComponent.propTypes = {
  item: PropTypes.shape(itemProps),
}

ItemComponent.defaultProps = {
  item: {},
}
