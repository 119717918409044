import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './index.module.scss'

const MoreBack = ({
  showingMore,
  onToggle,
  moreLabel,
  backLabel,
  'data-cy': dataCy,
}) => {
  return (
    <div className={classnames('box-nav', styles.moreBack)} data-cy={dataCy}>
      <span
        onClick={() => onToggle()}
        className={classnames(
          'prot-a forScreen',
          showingMore ? 'float-left' : 'float-right'
        )}
      >
        {showingMore ? (
          <>
            <span>
              <i className="material-icons icon-keyboard_arrow_left" />
            </span>
            {backLabel}
          </>
        ) : (
          <>
            {moreLabel}
            <span>
              <i className="material-icons icon-keyboard_arrow_right" />
            </span>
          </>
        )}
      </span>
    </div>
  )
}

MoreBack.propTypes = {
  'data-cy': PropTypes.string,
  showingMore: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  moreLabel: PropTypes.string,
  backLabel: PropTypes.string,
}

MoreBack.defaultProps = {
  moreLabel: 'More Info',
  backLabel: 'Back',
}

export default MoreBack
