import React, { useState } from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './index.module.scss'

const MultilineInput = ({
  autoFocus,
  label,
  placeholder,
  value,
  warn,
  bordered,
  borderRadius,
  readOnly,
  darkBackground,
  onChange,
  onFocus,
  rows,
  maxRows,
  'data-cy': dataCy,
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const classes = classnames(styles.multilineInputContainer, {
    [styles.showLabel]: isFocused || !!value,
    [styles.warn]: warn,
    [styles.bordered]: bordered,
    [styles.borderRadius]: borderRadius,
    [styles.readOnly]: readOnly,
    [styles.labeled]: label,
    [styles.darkBackground]: darkBackground,
  })

  return (
    <div className={classes}>
      {label && <label className={styles.label}>{label}</label>}
      <TextareaAutosize
        autoFocus={autoFocus}
        className={styles.textarea}
        value={value || ''}
        onChange={e => onChange(e.target.value)}
        onFocus={() => {
          setIsFocused(true)
          onFocus()
        }}
        onBlur={() => {
          setIsFocused(false)
        }}
        placeholder={placeholder || label}
        tabIndex={0}
        readOnly={readOnly}
        rows={rows}
        maxRows={maxRows}
        data-cy={dataCy}
      />
    </div>
  )
}

MultilineInput.propTypes = {
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  warn: PropTypes.bool,
  bordered: PropTypes.bool,
  borderRadius: PropTypes.bool,
  readOnly: PropTypes.bool,
  darkBackground: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  rows: PropTypes.number,
  maxRows: PropTypes.number,
  'data-cy': PropTypes.string,
}

MultilineInput.defaultProps = {
  autoFocus: false,
  label: null,
  placeholder: null,
  value: '',
  warn: false,
  bordered: false,
  borderRadius: false,
  readOnly: false,
  darkBackground: false,
  onChange: () => {},
  onFocus: () => {},
  rows: 5,
  maxRows: null, //meaning will auto-resize
  'data-cy': '',
}

export default MultilineInput
