import React from 'react'
import moment from 'moment'
import pluralize from '../../utils/pluralize'
import { generateMetadataLink, buildLink } from './LinkUtils'
import { capitalizeFirstLetter } from 'common'
import { PERSON_TYPE_USER } from '../stores/const'
import {
  metadataTypes,
  EXISTING_CASE_METADATA_TITLE,
  USER_RECORD_METADATA_TITLE,
  MEDICAL_RECORD_METADATA_TITLE,
  VIP_REGISTRATION_METADATA_TITLE,
  USER_TAG_METADATA_TITLE,
  AUTHORIZED_ACCESSES_METADATA_TITLE,
  EHR_VIP_TYPE,
  NEWS_VIP_TYPE,
  WIKI_VIP_TYPE,
} from './const'

export const generateTooltipContent = (count, title, plural = true) => {
  return count > 0
    ? `${count} ${pluralize(count, title)}`
    : `No ${plural ? pluralize(count, title) : title}`
}

export const generateFormattedMetadataInfo = (title, metadata, personType) => {
  const formattedMetadataInfo = {}

  switch (title) {
    case AUTHORIZED_ACCESSES_METADATA_TITLE:
      formattedMetadataInfo.count = metadata?.length || 0
      formattedMetadataInfo.metadataMultilineText = []
      formattedMetadataInfo.metadataMulticolumnText = []
      formattedMetadataInfo.multilineTooltipContent = []
      formattedMetadataInfo.multilineTooltip = metadata?.length > 0
      metadata?.length > 0
        ? metadata.map(({ patientFullName, userFullName, status }) => {
            return formattedMetadataInfo.multilineTooltipContent.push({
              textLeftCol: `${
                personType === PERSON_TYPE_USER ? patientFullName : userFullName
              }`,
              textRightCol: capitalizeFirstLetter(status.toLowerCase()),
            })
          })
        : (formattedMetadataInfo.tooltipContent = generateTooltipContent(
            0,
            title,
            false
          ))
      metadata =
        metadata?.length > 0
          ? metadata?.map(
              ({
                patientFullName,
                userFullName,
                status,
                id,
                start,
                requestedEnd,
              }) => {
                formattedMetadataInfo.metadataMulticolumnText.push({
                  textLeftCol:
                    personType === PERSON_TYPE_USER
                      ? patientFullName
                      : userFullName,
                  textRightCol: capitalizeFirstLetter(status.toLowerCase()),
                  tooltipContent: `${formatDate(start)} - ${formatDate(
                    requestedEnd
                  )}`,
                  className: 'expiredRevoked',
                  styleCondition: status === 'EXPIRED' || status === 'REVOKED',
                  link: `/authorizations/${id}`,
                })
                return metadata
              }
            )
          : formattedMetadataInfo.metadataMulticolumnText.push({
              textLeftCol: generateTooltipContent(0, title, false),
              textRightCol: 'Create',
              tooltipContent: generateTooltipContent(0, title, false),
              link: `/authorizations/create`,
              disabled: false,
              disabledStyle: true,
            })
      break
    case VIP_REGISTRATION_METADATA_TITLE:
      formattedMetadataInfo.count = metadata?.length > 0 ? metadata.length : 0
      formattedMetadataInfo.tooltipContent =
        metadata?.length > 0 ? 'VIP' : generateTooltipContent(0, title, false)
      formattedMetadataInfo.metadataMultilineText = []
      metadata =
        metadata?.length > 0
          ? metadata?.map(({ types, endDate }) => {
              formattedMetadataInfo.metadataMultilineText.push({
                text: `${
                  types?.length > 0
                    ? `${types
                        ?.sort()
                        ?.map(type => formatVipType(type))
                        .join(', ')}`
                    : 'VIP'
                } ${
                  endDate ? `(Exp. ${formatDate(endDate).toString()})` : ''
                }`?.trim(),

                link: buildLink('/vips', {
                  types: types?.sort()?.map(type => formatVipType(type)),
                }),
              })
              return metadata
            })
          : formattedMetadataInfo.metadataMultilineText.push({
              text: generateTooltipContent(0, title, false),
              link: buildLink('/vips', {}),
              disabled: true,
            })
      break
    case USER_TAG_METADATA_TITLE:
      formattedMetadataInfo.count = metadata?.length || 0
      formattedMetadataInfo.tooltipContent =
        metadata?.length > 0
          ? metadata
              ?.map(({ name }) => name)
              ?.sort()
              ?.join(', ')
          : generateTooltipContent(0, title)
      formattedMetadataInfo.metadataText = formattedMetadataInfo.tooltipContent
      break

    case USER_RECORD_METADATA_TITLE:
    case MEDICAL_RECORD_METADATA_TITLE:
    case EXISTING_CASE_METADATA_TITLE:
      formattedMetadataInfo.count = metadata || 0
      formattedMetadataInfo.tooltipContent = generateTooltipContent(
        metadata,
        title
      )
      formattedMetadataInfo.metadataText = formattedMetadataInfo.tooltipContent
      break
    default:
      formattedMetadataInfo.count = 0
      formattedMetadataInfo.tooltipContent = generateTooltipContent(
        metadata,
        title
      )
      formattedMetadataInfo.metadataText = formattedMetadataInfo.tooltipContent
      break
  }

  return formattedMetadataInfo
}

export const generateMetadataTypes = (metadata, personType) => {
  const generatedMetadataTypes = []

  metadataTypes
    .filter(
      metadataType =>
        !metadataType.personType || metadataType.personType === personType
    )
    .map(metadataType => {
      switch (metadataType.title) {
        case EXISTING_CASE_METADATA_TITLE:
          metadataType.data = generateFormattedMetadataInfo(
            EXISTING_CASE_METADATA_TITLE,
            metadata?.existingCases?.length
          )
          metadataType.link = generateMetadataLink(
            EXISTING_CASE_METADATA_TITLE,
            { data: metadata?.existingCases, personId: metadata?.personId },
            personType
          )
          generatedMetadataTypes.push(metadataType)
          break
        case USER_RECORD_METADATA_TITLE:
          metadataType.data = generateFormattedMetadataInfo(
            USER_RECORD_METADATA_TITLE,
            metadata?.userCount
          )
          metadataType.link = generateMetadataLink(
            USER_RECORD_METADATA_TITLE,
            { personId: metadata?.personId, linkId: metadata?.linkId },
            personType
          )
          generatedMetadataTypes.push(metadataType)
          break
        case MEDICAL_RECORD_METADATA_TITLE:
          metadataType.data = generateFormattedMetadataInfo(
            MEDICAL_RECORD_METADATA_TITLE,
            metadata?.patientCount
          )
          metadataType.link = generateMetadataLink(
            MEDICAL_RECORD_METADATA_TITLE,
            { personId: metadata?.personId, linkId: metadata?.linkId },
            personType
          )
          generatedMetadataTypes.push(metadataType)
          break
        case VIP_REGISTRATION_METADATA_TITLE:
          metadataType.data = generateFormattedMetadataInfo(
            VIP_REGISTRATION_METADATA_TITLE,
            metadata?.vipRegistrations
          )
          metadataType.link = generateMetadataLink(
            VIP_REGISTRATION_METADATA_TITLE,
            metadata?.vipRegistrations
          )
          generatedMetadataTypes.push(metadataType)
          break
        case USER_TAG_METADATA_TITLE:
          metadataType.data = generateFormattedMetadataInfo(
            USER_TAG_METADATA_TITLE,
            metadata?.personTags
          )
          metadataType.link = generateMetadataLink(USER_TAG_METADATA_TITLE, {
            personId: metadata?.personId,
            personTags: metadata?.personTags,
          })
          generatedMetadataTypes.push(metadataType)
          break
        case AUTHORIZED_ACCESSES_METADATA_TITLE:
          metadataType.data = generateFormattedMetadataInfo(
            AUTHORIZED_ACCESSES_METADATA_TITLE,
            metadata?.accessAuthorizations,
            personType
          )
          metadataType.link = generateMetadataLink(
            AUTHORIZED_ACCESSES_METADATA_TITLE,
            metadata?.acessAuthorizations
          )
          generatedMetadataTypes.push(metadataType)
          break
        default:
          break
      }
      return metadataType
    })

  return generatedMetadataTypes
}

export const formatVipType = type => {
  switch (type) {
    case EHR_VIP_TYPE:
      return 'EHR VIP'
    case NEWS_VIP_TYPE:
    case WIKI_VIP_TYPE:
      return 'Online References'
    default:
      break
  }
}

export const formatDate = date => {
  return moment(date).format('l')
}

export const escapeRegExp = string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export const generateHighlightedText = (text, searchTerm) => {
  if (!searchTerm) return text
  const splitText = text?.split(
    new RegExp(`(${escapeRegExp(searchTerm)})`, 'gi')
  )
  return (
    <span key={text}>
      {splitText?.map((text, i) =>
        text?.toLowerCase() === searchTerm?.toLowerCase() ? (
          <strong key={`${text} - ${i}`} className="highlighted">
            {text}
          </strong>
        ) : (
          text
        )
      )}
    </span>
  )
}

export const singularize = word => {
  if (word.slice(-3) === 'ses') {
    return word.substr(0, word.length - 2)
  } else if (word.slice(-1) === 's') {
    return word.substr(0, word.length - 1)
  }
  return word
}
