import React from 'react'
import moment from 'moment'
import { computed, decorate } from 'mobx'
import { observer } from 'mobx-react'
import * as d3 from 'd3'

import {
  HTMLEntities,
  RouterContainer,
  GroupStore,
  convertMultiValue,
} from 'common'
import classnames from 'classnames'
import CaseAgesStore from '../stores/CaseAgesStore'

const DATE_FORMAT = 'YYYY-MM-DD'

/**
 * Component that produces a list of the open cases.
 * @extends React.Component
 */
const CaseAgesList = observer(
  class CaseAgesList extends React.Component {
    /**
     * COMPUTED
     * Computed list of known categories for the Open Cases report on the Dashboard.
     * @return {Array} List of categories
     */
    get categories() {
      const store = CaseAgesStore
      const { emdash, gt } = HTMLEntities

      return [
        {
          name: 'upToFifteenDays',
          total: store.totalUpToFifteen,
          range: [null, 15],
          label: `0 ${emdash} 15 Days`,
        },
        {
          name: 'fifteenToThirtyDays',
          total: store.totalFifteenToThirty,
          range: [16, 30],
          label: `16 ${emdash} 30 Days`,
        },
        {
          name: 'thirtyToSixtyDays',
          total: store.totalThirtyToSixty,
          range: [31, 60],
          label: `31 ${emdash} 60 Days`,
        },
        {
          name: 'moreThanSixtyDays',
          total: store.totalMoreThanSixty,
          range: [61, null],
          label: `${gt} 60 Days`,
        },
      ]
    }

    handleMouseEnter = name => {
      CaseAgesStore.selectedSegment = name

      d3.selectAll(`[data-group='${name}']`).classed('active-selection', true)

      d3.selectAll(
        `.case-ages__chart-segment:not([data-group='${name}'])`
      ).classed('not-active-selection', true)

      d3.selectAll(
        `.case-ages__chart-segment-label:not([data-group='${name}'])`
      ).classed('not-active-selection', true)
    }

    handleMouseLeave = name => {
      CaseAgesStore.selectedSegment = null

      d3.select(`[data-group='${name}']`).classed('active-selection', false)

      d3.selectAll(
        `.case-ages__chart-segment:not([data-group='${name}'])`
      ).classed('not-active-selection', false)

      d3.selectAll(
        `.case-ages__chart-segment-label:not([data-group='${name}'])`
      ).classed('not-active-selection', false)
    }

    handleClick = (e, category) => {
      e.preventDefault()

      const params = {}

      params.showFilters = true
      params.resolution = 'null'
      params.createdRange = 'custom'

      if (CaseAgesStore.owner) params.owner = CaseAgesStore.owner

      if (category.range[0])
        params.createdBefore = moment()
          .subtract(category.range[0], 'days')
          .format(DATE_FORMAT)

      if (category.range[1]) {
        params.createdAfter = moment()
          .subtract(category.range[1], 'days')
          .format(DATE_FORMAT)
      } else {
        params.createdAfter = moment(0).format(DATE_FORMAT)
      }

      CaseAgesStore.group
        ? (params.group = convertMultiValue(CaseAgesStore.group))
        : (params.group = convertMultiValue(GroupStore.groupIds))

      RouterContainer.go('/cases', params)
    }

    /**
     * Create a mapping of DOM nodes for the categories (stored in
     * this.categories) that track active/disabled state, detailed information
     * about the category.
     * @return {Array} Array of React DOM nodes to be rendered
     */
    renderCategories() {
      return this.categories.map((category, idx) => (
        <li
          key={`ageCategory${idx}`}
          className={classnames('caseAge-list-selection', {
            'not-active-selection':
              CaseAgesStore.selectedSegment &&
              CaseAgesStore.selectedSegment !== category.name,
            'active-selection': CaseAgesStore.selectedSegment === category.name,
          })}
          onMouseEnter={() => this.handleMouseEnter(category.name)}
          onMouseLeave={() => this.handleMouseLeave(category.name)}
          onClick={e => this.handleClick(e, category)}
        >
          <i className="material-icons icon-brightness_1" />
          <span>{category.label}</span>
          <span className="data__count">
            {category.total}&nbsp;{category.total === 1 ? 'Case' : 'Cases'}
          </span>
        </li>
      ))
    }

    /**
     * Render the component.
     * @return {Object} - the React DOM node
     */
    render() {
      return <ul className="data data__breakdown">{this.renderCategories()}</ul>
    }
  }
)

decorate(CaseAgesList, {
  categories: computed,
})

CaseAgesList.displayName = 'CaseAgesList'

export default CaseAgesList
