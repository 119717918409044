import React from 'react'
import { observer } from 'mobx-react'
import { SAI360CaseIntegration } from '../SAI360CaseIntegration'
import { CaseIntegrationStore } from '../../../stores/CaseIntegrationStore'

export function mapProps({ caseIntegrationStore }) {
  return {
    isEnabled: caseIntegrationStore.isEnabled,
    setEnabled: caseIntegrationStore.setEnabled,
    urlDisplay: caseIntegrationStore.urlDisplay,
    setUrl: caseIntegrationStore.setUrl,
    caseSyncSettings: caseIntegrationStore.activeCaseSyncSettings,
    setCaseSyncSettings: caseIntegrationStore.setSAI360SyncSetting,
  }
}

export const SAI360CaseIntegrationContainer = observer(() => {
  return (
    <SAI360CaseIntegration
      {...mapProps({
        caseIntegrationStore: CaseIntegrationStore,
      })}
    />
  )
})

SAI360CaseIntegrationContainer.displayName = 'SAI360CaseIntegrationContainer'
