import { action, decorate, observable } from 'mobx'
import { BaseURL, DownloadUtils, SingletonStore } from 'common'
import moment from 'moment'

class EPCSStore extends SingletonStore {
  // Observables
  endDate = moment().startOf('day')
  startDate = this.endDate.clone().subtract(6, 'days')
  radioOption = null

  setValue = (type, val) => {
    this[type] = val
  }

  downloadCSV = () => {
    let includeAuth = false
    let includeEPCSEvent = false

    if (this.radioOption === 'authOnly') includeAuth = true
    if (this.radioOption === 'epcsOnly') includeEPCSEvent = true
    if (this.radioOption === 'epcsAuth') {
      includeAuth = true
      includeEPCSEvent = true
    }

    const params = {
      startDate: this.startDate.format('YYYY-MM-DD'),
      endDate: this.endDate.format('YYYY-MM-DD'),
      includeAuth,
      includeEPCSEvent,
    }

    const href = BaseURL.service('epcs/report', params)

    const title = `EPCS reports from ${this.startDate.format(
      'l'
    )} to ${this.endDate.format('l')}.csv`

    DownloadUtils.downloadFromServer(href, title)
  }
}

decorate(EPCSStore, {
  startDate: observable,
  endDate: observable,
  radioOption: observable,
  setValue: action,
})

export { EPCSStore }
export default new EPCSStore()
