import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import $ from 'jquery'

import { RouterContainer } from 'common'

class Modal extends React.Component {
  state = { visible: false }
  overlay = React.createRef()

  componentDidMount() {
    const me = this
    this._escHandler = function(e) {
      if (this.props.closable && e.keyCode === 27) {
        this.hide()
      }
    }.bind(this)
    $(document).keyup(this._escHandler)
    setTimeout(() => {
      me.setState({ visible: true })
    }, 1)

    if (!this.props.ignoreRoute) {
      this.unlisten = RouterContainer.listen(this.transitionListener.bind(this))
    }
  }

  componentWillUnmount() {
    this.unlisten()
    $(document).off('keyup', this._escHandler)
  }

  transitionListener() {
    this.hide()
  }

  unlisten() {
    // placeholder
  }

  handleCloseBtnClick(e) {
    e.preventDefault()
    e.stopPropagation()
    this.hide()
  }

  handleOverlayClick(e) {
    if (e.target === this.overlay.current && this.props.closable) {
      e.preventDefault()
      e.stopPropagation()
      this.hide()
    }
  }

  // called from the outside world
  static show(reactElement) {
    // first make sure there's not already one in the dom
    this.cleanupDom()
    const div = document.createElement('div')
    div.id = 'modal--wrapper'
    document.body.appendChild(div)
    ReactDOM.render(reactElement, div)
  }

  static cleanupDom(delay) {
    const remove = () => {
      const dom = $(document)
        .find('#modal--wrapper')
        .get()
      if (dom && dom[0]) {
        ReactDOM.unmountComponentAtNode(dom[0])
        document.body.removeChild(dom[0])
      }
    }

    if (delay) setTimeout(remove, delay)
    else remove()
  }

  // called from the outside world
  hide() {
    this.setState({ visible: false })
    Modal.cleanupDom(500)
  }

  get closeBtn() {
    return (
      this.props.closable === true && (
        <span
          className="modal--close prot-a"
          onClick={this.handleCloseBtnClick.bind(this)}
        >
          <i className="material-icons icon-close" />
        </span>
      )
    )
  }

  render() {
    const classes = classnames(
      'modal',
      { show: this.state.visible },
      this.props.className
    )

    return (
      <div
        className={classes}
        ref={this.overlay}
        onClick={this.handleOverlayClick.bind(this)}
        data-cy="case-modal"
      >
        <div>
          {this.closeBtn}
          {this.props.children}
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closable: PropTypes.bool,
  ignoreRoute: PropTypes.bool,
}
Modal.defaultProps = {
  className: '',
  closable: true,
  ignoreRoute: false,
}

export default Modal
