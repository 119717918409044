import React from 'react'
import PropTypes from 'prop-types'
import { Source } from './..'
import moment from 'moment'
import styles from './index.module.scss'
import Anchor from '../Anchor'
import IncidentSummary from './IncidentSummary'

// this applies to both Privacy and Diversion
// look & feel should align w/ the new CaseEvent/Header
const Header = ({
  author,
  dateAdded,
  dateCreated,
  endDate,
  lastViewedByUser,
  startDate,
  type,
  onClick,
  source,
  condensedHeader,
  suspicionScore,
  unreconciledDrugs,
  incidentsLen,
  lastUpdatedByAG,
}) => {
  let activityBetween = null

  if (startDate && endDate) {
    if (!moment(startDate).isSame(endDate, 'd')) {
      activityBetween = (
        <span>
          {`Activity from ${moment(startDate).format(
            'MM/DD/YYYY'
          )} through ${moment(endDate).format('MM/DD/YYYY')}`}
        </span>
      )
    } else {
      activityBetween = (
        <span>{`Activity on ${moment(startDate).format('MM/DD/YYYY')}`}</span>
      )
    }
  }

  let newIcon = null

  // This checks whether the individual assessment was viewed
  if (
    (type === 'diversion' && !lastViewedByUser) ||
    moment(dateCreated || startDate).isAfter(moment(lastViewedByUser))
  ) {
    newIcon = (
      <i className={`material-icons icon-fiber_new ${styles.newIcon}`} />
    )
  }

  if (condensedHeader) {
    return (
      <div>
        <div className={styles.condensedHeader}>
          {onClick ? (
            <Anchor
              tag={'span'}
              onClick={onClick}
              data-cy="assessment-activity"
            >
              {activityBetween}
            </Anchor>
          ) : (
            activityBetween
          )}

          {source && <Source source={source} className={styles.iconInfo} />}
        </div>
        {type === 'diversion' ? (
          <IncidentSummary
            suspicionScore={suspicionScore}
            unreconciledDrugs={unreconciledDrugs}
            incidentsLen={incidentsLen}
          />
        ) : null}
      </div>
    )
  }

  return (
    <div>
      <h1 className={styles.header} data-cy="assessment-activity">
        {type === 'privacy' && 'Privacy Assessment Added'}
        {type === 'diversion' && 'Diversion Assessment Added'} {activityBetween}
        {newIcon}
      </h1>
      <p className={styles.desc}>
        Added by {author}{' '}
        <span>
          {(dateAdded || dateCreated || startDate) &&
            moment(dateAdded || dateCreated || startDate).format('lll')}{' '}
          based on data available{' '}
          {lastUpdatedByAG && moment(lastUpdatedByAG).format('ll')}
        </span>
      </p>
      {type === 'diversion' ? (
        <IncidentSummary
          suspicionScore={suspicionScore}
          unreconciledDrugs={unreconciledDrugs}
          incidentsLen={incidentsLen}
        />
      ) : null}
    </div>
  )
}

Header.propTypes = {
  author: PropTypes.string,
  dateAdded: PropTypes.string,
  dateCreated: PropTypes.string,
  startDate: PropTypes.string, // can substitute dateCreated
  endDate: PropTypes.string,
  type: PropTypes.string.isRequired,
  lastViewedByUser: PropTypes.string,
  onClick: PropTypes.func,
  condensedHeader: PropTypes.bool,
  source: PropTypes.string,
  suspicionScore: PropTypes.number,
  unreconciledDrugs: PropTypes.number,
  incidentsLen: PropTypes.number,
  lastUpdatedByAG: PropTypes.string,
}

Header.defaultProps = {
  author: 'Protenus',
  dateAdded: '',
  dateCreated: '',
  startDate: '',
  endDate: '',
  lastViewedByUser: '',
  onClick: null,
  condensedHeader: false,
  source: '',
}

export default Header
