import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const classes = (actionBar, filterBar, secondary, className) =>
  classnames('subnav', className, {
    actionbar: actionBar,
    filterbar: filterBar,
    secondary: secondary,
  });

const TopNavBar = ({
  children,
  actionBar,
  filterBar,
  secondary,
  className,
}) => (
  <header className={classes(actionBar, filterBar, secondary, className)} data-cy="filterbar">
    <ul>{children}</ul>
  </header>
);

TopNavBar.propTypes = {
  children: PropTypes.node,
  actionBar: PropTypes.bool,
  filterBar: PropTypes.bool,
  secondary: PropTypes.bool,
  className: PropTypes.string,
};

export default TopNavBar;
