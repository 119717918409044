import $ from 'jquery';
import moment from 'moment';
import { makeSessionStorageForKey } from 'common';
const DEFAULT_START = moment()
  .subtract(1, 'months')
  .format('YYYY-MM-DD');
const DEFAULT_END = moment().format('YYYY-MM-DD');
export const sqUser = makeSessionStorageForKey('sqUser-incidents');
export const sqPatient = makeSessionStorageForKey('sqPatient-incidents');
export const DEFAULT_DATES = [DEFAULT_START, DEFAULT_END];

export function mapKeys(key) {
  if (key === 'adcs') return 'station';
  if (key === 'dates') return 'range';
  if (key === 'After') return 'rangeAfter';
  if (key === 'Before') return 'rangeBefore';
  if (key === 'patientFullNames') return 'patientFullNames';
  if (key === 'medications') return 'medications';
  if (key === 'userFullNames') return 'userFullNames';
  if (key === 'status') return 'resolution';
  if (key === 'types') return 'type';
  if (key === 'groupBy') return 'groupBy';
  if (key === 'sortBy') return 'sort';
  if (key === 'groups') return 'group';
  if (key === 'incidentIds') return 'incidentIds';
  return null;
}

export function hasThisOption(filterOpt = null, options = []) {
  return options.find(opt => opt.value === filterOpt) || null;
}

export function formatFilterDate(date) {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  return { value: formattedDate };
}

export function handleOnChange(
  key,
  opt,
  queryCriteria,
  historyPush,
  exclude = false
) {
  const newCriteria = { ...queryCriteria };
  const k = mapKeys(key);
  let val = opt.value;
  delete newCriteria[k]; // delete old property

  if (exclude && opt.length) {
    // Add k to list of excludeFilters
    // If list of excludeFilters only has 1 then it will be just a string and not in an array,
    // you must put the existing excludeFilter into an array and then add the new excludeFilter
    newCriteria.excludeFilters = Array.from(
      new Set([
        ...(typeof newCriteria.excludeFilters === 'string'
          ? [newCriteria.excludeFilters]
          : newCriteria.excludeFilters || []),
        k,
      ])
    );
  } else {
    newCriteria.excludeFilters =
      typeof newCriteria?.excludeFilters === 'string'
        ? [newCriteria.excludeFilters]?.filter(f => f !== k)
        : newCriteria.excludeFilters?.filter(f => f !== k);

    // If exclude is false and there are no items in excludeFilters,
    // then delete that object from the query
    if (!newCriteria.excludeFilters) {
      delete newCriteria.excludeFilters;
    }
  }

  if (k === 'range' && val !== 'custom') {
    delete newCriteria.rangeAfter;
    delete newCriteria.rangeBefore;
  }

  if (k === 'range' && val === 'custom' && !newCriteria.rangeAfter) {
    newCriteria.rangeAfter = DEFAULT_DATES[0];
  }

  if (k === 'range' && val === 'custom' && !newCriteria.rangeBefore) {
    newCriteria.rangeBefore = DEFAULT_DATES[1];
  }

  if (k === 'groupBy') delete newCriteria.sort; // delete sort property for reset
  if (val === 'user' || val === 'patient' || val === 'station')
    newCriteria.sort = 'asc'; // we want A-Z for humans and meds

  // Create comma-separated list of incidentIds, userFullNames, patientFullNames and medications
  if (
    k === 'incidentIds' ||
    k === 'userFullNames' ||
    k === 'patientFullNames' ||
    k === 'medications'
  ) {
    val = opt
      .filter(o => o.trim() !== '')
      .map(o => o.trim())
      .join();
    if (val === '') val = null;
  }

  if (k === 'group') newCriteria.group = (opt || []).map(({ id }) => id);

  if (k === 'type' && opt?.length) {
    newCriteria.type = (opt || []).map(({ value }) => value);
  }

  if (k === 'station' && opt?.length) {
    newCriteria.station = (opt || []).map(({ value }) => value);
  }

  if (k === 'resolution' && opt?.length) {
    newCriteria.resolution = (opt || []).map(({ value }) => value);
  }

  if (val && val !== 'any') newCriteria[k] = val;

  const cleanFields = { ...newCriteria };
  // scrub sensitive PII with numeric string then pass to history which sets url
  if (cleanFields.userFullNames) {
    const storageKey = sqUser.timeSet(cleanFields.userFullNames);
    cleanFields.userFullNames = storageKey;
  }

  if (cleanFields.patientFullNames) {
    const storageKey = sqPatient.timeSet(cleanFields.patientFullNames);
    cleanFields.patientFullNames = storageKey;
  }

  return historyPush({
    pathname: `/incidents`,
    search: '?' + $.param(cleanFields, true),
  });
}
