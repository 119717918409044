import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { computed, decorate } from 'mobx'
import { observer } from 'mobx-react'
import { PermissionStore, Authenticated } from 'common'
import moment from 'moment'

import { Input, MultilineInput } from '../../../ui'
import NotificationStore from '../../../stores/NotificationStore'

import UserTagsStore from '../stores/UserTagsStore'
import UserTagStore from '../stores/UserTagStore'

import styles from './index.module.scss'

const unknown = <em>Unknown</em>

function checkName(name) {
  if (name && name !== '') {
    return name
  }
  return unknown
}

const UserTagEditForm = observer(
  class UserTagEditForm extends React.Component {
    static propTypes = {
      baseUrl: PropTypes.string,
      linkTo: PropTypes.string,
    }

    static defaultProps = {
      baseUrl: '',
      linkTo: '',
    }

    // Computed
    get canEdit() {
      return PermissionStore.has('PERSON_TAG_MODIFY')
    }

    onSubmit = e => {
      if (e) e.preventDefault()
      if (!UserTagStore.isDirty || !this.validateName(UserTagStore.name)) return

      // store the active tag values, in case of failure
      const oldName = UserTagsStore.activeTag.name
      const oldDescription = UserTagsStore.activeTag.description

      // Set the activeTag model item to the new values
      UserTagsStore.activeTag.name = UserTagStore.name
      UserTagsStore.activeTag.description = UserTagStore.description

      UserTagStore.update().then(
        () => {
          const content = (
            <span>
              <i className="material-icons icon-check_circle" />
              Successfully updated user tag
            </span>
          )
          NotificationStore.add({ level: 'success', content })
        },
        () => {
          UserTagsStore.activeTag.name = oldName
          UserTagsStore.activeTag.description = oldDescription
          const content = (
            <span>
              <i className="material-icons icon-warning" />
              There was a problem updating this user tag
            </span>
          )
          NotificationStore.add({ level: 'warning', content })
        }
      )
    }

    onNameChange = value => {
      UserTagStore.name = value
    }

    onDescriptionChange = value => {
      UserTagStore.description = value
    }

    validateName = name => /[0-9a-zA-Z]/.test(name)

    renderTagged() {
      const { baseUrl } = this.props

      if (UserTagsStore && UserTagsStore.activeTagId) {
        // destructuring the count value from UserTagsStore with a default so it doesn't break if activeTag isn't in UserTagsStore
        const { activeTag: { count } = {} } = UserTagsStore

        return (
          <Link
            to={`${baseUrl}/${UserTagsStore.activeTagId}/users`}
            data-cy="edit-user-tag-tagged"
          >
            {count || 0} EMR User{count === 1 ? '' : 's'}
          </Link>
        )
      }
    }

    render() {
      const { linkTo } = this.props

      return (
        <div>
          <form className={styles.form} onSubmit={this.onSubmit}>
            <Input
              bordered
              error={!this.validateName(UserTagStore.name)}
              label="Tag Name"
              margin="md"
              onChange={this.onNameChange}
              placeholder="Add a tag name"
              readOnly={!this.canEdit}
              value={UserTagStore.name}
              name="edit-user-tag-name"
            />
            <div className="full-width">
              <MultilineInput
                label="Description"
                placeholder="Add an optional description"
                onChange={this.onDescriptionChange}
                value={UserTagStore.description}
                readOnly={!this.canEdit}
                bordered
              />
            </div>
          </form>

          <section className="pane-grid">
            <div className="pane-grid__row bottom-collapse">
              <div className="item">
                <h4>Tagged</h4>
                {this.renderTagged()}
              </div>

              <div className="item">
                {/* eventually this will hold a data point for open cases, but that doesn't yet exist */}
              </div>
            </div>

            <div className="pane-grid__row">
              <div className="item">
                <h4>Created By</h4>
                <span>
                  {UserTagStore &&
                    UserTagStore.createdBy &&
                    checkName(UserTagStore.createdBy.firstName)}{' '}
                  {UserTagStore &&
                    UserTagStore.createdBy &&
                    checkName(UserTagStore.createdBy.lastName)}
                </span>
              </div>

              <div className="item">
                <h4>Date Added</h4>
                <span>
                  {moment(UserTagStore.created).format('MMMM D, YYYY')}
                </span>
              </div>
            </div>
          </section>

          <Authenticated permission="PERSON_TAG_MODIFY">
            <div className={styles.formActions}>
              <div
                onClick={this.onSubmit}
                className="button"
                disabled={!UserTagStore.isDirty}
                data-cy="edit-user-tag-button"
              >
                Update Tag
              </div>
              <Link to={linkTo} className="button button--link">
                Cancel
              </Link>
            </div>
          </Authenticated>
        </div>
      )
    }
  }
)

decorate(UserTagEditForm, {
  canEdit: computed,
})

UserTagEditForm.displayName = 'UserTagEditForm'

export default UserTagEditForm
