import { DateHelpers } from 'common';

const {
  TIME_PERIOD_VALUES: { WEEK_EXACT },
} = DateHelpers;

export const statuses = [
  { value: 'UNRESOLVED', label: 'Status', name: 'Unresolved' },
  { value: 'ACCURATE', label: 'Status', name: 'Accurate' },
  { value: 'INACCURATE', label: 'Status', name: 'Inaccurate' },
  {
    value: 'AI_UPDATED_AND_RESOLVED',
    label: 'Status',
    name: 'AI Updated and Resolved',
  },
  { value: 'PENDING', label: 'Status', name: 'Pending' },
].filter(Boolean);

export const alphabeticalOpts = [
  { value: 'asc', label: 'Order', name: 'A-Z' },
  { value: 'desc', label: 'Order', name: 'Z-A' },
];

export const chronologicalOpts = [
  { value: 'asc', label: 'Order', name: 'Oldest' },
  { value: 'desc', label: 'Order', name: 'Newest' },
];

export const groupByOptions = [
  { value: 'startTime', label: 'Group By', name: 'Time' },
  { value: 'user', label: 'Group By', name: 'User' },
  { value: 'patient', label: 'Group By', name: 'Patient' },
  { value: 'station', label: 'Group By', name: 'ADC' },
];

export const defaultOptions = {
  range: WEEK_EXACT,
  groupBy: 'startTime',
  sort: 'desc',
};
