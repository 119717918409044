import React from 'react';
import PropTypes from 'prop-types';
import EncounterType from './EncounterType';
import ViaSource from '../ViaSource';
import SideList from '../SideList';
import EncounterDateTime from './EncounterDateTime';
import EncounterLinks from './EncounterLinks';
import EncounterOther from './EncounterOther';

const Encounter = ({ encounter, showDuration }) => {
  return (
    <div>
      <EncounterType encounter={encounter} />
      <ViaSource source={encounter.source} />
      <SideList>
        <EncounterDateTime encounter={encounter} showDuration={showDuration} />
        <EncounterLinks encounter={encounter} />
        <EncounterOther encounter={encounter} />
      </SideList>
    </div>
  );
};

Encounter.propTypes = {
  encounter: PropTypes.shape({
    source: PropTypes.string,
  }).isRequired,
  showDuration: PropTypes.bool,
};

Encounter.defaultProps = {
  showDuration: false,
};

export default Encounter;
