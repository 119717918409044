/*eslint-disable react/no-find-dom-node*/
import $ from 'jquery'
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

class ScreenOnly extends React.Component {
  // Ensure that only boolean values (not undefined values) are passed in
  static propTypes = {
    children: PropTypes.node.isRequired,
    hideOnPrint: PropTypes.bool,
  }

  static defaultProps = {
    hideOnPrint: true,
  }

  componentDidMount() {
    const element = ReactDOM.findDOMNode(this)
    if (!element) return

    const $el = $(element)
    if (this.props.hideOnPrint) {
      $el.addClass('forScreen')
    }
  }

  render() {
    return this.props.children
  }
}

export default ScreenOnly
