import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import queryString from 'query-string';

import DateSelector from '../../ui/Filters/DateSelector';
import ExclusionDropdown from '../../ui/Filters/ExclusionDropdown';

const TimePeriodFilters = ({
  history,
  location,
  query,

  queryForToggle,
  queryForSelectionChange,

  createdRangeSelection,
  createdAfter,
  createdBefore,
  createdExcluded,

  lastModifiedRangeSelection,
  lastModifiedAfter,
  lastModifiedBefore,
  lastModifiedExcluded,

  violationDateRangeSelection,
  violationDateAfter,
  violationDateBefore,
  violationDateExcluded,

  resolutionDateRangeSelection,
  resolutionDateAfter,
  resolutionDateBefore,
  resolutionDateExcluded,

  timeToResolveOptions,
  timeToResolveSelection,
  timeToResolveExcluded,
}) => {
  const navigateOnChange = newQuery => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({ ...query, ...newQuery }),
    });
  };
  const buildDateSelector = function([
    label,
    fieldPrefix,
    selection,
    dateAfter,
    dateBefore,
    isExcluding,
  ]) {
    return (
      <DateSelector
        key={label}
        value={fieldPrefix}
        label={label}
        filters={{
          [`${fieldPrefix}Exclude`]: isExcluding,
          [`${fieldPrefix}Range`]: selection,
          [`${fieldPrefix}After`]: dateAfter,
          [`${fieldPrefix}Before`]: dateBefore,
        }}
        onChangeDropdown={value => {
          navigateOnChange(
            queryForSelectionChange(`${fieldPrefix}Range`, value)
          );
        }}
        onToggleExclusion={() => {
          navigateOnChange(
            queryForToggle(fieldPrefix, `${fieldPrefix}Range`)
          );
        }}
        onCalendarChange={(date, valid, beforeOrAfter, filterKeyRoot) => {
          if (valid) {
            navigateOnChange(
              queryForSelectionChange(`${filterKeyRoot}${beforeOrAfter}`, date)
            );
          }
        }}
        whiteDatePickerBackground
      />
    );
  };

  const dateFilterParticulars = [
    [
      'Case Created',
      'created',
      createdRangeSelection,
      createdAfter,
      createdBefore,
      createdExcluded,
    ],
    [
      'Case Last Modified',
      'lastModified',
      lastModifiedRangeSelection,
      lastModifiedAfter,
      lastModifiedBefore,
      lastModifiedExcluded,
    ],
    [
      'Case Event Date',
      'violationDate',
      violationDateRangeSelection,
      violationDateAfter,
      violationDateBefore,
      violationDateExcluded,
    ],
    [
      'Resolution Date',
      'resolutionDate',
      resolutionDateRangeSelection,
      resolutionDateAfter,
      resolutionDateBefore,
      resolutionDateExcluded,
    ],
  ];

  return (
    <section>
      <h4>
        <i className="material-icons icon-schedule" />
        Time Range
      </h4>
      <ul className="timePeriodFilters">
        {dateFilterParticulars.map(buildDateSelector)}

        {/*Time to resolution (single dropdown)*/}
        <ExclusionDropdown
          label="Time To Resolution"
          options={timeToResolveOptions}
          value={timeToResolveOptions.find(
            ({ value }) => value === timeToResolveSelection
          )}
          isExcluding={timeToResolveExcluded}
          onChange={value => {
            navigateOnChange(queryForSelectionChange('timeToResolve', value));
          }}
          onToggleExclusion={() => {
            navigateOnChange(queryForToggle('timeToResolve'));
          }}
          textField="label"
          valueField="value"
        />
      </ul>
    </section>
  );
};

TimePeriodFilters.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  query: PropTypes.object.isRequired,

  queryForToggle: PropTypes.func.isRequired,
  queryForSelectionChange: PropTypes.func.isRequired,

  createdRangeSelection: PropTypes.string,
  createdAfter: PropTypes.string,
  createdBefore: PropTypes.string,
  createdExcluded: PropTypes.bool,

  lastModifiedRangeSelection: PropTypes.string,
  lastModifiedAfter: PropTypes.string,
  lastModifiedBefore: PropTypes.string,
  lastModifiedExcluded: PropTypes.bool,

  violationDateRangeSelection: PropTypes.string,
  violationDateAfter: PropTypes.string,
  violationDateBefore: PropTypes.string,
  violationDateExcluded: PropTypes.bool,

  resolutionDateRangeSelection: PropTypes.string,
  resolutionDateAfter: PropTypes.string,
  resolutionDateBefore: PropTypes.string,
  resolutionDateExcluded: PropTypes.bool,

  timeToResolveOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  timeToResolveSelection: PropTypes.string,
  timeToResolveExcluded: PropTypes.bool,
};

TimePeriodFilters.displayName = 'TimePeriodFilters';

export default TimePeriodFilters;
