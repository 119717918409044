import React from 'react'
import PropTypes from 'prop-types'
import { Authenticated } from 'common'

import { Button, Input, MultilineInput } from '../../ui'

function validateName(text) {
  const regex = /[0-9a-zA-Z]/
  return regex.test(text)
}

const BundleDetailForm = ({
  isDirty,
  shouldDisable,
  onSave,
  setValue,
  nameValue,
  notesValue,
}) => {
  const onSubmit = e => {
    e.preventDefault()
    onSave()
  }

  return (
    <section>
      <form className="form padd-all" onSubmit={onSubmit}>
        <ul>
          <Input
            bordered
            disabled={shouldDisable}
            error={!validateName(nameValue)}
            label="Bundle Name"
            margin="md"
            onChange={val => setValue('draftName', val)}
            value={nameValue || ''}
            name="bundle-name--input"
          />
          <div className="input-section bundle-notes">
            <MultilineInput
              label="Bundle Notes"
              readOnly={shouldDisable}
              onChange={val => setValue('draftNotes', val)}
              value={notesValue}
              bordered
            />
          </div>
          <Authenticated permission="BUNDLE_MODIFY">
            <li>
              <Button
                type="submit"
                className="button"
                disabled={shouldDisable || !validateName(nameValue) || !isDirty}
                value="Update Bundle"
                data-cy="update-bundle--button"
              />
            </li>
          </Authenticated>
        </ul>
      </form>
    </section>
  )
}

BundleDetailForm.propTypes = {
  isDirty: PropTypes.bool,
  nameValue: PropTypes.string,
  notesValue: PropTypes.string,
  onSave: PropTypes.func,
  setValue: PropTypes.func,
  shouldDisable: PropTypes.bool,
}

export default BundleDetailForm
