import { useEffect } from 'react'
import CaseResultsStore from '../stores/CaseResultsStore'
import { RouterContainer } from 'common'

/**
 * Instruct the store to fetch only while this component is rendered.
 * This avoids fetching in the background and displaying unexpected values
 * on initial render.
 */
export function useSetAllowFetch() {
  useEffect(() => {
    CaseResultsStore.setAllowFetch(true)

    return () => {
      CaseResultsStore.setAllowFetch(false)
      CaseResultsStore.reset()
    }
  }, [])
}

/**
 * Responsible for setting case search query params in the store
 * based on url params
 *
 * @param query
 * @param shouldShowMsg
 */
export function useSetFiltersFromQuery({ query, shouldShowMsg }) {
  const filterMsg = localStorage.getItem('filterMsg')
  useEffect(() => {
    const newQuery = { ...query }
    if (
      shouldShowMsg &&
      query.showFilters === undefined &&
      !query.user &&
      !query.patient &&
      !filterMsg
    ) {
      RouterContainer.go('/cases?showFilters=true', newQuery)
    }

    CaseResultsStore.setFilters(newQuery)
  }, [query, shouldShowMsg, filterMsg])
}
