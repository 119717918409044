import React from 'react'
import { observer } from 'mobx-react'
import { autorun, decorate, observable, computed } from 'mobx'
import { DropdownList } from 'react-widgets'
import moment from 'moment'
import classnames from 'classnames'
import { HalUtils, RouterContainer, mapKeysToArray } from 'common'
import CaseBundleStore from '../../../stores/CaseBundleStore'
import BulkCaseActionBase from '../BulkCaseActionBase'
import BulkCaseActionHelpText from '../BulkCaseActionHelpText'
import { Input } from '../..'

function defaultBundleName() {
  const today = moment().format('l')
  return `New Bundle ${today}`
}

export function getDropdownOptions(bundles = [], bundlesWithCase) {
  let options = []

  bundles.forEach(bundle => {
    options.push({
      id: HalUtils.getId(bundle),
      name: bundle.name,
    })
  })

  if (bundlesWithCase)
    options = options.filter(
      opt => !bundlesWithCase.map(b => b.name).includes(opt.name)
    )

  options.sort((a, b) => {
    if (a.name.toUpperCase() < b.name.toUpperCase()) return -1
    if (a.name.toUpperCase() > b.name.toUpperCase()) return 1
    return 0
  })

  options.unshift({
    id: 'newBundle',
    name: 'Create New Bundle',
  })

  return options
}

const BundleCases = observer(
  class extends BulkCaseActionBase {
    constructor() {
      super()

      this.newBundleName = defaultBundleName()
      this.bundles = CaseBundleStore.allBundles

      autorun(() => {
        const bundles = CaseBundleStore.allBundles
        this.bundles = bundles
      })
    }

    // Observable
    newBundleName
    selected
    bundles

    reset() {
      this.newBundleName = null
      this.selected = null
    }

    // Computed
    get options() {
      return getDropdownOptions(this.bundles, this.props.bundlesWithCase)
    }

    // Computed
    get action() {
      return this.selected === 'newBundle' ? 'createBundle' : 'updateBundle'
    }

    // Computed
    get selectedOption() {
      let selected

      if (this.selected) {
        selected = this.options.find(option => option.id === this.selected)
      }

      return selected
    }

    validator = text => /[0-9a-zA-Z]/.test(text)

    /**
     * Handle the change of the bundle name (when creating a new bundle).
     * @param {String}     val   - the new bundle name
     * @return {undefined}
     */
    onBundleNameChange = val => {
      this.dirty = true
      this.newBundleName = val
    }

    /**
     * Handle the bundle value change. This is the onChange callback handler
     * for the dropdown menu.
     * @param {String} val    - the new value being set
     * @param {Boolean} dirty - whether or not the form is dirty
     * @return {undefined}
     */
    onChange = val => {
      this.selected = val.id

      // reset the new bundle name to the default when changing
      // away from the create new bundle option
      if (this.selected !== 'newBundle') {
        this.newBundleName = defaultBundleName()
      }
      super.onChange(val, true)
    }

    onSubmit = e => {
      e.preventDefault()

      const { store } = this.props
      const action =
        this.selected === 'newBundle' ? 'createBundle' : 'updateBundle'
      const cases = mapKeysToArray(store.selected, c => {
        if (typeof c === 'string') {
          return c
        }
      })
      const opts = {}

      if (this.selected === 'newBundle') {
        opts.name = this.newBundleName
      } else {
        opts.id = this.selected
      }

      opts.cases = cases

      store.performBulkAction(action, opts).then(r => {
        if (this.props.singleCase) {
          this.reset()
          if (this.props.bundleRefresh) this.props.bundleRefresh()
        } else {
          const bundleId = HalUtils.getId(r) || opts.id
          if (bundleId) RouterContainer.go(`/bundles/${bundleId}`, {})
        }
      })
    }

    renderBundleDetails() {
      let node

      if (this.selected === 'newBundle') {
        node = (
          <Input
            name="new-bundle-name--input"
            bordered
            error={!this.validator(this.newBundleName)}
            label="New Bundle Name"
            margin="md"
            onChange={this.onBundleNameChange}
            value={this.newBundleName || ''}
          />
        )
      }

      return node
    }

    renderButtonLabel() {
      return this.selected === 'newBundle' ? 'Create Bundle' : 'Add to Bundle'
    }

    render() {
      const { store, singleCase } = this.props
      const { performingBulkAction } = store

      return (
        <form
          className="form"
          onSubmit={this.onSubmit}
          data-cy="bulk-actions--select-bundle--dropdown"
        >
          <ul>
            <li>
              <DropdownList
                data={this.options}
                value={this.selectedOption}
                valueField="id"
                onChange={this.onChange}
                disabled={performingBulkAction}
                textField="name"
                placeholder="Select Bundle"
              />
            </li>
            {this.renderBundleDetails()}
            <li>
              <button
                type="submit"
                data-cy="submit-bundle-action--button"
                className={classnames('button--block', {
                  hidden: !this.dirty,
                })}
                disabled={
                  !this.newBundleName ||
                  !this.validator(this.newBundleName) ||
                  performingBulkAction ||
                  store.selected.size < 1
                }
              >
                {this.renderButtonLabel()}
              </button>
              <BulkCaseActionHelpText
                store={store}
                hidden={!this.dirty}
                helpText={
                  singleCase
                    ? ' '
                    : 'This will bundle the selected cases together.'
                }
              />
            </li>
          </ul>
        </form>
      )
    }
  }
)

decorate(BundleCases, {
  newBundleName: observable,
  selected: observable,
  bundles: observable,
  options: computed,
  action: computed,
  selectedOption: computed,
})

export default BundleCases
