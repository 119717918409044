import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import SettingsList from '../SettingsList';
import SettingsChart from '../SettingsChart';
import { DocumentTitle, Tooltip } from '../../../ui';

function getlocalBool() {
  const localchart = localStorage.getItem('showChart');
  if (localchart === null || localchart === undefined) return true;
  return !!JSON.parse(String(localchart).toLowerCase());
}

const SettingsView = ({
  query,
  setFilters,
  toggleAllCaseCreation,
  downloadCSV,
  toggleGroupCaseCreation,
  isGroupEnabled,
  creatingCases,
  groups,
  hasPermission,
  automatedCategories,
}) => {
  const [showChart, setShowChart] = useState(getlocalBool());

  useEffect(() => {
    if (query) setFilters(query);
  }, [query, setFilters]);

  const downloadSettingsCSV = e => {
    e.preventDefault();
    downloadCSV();
  };

  const toggleGroup = groupToToggle => {
    const enabled = !isGroupEnabled(groupToToggle.name);
    toggleGroupCaseCreation(enabled, groupToToggle);
  };

  const toggleChart = e => {
    e.preventDefault();
    localStorage.setItem('showChart', !showChart);
    setShowChart(!showChart);
  };

  return (
    <div className="settings__pager">
      <DocumentTitle text="Settings" />
      <section className="view-content">
        <div className="view-content__body">
          <div className="settings__header">
            <div className="print-only">
              <img alt="logo" src="assets/img/logo.png" />
              <p className="float-right">
                Report Generated
                <strong>{moment().format('LL')}</strong>
              </p>
            </div>
            <div>
              <h1>Case Creation Settings</h1>
            </div>
            <div className="settings__header-icons">
              {groups.length <= 1 && (
                <Tooltip
                  content={
                    creatingCases
                      ? 'Pause Case Creation'
                      : 'Resume Case Creation'
                  }
                  placement="left"
                >
                  {groups.length === 1 ? (
                    <span
                      className="icon prot-a"
                      onClick={() => toggleGroup(groups[0])}
                    >
                      <i
                        className={classnames('material-icons', {
                          hidden: !hasPermission,
                          'icon-play_arrow': !isGroupEnabled(groups[0].name),
                          'icon-pause': isGroupEnabled(groups[0].name),
                        })}
                      />
                    </span>
                  ) : (
                    <span
                      className="icon prot-a"
                      onClick={() => toggleAllCaseCreation()}
                    >
                      <i
                        className={classnames('material-icons', {
                          hidden: !hasPermission,
                          'icon-play_arrow': !creatingCases,
                          'icon-pause': creatingCases,
                        })}
                      />
                    </span>
                  )}
                </Tooltip>
              )}
              <Tooltip
                content={showChart ? 'Hide Chart' : 'Show Chart'}
                placement="left"
              >
                <span
                  className="icon prot-a span-insert_chart"
                  onClick={toggleChart}
                >
                  <i className="material-icons icon-insert_chart" />
                </span>
              </Tooltip>
              <Tooltip
                content="Export Case Settings History CSV"
                placement="left"
              >
                <span className="icon prot-a"
                  onClick={downloadSettingsCSV}
                  data-cy='settings-case-settings-history-csv'
                >
                  <i className="material-icons icon-get_app " />
                </span>
              </Tooltip>
            </div>
          </div>
          {showChart && <SettingsChart />}
          {automatedCategories.length > 0 && (
            <SettingsList toggleGroup={toggleGroup} />
          )}
        </div>
      </section>
    </div>
  );
};

SettingsView.propTypes = {
  automatedCategories: PropTypes.array.isRequired,
  hasPermission: PropTypes.bool,
  creatingCases: PropTypes.bool,
  isGroupEnabled: PropTypes.func,
  groups: PropTypes.array.isRequired,
  query: PropTypes.shape({}),
  downloadCSV: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  toggleAllCaseCreation: PropTypes.func.isRequired,
  toggleGroupCaseCreation: PropTypes.func.isRequired,
};

SettingsView.defaultProps = {
  groups: [],
};

export default SettingsView;
