import React from 'react'
import PropTypes from 'prop-types'
import { DoubleLineCell } from '../../../../ui'
import classnames from 'classnames'
import styles from '../index.module.scss'

const EmailCell = ({ email, isActive, 'data-cy': dataCy }) => {
  return (
    <DoubleLineCell
      className={classnames({ [styles.isActive]: isActive })}
      lineOne={email}
      data-cy={dataCy}
    />
  )
}

export default EmailCell

EmailCell.propTypes = {
  'data-cy': PropTypes.string,
  email: PropTypes.string,
  isActive: PropTypes.bool,
}
