import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Label = ({
  className,
  value,
  withIcon,
  icon,
  badge,
  'data-cy': dataCy,
}) => (
  <span
    data-cy={dataCy}
    className={classnames('label', className, {
      'label-icon': withIcon,
      badge: badge,
    })}
  >
    {value}
    {withIcon && <i className={classnames('material-icons', icon)}></i>}
  </span>
)

Label.propTypes = {
  'data-cy': PropTypes.string,
  className: PropTypes.string,
  badge: PropTypes.bool,
  value: PropTypes.string,
  withIcon: PropTypes.bool,
  icon: PropTypes.string,
}

Label.defaultProps = {
  badge: false,
  className: '',
  value: '',
  withIcon: false,
  icon: '',
}

export default Label
