import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './index.module.scss'

import DateInput from '../DateInput'

const DatePicker = props => {
  const { className, label, ...other } = props

  let klasses = (className || '').split(' ')
  klasses = klasses.concat(['input-container', 'input-group-date-picker'])
  klasses = classnames(
    [styles.container],
    klasses,
    'input-container',
    'input-group-date-picker'
  )

  return (
    <div className={klasses}>
      <DateInput
        type="text"
        className={className}
        data-cy="date-picker--text-input"
        {...other}
      />
      {label && (
        <label className={classnames({ disabled: props.disabled })}>
          {label}
        </label>
      )}
    </div>
  )
}

DatePicker.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
}

DatePicker.defaultProps = {
  className: '',
  disabled: false,
  label: '',
}

export default DatePicker
