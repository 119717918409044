import React, { useState } from 'react'
import ListItem from '../SideList/ListItem'
import PropTypes from 'prop-types'
import intersperse from '../../utils/intersperse'

const RenderUsers = ({ users, label }) => {
  const [viewAll, toggle] = useState(false)
  const toggleFunc = () => {
    toggle(!viewAll)
  }
  if (users && users.length > 0) {
    const nameCount = users.length - 1
    users = intersperse(users, ', ')
    if (viewAll)
      return (
        <ListItem
          label={label}
          value={
            <p>
              {users}{' '}
              <span onClick={toggleFunc} className="forScreen prot-a">
                {' '}
                - Hide
              </span>
            </p>
          }
        />
      )
    else if (users.length === 1)
      return <ListItem label={label} value={<p>{users[0]}</p>} />
    return (
      <ListItem
        label={label}
        value={
          <p>
            {users[0]}
            <span className="forScreen"> and</span>{' '}
            <span className="prot-a forScreen" onClick={toggleFunc}>
              {nameCount} Other{nameCount === 1 ? '' : 's'}
            </span>
          </p>
        }
      />
    )
  }
  return null
}

RenderUsers.propTypes = {
  users: PropTypes.array,
  label: PropTypes.string,
}

export default RenderUsers
