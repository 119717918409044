import React from 'react'
import PropTypes from 'prop-types'
import { DropdownList } from 'react-widgets'
import {
  RADAR_EXPLANATION,
  TIMEZONES,
} from '../../../stores/CaseIntegrationStore/const'
import { MultilineInput } from '../../../../ui'
import Checkbox from '../../../../ui/Checkbox'
import styles from './index.module.scss'
import RadarIntegrationTable from '../RadarIntegrationTable'

export const RadarCaseIntegration = ({
  isEnabled,
  setEnabled,
  tokenDisplay,
  setToken,
  timezone,
  setTimezone,
  caseSyncSettings,
  setCaseSyncSettings,
}) => {
  return (
    <form className="content other">
      <p>{RADAR_EXPLANATION}</p>
      <div className={styles.bottomSpacing} data-cy="integration-radar-enable">
        <Checkbox
          checked={isEnabled}
          tabIndex={0}
          color={'secondary'}
          onChange={() => setEnabled(!isEnabled)}
        >
          Enable Integration
        </Checkbox>
      </div>
      <div data-cy="integration-radar-token">
        <MultilineInput
          label="Radar Integration Token"
          placeholder="Enter token value here"
          value={tokenDisplay}
          onChange={setToken}
          bordered
        />
      </div>
      <div className={styles.dropdown}>
        <p>Select a time zone for incidents created in Radar.</p>
        <DropdownList
          data={TIMEZONES}
          onChange={value => setTimezone(value.value)}
          value={timezone}
          valueField="value"
          textField="key"
          valueComponent={({ item }) => (
            <span>
              <strong>Timezone:</strong> {item?.key}
            </span>
          )}
          data-cy="integration-radar-timezone"
        />
      </div>
      <RadarIntegrationTable
        caseSyncSettings={caseSyncSettings}
        setCaseSyncSettings={setCaseSyncSettings}
      />
    </form>
  )
}

RadarCaseIntegration.propTypes = {
  isEnabled: PropTypes.bool,
  tokenDisplay: PropTypes.string,
  caseSyncSettings: PropTypes.shape({
    discovered: PropTypes.bool,
    firstInformedAt: PropTypes.bool,
    occurredOn: PropTypes.bool,
    description: PropTypes.bool,
    individualsImpacted: PropTypes.bool,
    department: PropTypes.bool,
    employeeInvolved: PropTypes.bool,
    startDate: PropTypes.bool,
    remediation: PropTypes.bool,
    endDate: PropTypes.bool,
    caseNotes: PropTypes.bool,
    caseActions: PropTypes.bool,
    caseResolution: PropTypes.bool,
  }),
  setCaseSyncSettings: PropTypes.func,
  setEnabled: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  setTimezone: PropTypes.func.isRequired,
}
