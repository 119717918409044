import React from 'react'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import styles from './index.module.scss'

const Tab = ({ count, disabled, hidden, label, route, exact, showNewIcon }) => {
  if (hidden) return null

  return (
    <NavLink
      to={route}
      className={classnames(styles.item, {
        [styles.disabled]: disabled,
      })}
      activeClassName={styles.active}
      exact={exact}
    >
      <span>{label}</span>
      {count ? (
        <span className={styles.count} data-cy="tab-count">
          {count}
        </span>
      ) : null}
      {showNewIcon && (
        <i className={`material-icons icon-fiber_new ${styles.newIcon}`} />
      )}
    </NavLink>
  )
}

Tab.propTypes = {
  caseType: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  disabled: PropTypes.bool,
  exact: PropTypes.bool,
  hidden: PropTypes.bool,
  label: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  showNewIcon: PropTypes.bool,
}

Tab.defaultProps = {
  count: 0,
  disabled: false,
  exact: false,
  hidden: false,
}

Tab.displayName = 'Tab'

export default Tab
