import { PERSON_TYPE_PATIENT, PERSON_TYPE_USER } from '../stores/const'
import {
  USER_RECORD_METADATA_TITLE,
  MEDICAL_RECORD_METADATA_TITLE,
  VIP_REGISTRATION_METADATA_TITLE,
  USER_TAG_METADATA_TITLE,
  EXISTING_CASE_METADATA_TITLE,
} from './const'
import { formatVipType } from './helpers'
import queryString from 'query-string'

export const generateMRVPersonLink = (personType, personId, linkId) => {
  let personLink
  let personSwitchLink

  if (personType === PERSON_TYPE_USER) {
    personLink = `/activity/employee/${personId}`
    if (linkId) {
      personSwitchLink = `/activity/patient/${linkId}`
    }
  } else if (personType === PERSON_TYPE_PATIENT) {
    personLink = `/activity/patient/${personId}`
    if (linkId) {
      if (linkId) personSwitchLink = `/activity/employee/${linkId}`
    }
  }
  return {
    personLink: personLink || '',
    personSwitchLink: personSwitchLink || '',
  }
}

export const generateMetadataLink = (type, metadata, personType) => {
  let url
  const params = {}
  const metadataLink = {}
  const personLinks = generateMRVPersonLink(
    personType,
    metadata?.personId,
    metadata?.linkId
  )

  switch (type) {
    case USER_TAG_METADATA_TITLE:
      url =
        metadata?.personTags?.length > 0
          ? `/user-tags/assign/employee/${metadata?.personId}`
          : '/user-tags'
      break
    case MEDICAL_RECORD_METADATA_TITLE:
      url =
        personType === PERSON_TYPE_USER
          ? personLinks.personSwitchLink
          : personLinks.personLink
      break
    case USER_RECORD_METADATA_TITLE:
      url =
        personType === PERSON_TYPE_PATIENT
          ? personLinks.personSwitchLink
          : personLinks.personLink
      break
    case VIP_REGISTRATION_METADATA_TITLE:
      if (metadata?.length > 0)
        params.types = metadata
          ?.map(({ types }) => types?.map(type => formatVipType(type)))
          ?.flat()
      url = metadata?.length > 0 ? `/vips` : ''
      break
    case EXISTING_CASE_METADATA_TITLE:
      if (metadata?.data?.length > 0)
        params[personType.toLowerCase()] = metadata?.personId
      url = metadata?.data?.length > 0 ? `/cases` : ''
      break
    default:
      url = ''
      break
  }
  if (params) metadataLink.search = queryString.stringify(params)
  metadataLink.pathname = url
  return metadataLink
}

export const buildLink = (url, params) => {
  return {
    pathname: url,
    search: params ? queryString.stringify(params) : null,
  }
}
