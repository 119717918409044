import React from 'react'
import { computed, observable, decorate, reaction, action } from 'mobx'
import { observer } from 'mobx-react'
import { AutomatedEmailStore, validateEmail } from 'common'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import NotificationStore from '../../../stores/NotificationStore'
import { Input } from '../../../ui'

const AutomatedEmail = observer(
  class AutomatedEmail extends React.Component {
    static propTypes = {
      onDirtyChange: PropTypes.func.isRequired,
    }

    constructor() {
      super()
      reaction(
        () => [this.dirty],
        ([dirty]) => {
          this.props.onDirtyChange(dirty)
        }
      )
    }

    // observables
    address
    alias
    cc
    replyTo

    // computed
    get dirty() {
      return (
        (this.address !== undefined &&
          this.address !== AutomatedEmailStore.address) ||
        (this.alias !== undefined &&
          this.alias !== AutomatedEmailStore.alias) ||
        (this.cc !== undefined && this.cc !== AutomatedEmailStore.cc) ||
        (this.replyTo !== undefined &&
          this.replyTo !== AutomatedEmailStore.replyTo)
      )
    }

    onFromAddressChange = val => (this.address = val)
    onFromAliasChange = val => (this.alias = val)
    onCCAddressChange = val => (this.cc = val)
    onReplyToAddressChange = val => (this.replyTo = val)

    handleSubmit(e) {
      e.preventDefault()
      if (!this.dirty) return
      const address =
        this.address === undefined ? AutomatedEmailStore.address : this.address
      const alias =
        this.alias === undefined ? AutomatedEmailStore.alias : this.alias
      const cc = this.cc === undefined ? AutomatedEmailStore.cc : this.cc
      const replyTo =
        this.replyTo === undefined ? AutomatedEmailStore.replyTo : this.replyTo
      // don't submit if validation fails
      if (
        [address, cc, replyTo]
          .map(email => validateEmail(email))
          .includes(false)
      )
        return

      AutomatedEmailStore.update({
        value: address,
        properties: { alias, cc, replyTo },
      })
        .then(() => {
          const content = (
            <span>
              <i className="material-icons icon-check_circle" />
              Email addresses updated successfully
            </span>
          )
          NotificationStore.add({ level: 'success', content })
        })
        .catch(() => {
          const content = (
            <span>
              <i className="material-icons icon-warning" />
              An error occurred during the update. Please check connectivity or
              contact support.
            </span>
          )
          NotificationStore.add({ level: 'warning', content })
        })
    }

    render() {
      let address = AutomatedEmailStore.address || ''
      if (this.address !== undefined) address = this.address

      let alias = AutomatedEmailStore.alias || ''
      if (this.alias !== undefined) alias = this.alias

      let cc = AutomatedEmailStore.cc || ''
      if (this.cc !== undefined) cc = this.cc

      let replyTo = AutomatedEmailStore.replyTo || ''
      if (this.replyTo !== undefined) replyTo = this.replyTo

      const modified = AutomatedEmailStore.lastModified

      return (
        <div id="settings__wrapper-Auto_Email" className="wrapper">
          <div className="card_header">
            <h1>Automated Email</h1>
            <div>
              <span
                className={classnames('prot-a', { muted: !this.dirty })}
                onClick={this.handleSubmit.bind(this)}
                role="button"
                data-cy="automated-email--save-changes--btn"
              >
                Save Changes
              </span>
            </div>
          </div>
          <div className="content other">
            <form>
              <Input
                bordered
                error={!validateEmail(address)}
                label="From Address"
                margin="md"
                onChange={this.onFromAddressChange}
                value={address}
                name="automated-email--from-address--input"
              />
              <Input
                bordered
                label="From Alias"
                margin="md"
                onChange={this.onFromAliasChange}
                value={alias}
              />
              <Input
                bordered
                error={!validateEmail(cc)}
                label="CC Address"
                margin="md"
                validator={validateEmail}
                onChange={this.onCCAddressChange}
                value={cc}
              />
              <Input
                bordered
                error={!validateEmail(replyTo)}
                label="Reply-To Address"
                margin="md"
                validator={validateEmail}
                onChange={this.onReplyToAddressChange}
                value={replyTo}
                name="automated-email--reply-to-address--input"
              />
            </form>
            <h5>
              Last Modified:{' '}
              {modified
                ? moment(modified).format('MM/DD/YYYY hh:mm:ss A')
                : 'Never'}
            </h5>
          </div>
        </div>
      )
    }
  }
)

decorate(AutomatedEmail, {
  address: observable,
  alias: observable,
  cc: observable,
  replyTo: observable,
  dirty: computed,
  onFromAddressChange: action,
  onFromAliasChange: action,
  onCCAddressChange: action,
  onReplyToAddressChange: action,
})

AutomatedEmail.displayName = 'AutomatedEmail'

export default AutomatedEmail
