import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import TopNavBar from '../../../navbar/TopNavBar'
import NavBarItem from '../../../navbar/NavBarItem'
import styles from './index.module.scss'
import ProgressButton from '../../../ui/ProgressButton'

const BulkVipNav = props => {
  const {
    status,
    count,
    navTitle,
    selected,
    templateData,
    existingPatients,
    unmatchedPatients,
    complete,
    percentComplete,
    registering,
    registerVIPs,
    someFailed,
    matched,
    reset,
  } = props

  const buttonText = `Register ${selected} Patient${selected === 1 ? '' : 's'}`
  return (
    <React.Fragment>
      <TopNavBar filterBar>
        <NavBarItem
          active={status === 'all'}
          route="/vips/upload-confirm?status=all"
        >
          Show All <strong>{templateData}</strong>
        </NavBarItem>
        <NavBarItem
          active={status === 'matched'}
          route="/vips/upload-confirm?status=matched"
        >
          Matched <strong>{matched}</strong>
        </NavBarItem>
        <NavBarItem
          active={status === 'unmatched'}
          route="/vips/upload-confirm?status=unmatched"
        >
          Unmatched <strong>{unmatchedPatients}</strong>
        </NavBarItem>
        <NavBarItem
          active={status === 'existing'}
          route="/vips/upload-confirm?status=existing"
        >
          Existing <strong>{existingPatients}</strong>
        </NavBarItem>
      </TopNavBar>
      <TopNavBar actionBar>
        <div className={styles.actionNav}>
          <div
            className={styles.title}
            data-cy="vip-bulk-registration--matching-rows"
          >
            <strong>{count}</strong> {navTitle}
          </div>
          <div>
            <Link to="/vips" className={styles.buttonLink}>
              <button
                type="button"
                className={classnames(styles.cancelButton, {
                  hidden: registering || complete,
                })}
              >
                Cancel
              </button>
            </Link>
            <Link to="/vips/upload" className={styles.buttonLink}>
              <button
                type="button"
                className={classnames(styles.registerButton, {
                  hidden: !someFailed && !complete,
                })}
                onClick={() => reset()}
              >
                Re-Upload
              </button>
            </Link>
            <button
              type="button"
              className={classnames(styles.registerButton, {
                hidden: selected === 0 || registering || complete,
              })}
              onClick={() => registerVIPs()}
            >
              {buttonText}
            </button>
            <ProgressButton
              className={classnames(styles.registeringButton, {
                hidden: !registering || complete,
              })}
              loading={registering}
              disabled={selected.size < 1}
              percentage={percentComplete}
            >
              Registering...
            </ProgressButton>
          </div>
        </div>
      </TopNavBar>
    </React.Fragment>
  )
}

BulkVipNav.propTypes = {
  status: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  matched: PropTypes.number.isRequired,
  navTitle: PropTypes.string.isRequired,
  selected: PropTypes.number.isRequired,
  templateData: PropTypes.number.isRequired,
  existingPatients: PropTypes.number.isRequired,
  unmatchedPatients: PropTypes.number.isRequired,
  registerVIPs: PropTypes.func.isRequired,
  complete: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  someFailed: PropTypes.bool.isRequired,
  registering: PropTypes.bool.isRequired,
  percentComplete: PropTypes.number.isRequired,
}

export default BulkVipNav
