import React from 'react'
import PropTypes from 'prop-types'
import { computed, decorate } from 'mobx'
import { observer } from 'mobx-react'
import { Cell } from 'fixed-data-table-2'
import classnames from 'classnames'
import ToggleCell from '../ToggleCell'
import Checkbox from '../Checkbox'
import styles from './index.module.scss'

/**
 * Component to toggle the selected property of all objects in a collection.
 * NOTE: This requires that each element in the collection have a "selected"
 * property on it.
 */
const ToggleAllCell = observer(
  class extends ToggleCell {
    static propTypes = {
      store: PropTypes.shape({
        toggleSelectAll: PropTypes.func.isRequired,
        allSelected: PropTypes.bool.isRequired,
        someSelected: PropTypes.bool.isRequired,
      }),
      selected: PropTypes.bool,
    }

    // Computed
    get allSelected() {
      const { store } = this.props
      return store.allSelected
    }

    // Computed
    get someSelected() {
      const { store } = this.props
      return store.someSelected
    }

    toggleChecked = e => {
      e.preventDefault()
      e.stopPropagation()
      this.props.store.toggleSelectAll()
    }

    render() {
      return (
        <Cell
          className={classnames(styles.pointer, {
            selected: this.allSelected,
          })}
          onClick={this.toggleChecked}
          data-cy="select-all-checkbox"
        >
          <Checkbox
            checked={this.allSelected}
            indeterminate={this.someSelected}
            onChange={this.toggleChecked}
          />
        </Cell>
      )
    }
  }
)

decorate(ToggleAllCell, {
  allSelected: computed,
  someSelected: computed,
})

export default ToggleAllCell
