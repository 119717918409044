import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { Tooltip } from '../../../../ui';
import classnames from 'classnames';
import {
  USER_ICON,
  PATIENT_ICON,
  PATIENT_SWITCH_MSG,
  USER_SWITCH_MSG,
  SWAP_ICON,
} from '../const';
import { PERSON_TYPE_PATIENT, PERSON_TYPE_USER } from '../../../../mrv/stores/const';

const PersonSwitchButton = ({ mode, link }) => {
  if (!link) return null;

  const tooltipContent = mode === PERSON_TYPE_USER ? USER_SWITCH_MSG : PATIENT_SWITCH_MSG;
  const icon = mode === PERSON_TYPE_USER ? PATIENT_ICON : USER_ICON;

  return (
    <div className={styles.container}>
      <Link to={link}>
        <Tooltip content={tooltipContent} placement={'top'}>
          <span className={styles.icon}>
            <i
              className={classnames('material-icons', SWAP_ICON, styles.swap)}
            />
            <i
              className={classnames('material-icons', icon, styles.person, {
                [styles.user]: mode === PERSON_TYPE_USER,
                [styles.patient]: mode === PERSON_TYPE_PATIENT,
              })}
            />
          </span>
        </Tooltip>
      </Link>
    </div>
  );
};

PersonSwitchButton.propTypes = {
  mode: PropTypes.oneOf([PERSON_TYPE_USER, PERSON_TYPE_PATIENT]).isRequired,
  link: PropTypes.string,
};

PersonSwitchButton.defaultProps = {
  link: null,
};

export default PersonSwitchButton;
