import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Table, Column } from 'fixed-data-table-2'
import moment from 'moment'
import SortableHeaderCell from '../../ui/registry/RegistrySortableHeaderCell'
import {
  DateCell,
  DetailsCell,
  SourceCell,
} from '../../activity/eventTableCells'
import { userOnlyRow } from './index.module.scss'

const CaseEventTable = ({ eventData, sortData, linkForEvent }) => {
  const [width, setWidth] = useState(1)
  const [height, setHeight] = useState(1)
  const caseEventTable = useRef(null)

  // resize the table as needed
  useEffect(() => {
    // force a setsize to start out
    setSize()
    window.addEventListener('resize', setSize)

    return () => {
      window.removeEventListener('resize', setSize)
    }
    // by putting an empty array in the values to watch it ensures that the effect is only run once
  }, [])

  const setSize = () => {
    const node = caseEventTable.current
    setWidth(node.offsetParent.offsetWidth)
    setHeight(node.offsetParent.offsetHeight)
  }

  return (
    <div className="accesses__table" ref={caseEventTable}>
      <Table
        rowHeight={50}
        headerHeight={50}
        rowsCount={eventData.length}
        width={width}
        height={height}
        rowClassNameGetter={rowIndex =>
          eventData[rowIndex] && !eventData[rowIndex].patient && userOnlyRow
        }
      >
        <Column
          header={
            <SortableHeaderCell
              label="Date"
              propName="eventDate"
              store={sortData}
              baseUrl=""
            />
          }
          width={200}
          cellClassName="first-col"
          cell={({ rowIndex }) => <DateCell data={eventData[rowIndex]} />}
        />
        <Column
          header={
            <SortableHeaderCell
              label="Activity"
              propName="eventDescription"
              store={sortData}
              baseUrl=""
            />
          }
          width={200}
          cellClassName="no-wrap"
          cell={({ rowIndex }) => (
            <DetailsCell
              data={eventData[rowIndex]}
              link={linkForEvent(eventData[rowIndex])}
            />
          )}
          flexGrow={1}
        />
        <Column
          header={
            <SortableHeaderCell
              label="Source"
              propName="source"
              store={sortData}
              baseUrl=""
            />
          }
          width={120}
          cell={({ rowIndex }) => <SourceCell data={eventData[rowIndex]} />}
        />
      </Table>
    </div>
  )
}

CaseEventTable.propTypes = {
  eventData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      patient: PropTypes.shape({}),
      source: PropTypes.string,
      eventDate: PropTypes.instanceOf(moment),
      eventDescription: PropTypes.string,
    })
  ),
  linkForEvent: PropTypes.func.isRequired,
  sortData: PropTypes.shape({
    sortDir: PropTypes.string,
    sortBy: PropTypes.string,
  }).isRequired,
}

CaseEventTable.defaultProps = {
  eventData: [],
}

export default CaseEventTable
