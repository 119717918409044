import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import moment from 'moment';
import styles from './index.module.scss';

const DateCell = ({ date, format }) => {
  let dateValue = <em className={styles.date}>none</em>;
  if (date) dateValue = moment(date).format(format);
  return <Cell>{dateValue}</Cell>;
};

export default DateCell;

DateCell.propTypes = {
  date: PropTypes.string,
  format: PropTypes.string,
};

DateCell.defaultProps = {
  date: '',
  format: 'l',
};
