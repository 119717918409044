import React from 'react'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import TimePeriodFiltersContainer from '../TimePeriodFiltersContainer'
import CaseDetailFiltersContainer from '../CaseDetailFiltersContainer'
import UserFiltersContainer from '../UserFiltersContainer'
import FilterActionsContainer from '../FilterActionsContainer'

import { AsidePanel } from '../../ui'

const CaseResultsFilters = observer(({ showMsg, isOpen, onClose }) => (
  <AsidePanel
    title="Advanced Filters"
    leftAnimation
    isOpen={isOpen}
    onClose={onClose}
    data-cy="cases--adv-filer-panel"
    className={classnames('advanced-filter-panel', {
      showMsg: showMsg,
    })}
  >
    <div className="education">
      <p>
        <strong> Advanced Filters </strong> will make it easy to build custom
        reports on cases.{' '}
        <a href="https://help.protenus.com/hc/en-us/articles/115009161608-The-Cases-Page">
          {' '}
          Learn more here.
        </a>
        <br />
        <br />
        Click the <i className="material-icons icon-tune" /> icon in the results
        bar to show or hide Advanced Filters.
      </p>
    </div>
    <form name="lastpass-disable-search">
      <TimePeriodFiltersContainer />
      <CaseDetailFiltersContainer />
      <UserFiltersContainer />
    </form>
    <FilterActionsContainer />
  </AsidePanel>
))

CaseResultsFilters.displayName = 'CaseResultsFilters'
export default CaseResultsFilters

CaseResultsFilters.propTypes = {
  showMsg: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
