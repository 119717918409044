import React, { createRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Link, Redirect } from 'react-router-dom'
import styles from './index.module.scss'

class BulkVipIntro extends React.Component {
  // Ref
  fileInput = createRef()

  componentDidMount() {
    // We are making sure to start with a clean store state. Without this reset, entering the component with a
    // dirty state can happen if user navigates away mid session (with bad upload or stuck in uploading)
    // and enters the intro component again. We cannot put this cancel call in a ComponentDidMount because
    // that messes with the file upload
    const { reset } = this.props
    reset()
  }

  onFileSelect = () => {
    this.fileInput.current.value = null
    this.fileInput.current.click()
  }

  render() {
    const {
      onFileChange,
      downloadTemplate,
      attachedFile,
      loading,
      invalidUpload,
      reset,
      uploadComplete,
    } = this.props

    if (uploadComplete) {
      return <Redirect to="/vips/upload-confirm" />
    }

    return (
      <div className="vip--view">
        <div
          className={styles.vipUploads}
          data-cy="vip-bulk-registration--body"
        >
          <div>
            <h2>Bulk VIP Registration</h2>
            <p>
              Quickly identify <strong>Multiple Patients</strong> as{' '}
              <strong>VIPs</strong> by uploading a formatted spreadsheet.
            </p>

            <div className={styles.buttonDiv}>
              <form>
                <button
                  type="button"
                  disabled={loading}
                  key="fileSelect"
                  onClick={this.onFileSelect}
                  data-cy="bulk-VIP--upload-file--button"
                >
                  <input
                    type="file"
                    disabled={loading}
                    className="hidden"
                    key="fileInput"
                    ref={this.fileInput}
                    onChange={() => onFileChange(this.fileInput.current)}
                  />
                  {loading ? 'Uploading' : 'Upload File'}
                </button>
              </form>
              <Link to="/vips" onClick={reset}>
                <button type="button" data-cy="bulk-VIP--cancel--button">
                  Cancel
                </button>
              </Link>
              {attachedFile && invalidUpload && (
                <div className={styles.uploadInfo}>
                  <span className={styles.fileName}>
                    <i
                      className={classnames(
                        styles.icon,
                        'material-icons icon-error'
                      )}
                    />
                    {attachedFile.name}
                  </span>
                  <p
                    className={styles.warning}
                    data-cy="bulk-VIP--invalid-upload-message"
                  >
                    {invalidUpload}
                  </p>
                </div>
              )}
            </div>

            <ol>
              <li>
                <button
                  type="button"
                  onClick={downloadTemplate}
                  data-cy="vip-bulk-registration--template-link"
                >
                  Download the preformatted template file.
                </button>
              </li>
              <li>
                Enter your patient data into the template and save the file.
              </li>

              <ul>
                <li>
                  For best results, supply an MRN or Patient ID for each row to
                  strongly match patients.
                </li>
                <li>
                  If MRN and Patient ID are unavailable the full name and date
                  of birth of the patient can be used.
                </li>
                <li>Expiration date and description are optional.</li>
              </ul>
              <li>
                Click the UPLOAD FILE button and select the completed file to
                upload.
              </li>
            </ol>
            <p className={styles.note}>
              Note: Registering VIPs may increase the number of cases you
              receive in the VIP category.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

BulkVipIntro.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  downloadTemplate: PropTypes.func.isRequired,
  attachedFile: PropTypes.shape({
    name: PropTypes.string,
    lastModified: PropTypes.number,
    size: PropTypes.number,
    type: PropTypes.string,
  }),
  loading: PropTypes.bool,
  invalidUpload: PropTypes.string,
  reset: PropTypes.func.isRequired,
  uploadComplete: PropTypes.bool.isRequired,
}

BulkVipIntro.defaultProps = {
  attachedFile: null,
  invalidUpload: '',
  loading: false,
}

export default BulkVipIntro
