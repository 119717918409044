import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { DropdownList } from 'react-widgets';
import { AsidePanel, Button, Input, TimePlusCalendarSelector } from '../../ui';
import { useStateMatchProp } from '../../utils/hooks';
import styles from './index.module.scss';
import { PropTypes as mobxPropTypes } from 'mobx-react';
import FilterSectionHeader from '../../ui/FilterSectionHeader';
import {
  INGEST_PATH_OPTION,
  BACKFILL_PATH_OPTION,
} from '../stores/DeliveryStore/const';
import {
  DateHelpers,
} from 'common'
import {
  DEFAULT_DELIVERY_RANGE,
  DEFAULT_DELIVERY_AFTER_DATE,
  DEFAULT_DELIVERY_BEFORE_DATE,
  DELIVERY_AFTER_STORAGE_KEY,
  DELIVERY_BEFORE_STORAGE_KEY
} from '../stores/DeliveryStore/helpers'

const {
  TIME_PERIOD_VALUES: {
    DAY,
    WEEK,
    MONTH,
    NINETY_DAYS,
    YEAR,
    CUSTOM,
  },
} = DateHelpers

const processingStateOptions = [
  'Any',
  'Pending',
  'Failed',
  'Processed',
  'Unprocessed',
];

const pathOptions = ['Any', INGEST_PATH_OPTION, BACKFILL_PATH_OPTION];

const DataFeedDeliveryAside = ({
  history,
  query,
  location,
  deliveryAfter,
  deliveryBefore,
  deliveryRange,
  name,
  processingState,
  sourceOptions,
  path,
  source,
}) => {
  name = name || '';

  const [tempName, setTempName] = useStateMatchProp(name);
  const [tempProcessingState, setTempProcessingState] = useStateMatchProp(
    processingState
  );
  const [tempPath, setTempPath] = useStateMatchProp(path);
  const [tempSource, setTempSource] = useStateMatchProp(source);
  const [tempDeliveryRange, setTempDeliveryRange] = useStateMatchProp(deliveryRange);
  const [tempDeliveryAfter, setTempDeliveryAfter] = useStateMatchProp(deliveryAfter);
  const [tempDeliveryBefore, setTempDeliveryBefore] = useStateMatchProp(deliveryBefore);
  
  const canSubmit =
    tempName !== name ||
    tempProcessingState !== processingState ||
    tempPath !== path ||
    tempSource !== source ||
    tempDeliveryBefore !== deliveryBefore ||
    tempDeliveryAfter !== deliveryAfter ||
    tempDeliveryRange !== deliveryRange;

  const onSubmit = e => {
    e.preventDefault();
    if (!canSubmit) return;

    const search = {
      ...query,
      deliveryRange: tempDeliveryRange,
      name: tempName,
      processingState: tempProcessingState,
      path: tempPath,
      source: tempSource,
    }

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(search),
    });
  };

  return (
    <AsidePanel
      title="Advanced Filters"
      isOpen
      disableClose
      disableAnimation
      onClose={() => {}}
    >
      <form onSubmit={onSubmit} className={styles.form}>
        <FilterSectionHeader icon="icon-assignment" title="Incident Details" />
        <Input
          bordered
          padding="md"
          type="text"
          name="fdd-delivery-filter-name-or-path"
          font="sm"
          placeholder="Name or path"
          value={tempName}
          onChange={setTempName}
        />
        {
        <TimePlusCalendarSelector
                createdInformation={[
                  tempDeliveryAfter,
                  tempDeliveryBefore,
                ]}
                label="Date"
                options={[
                  DAY,
                  WEEK,
                  MONTH,
                  NINETY_DAYS,
                  YEAR,
                  CUSTOM,
                ]}
                defaultValue={tempDeliveryRange || DEFAULT_DELIVERY_RANGE}
                onCalendarChange={(date, valid, beforeOrAfter) => {
                  if (valid) {
                    if(beforeOrAfter === 'After') {
                      setTempDeliveryAfter(date);
                      localStorage.setItem(DELIVERY_AFTER_STORAGE_KEY, date);
                    } else {
                      setTempDeliveryBefore(date);
                      localStorage.setItem(DELIVERY_BEFORE_STORAGE_KEY, date);
                    }
                  }
                }}
                onChangeDropdown={(val) => {
                  setTempDeliveryRange(val.value);
                  localStorage.removeItem(DELIVERY_BEFORE_STORAGE_KEY);
                  localStorage.removeItem(DELIVERY_AFTER_STORAGE_KEY);
                  if(val.value === CUSTOM) {
                    setTempDeliveryAfter(DEFAULT_DELIVERY_AFTER_DATE);
                    setTempDeliveryBefore(DEFAULT_DELIVERY_BEFORE_DATE);
                    localStorage.setItem(DELIVERY_AFTER_STORAGE_KEY, DEFAULT_DELIVERY_AFTER_DATE);
                    localStorage.setItem(DELIVERY_BEFORE_STORAGE_KEY, DEFAULT_DELIVERY_BEFORE_DATE);
                  }
                }}
                hiddenCalendar={tempDeliveryRange === CUSTOM}
                restrictFutureDate
              />
          }
        <DropdownList
          data={sourceOptions}
          value={sourceOptions.find(({ value }) => value === tempSource)}
          onChange={({ value }) => setTempSource(value)}
          textField="label"
          valueField="value"
          valueComponent={({ item }) => (
            <span>
              <strong>Source</strong> {item?.label}
            </span>
          )}
          data-cy="fdd-delivery-filter-source"
        />
        <DropdownList
          data={pathOptions}
          value={tempPath}
          onChange={value => setTempPath(value)}
          valueComponent={({ item }) => (
            <span>
              <strong>Path</strong> {item}
            </span>
          )}
          data-cy="fdd-delivery-filter-path"
        />
        <DropdownList
          data={processingStateOptions}
          value={tempProcessingState}
          onChange={value => setTempProcessingState(value)}
          valueComponent={({ item }) => (
            <span>
              <strong>Processing</strong> {item}
            </span>
          )}
          data-cy="fdd-delivery-filter-processing"
        />
        <Button value="Apply" type="submit" disabled={!canSubmit} />
      </form>
    </AsidePanel>
  );
};

DataFeedDeliveryAside.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  query: PropTypes.shape({ selectedRow: PropTypes.string }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  deliveryAfter: PropTypes.string,
  deliveryBefore: PropTypes.string,
  deliveryRange: PropTypes.string,
  name: PropTypes.string,
  processingState: PropTypes.string.isRequired,
  sourceOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  path: PropTypes.string,
  source: PropTypes.string,
};

export default DataFeedDeliveryAside;
