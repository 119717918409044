import React from 'react'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import { ColumnGroup, Column, Cell } from 'fixed-data-table-2'
import PropTypes from 'prop-types'
import Registry from '../../../ui/registry/Registry'
import styles from './index.module.scss'
import Checkbox from '../../../ui/Checkbox'
import PatientNameCell from '../cells/PatientName'
import DOB from '../cells/DOB'
import MRN from '../cells/MRN'
import StatusContainer from '../cells/StatusContainer'

const BulkVipList = class BulkVipList extends React.Component {
  state = {
    width: 0,
    height: 0,
  }

  ref = React.createRef()

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    const registryOuter = document.querySelector('.registry')
    this.setState({
      width: this.ref.current.offsetWidth,
      availableHeight:
        registryOuter.offsetHeight - 100 /* Space for padding, title. */,
    })
  }

  setCellWidth = percentage => {
    percentage /= 100
    const { width } = this.state
    return width * percentage
  }

  render() {
    const {
      data,
      showMasterCheckbox,
      allSelected,
      toggleAll,
      mixedCheckedStatus,
    } = this.props
    const { width, availableHeight } = this.state
    // Calculate 50px for each row, 100px for headers, 5px for slight padding.
    const heightUpperBound = data.length * 50 + 105
    const tableHeight = Math.min(availableHeight, heightUpperBound)

    return (
      <>
        <Registry
          className={styles.table}
          dataCount={data.length}
          width={width}
          height={tableHeight}
          scroll
          ref={this.ref}
        >
          <ColumnGroup
            fixed
            header={
              <Cell className={styles.groupHeader}>Uploaded File Input</Cell>
            }
          >
            <Column
              header={() => {
                if (showMasterCheckbox)
                  return (
                    <Cell>
                      <Checkbox
                        checked={allSelected}
                        indeterminate={mixedCheckedStatus}
                        onChange={() => toggleAll()}
                      />
                    </Cell>
                  )
                return null
              }}
              width={this.setCellWidth(4)}
              cell={<StatusContainer />}
            />

            <Column
              header={<Cell>MRN</Cell>}
              width={this.setCellWidth(13)}
              cellClassName="no-wrap"
              cell={({ rowIndex }) =>
                MRN({ rows: data, rowKey: 'inputPatient', rowIndex })
              }
            />
            <Column
              header={<Cell>Patient ID</Cell>}
              width={this.setCellWidth(12)}
              cellClassName="no-wrap"
              cell={({ rowIndex }) => {
                const patient = data[rowIndex].inputPatient
                return <Cell>{patient && patient.patientId}</Cell>
              }}
            />
            <Column
              header={<Cell>Name</Cell>}
              width={this.setCellWidth(12)}
              cellClassName="no-wrap"
              cell={({ rowIndex }) =>
                PatientNameCell({
                  rows: data,
                  rowKey: 'inputPatient',
                  rowIndex,
                })
              }
            />
            <Column
              header={<Cell>DOB</Cell>}
              width={this.setCellWidth(9)}
              cellClassName="no-wrap"
              cell={({ rowIndex }) =>
                DOB({ rows: data, rowKey: 'inputPatient', rowIndex })
              }
            />
          </ColumnGroup>

          <ColumnGroup
            fixed
            header={
              <Cell className={styles.groupHeader}>
                Matched Patient in Protenus
              </Cell>
            }
          >
            <Column
              header={<Cell>MRN</Cell>}
              width={this.setCellWidth(13)}
              cellClassName="no-wrap"
              cell={({ rowIndex }) =>
                MRN({ rows: data, rowKey: 'matchedPatient', rowIndex })
              }
            />
            <Column
              header={<Cell>Patient ID</Cell>}
              width={this.setCellWidth(12)}
              cellClassName="no-wrap"
              cell={({ rowIndex }) => {
                const patient = data[rowIndex].matchedPatient
                return <Cell>{patient && patient.patientId}</Cell>
              }}
            />
            <Column
              header={<Cell>Name</Cell>}
              width={this.setCellWidth(15)}
              cellClassName="no-wrap"
              cell={({ rowIndex }) =>
                PatientNameCell({
                  rows: data,
                  rowKey: 'matchedPatient',
                  rowIndex,
                })
              }
            />
            <Column
              header={<Cell>DOB</Cell>}
              width={this.setCellWidth(10)}
              cellClassName="no-wrap"
              cell={({ rowIndex }) =>
                DOB({ rows: data, rowKey: 'matchedPatient', rowIndex })
              }
            />
          </ColumnGroup>
        </Registry>
        <div className={styles.divider} />
      </>
    )
  }
}

BulkVipList.propTypes = {
  data: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      inputPatient: PropTypes.shape({
        medicalRecordNumber: PropTypes.string,
        firstName: PropTypes.string,
        middleName: PropTypes.string,
        lastName: PropTypes.string,
        fullName: PropTypes.string,
        patientId: PropTypes.string,
        dateOfBirth: PropTypes.string,
      }),
      matchedPatient: PropTypes.shape({
        medicalRecordNumber: PropTypes.string,
        firstName: PropTypes.string,
        middleName: PropTypes.string,
        lastName: PropTypes.string,
        fullName: PropTypes.string,
        patientId: PropTypes.string,
        dateOfBirth: PropTypes.string,
      }),
      errorMessage: PropTypes.string,
    })
  ).isRequired,
  allSelected: PropTypes.bool,
  toggleAll: PropTypes.func,
  mixedCheckedStatus: PropTypes.bool,
  showMasterCheckbox: PropTypes.bool,
}

BulkVipList.defaultProps = {
  showMasterCheckbox: false,
}

BulkVipList.displayName = 'BulkVipList'

export default BulkVipList
