import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import moment from 'moment'
import classnames from 'classnames'
import ChartPropTypes from '../PropTypes'
import { Tooltip } from '../../../../ui'
import styles from './index.module.scss'
import { MedicationUtils } from 'common'

const SelectedBouncyDot = ({ incident, xScale, leftMargin }) => {
  const incidentMoment = moment(incident.startTime)
  const translate = `translate(${xScale(incidentMoment) + leftMargin}, 25)`
  return (
    <g key={'selected' + incident.id} transform={translate}>
      <circle className={styles.selectDot} r={6} data-cy="selected-incident" />
    </g>
  )
}

SelectedBouncyDot.propTypes = {
  incident: PropTypes.shape({
    id: PropTypes.string,
    startTime: PropTypes.string,
  }).isRequired,
  xScale: PropTypes.func.isRequired,
  leftMargin: PropTypes.number.isRequired,
}

/*
 * Layer responsible for drawing rectangles for each diversion incident.
 * and bouncy selected incident
 */
const DiversionIncidentLayer = ({
  height,
  incidents,
  leftMargin,
  onSelect,
  selectedIncident,
  selectedStatistic,
  xScale,
  linkIncident,
  diversionRowSelected,
}) => {
  const formatDate = d => d.format('M/D/YYYY')
  const rectHeight = 8
  const data = incidents.map(incidentGroup => {
    const { linkedIncidents } = incidentGroup
    const incident = linkedIncidents[0]
    const incidentTypes = linkedIncidents.reduce((types, incident) => {
      const formattedType = MedicationUtils.formatIncidentSummary(incident)
        .description
      if (!types.includes(formattedType))
        types += `${types.length ? ', ' : ''}${formattedType}`
      return types
    }, '')

    const incidentMoment = moment(incident.startTime)
    const x = xScale(incidentMoment)
    const translate = `translate(${x + leftMargin - 4}, ${height / 2 -
      rectHeight / 2})`

    const x0 = xScale(moment(incident.startTime))
    const x1 = xScale(moment(incident.endTime))
    const width = x1 - x0 + 8

    const insideSelectedStat = selectedStatistic
      ? moment(incident.startTime).isBetween(
          moment(selectedStatistic.startTime),
          moment(selectedStatistic.endTime)
        )
      : false

    return {
      incident: incidentGroup,
      insideSelectedStat,
      tooltipContent: (
        <div className={styles.tooltip}>
          <span>{formatDate(incidentMoment)}&nbsp;</span>
          <strong>{incidentTypes}</strong>
        </div>
      ),
      width,
      translate,
    }
  })

  const onClick = (e, inc) => {
    e.stopPropagation()
    diversionRowSelected ? onSelect(inc) : linkIncident(inc.primaryEvent)
  }

  return (
    // The clipPath is defined in FullActivityChart.
    <g clipPath="url(#clip)" data-cy="diversion-incidents">
      {data.map(
        ({
          incident,
          insideSelectedStat,
          tooltipContent,
          width,
          translate,
        }) => {
          return (
            <Tooltip
              key={incident.id}
              content={tooltipContent}
              position="top"
              followCursor
            >
              <rect
                className={classnames(styles.incidentRect, {
                  [styles.insideSelectedStat]: insideSelectedStat,
                })}
                rx={rectHeight / 2}
                width={width}
                height={rectHeight}
                onClick={e => onClick(e, incident)}
                transform={translate}
              />
            </Tooltip>
          )
        }
      )}
      {selectedIncident && (
        <SelectedBouncyDot
          incident={selectedIncident}
          xScale={xScale}
          leftMargin={leftMargin}
        />
      )}
    </g>
  )
}

DiversionIncidentLayer.propTypes = {
  height: PropTypes.number.isRequired,
  incidents: mobxPropTypes.arrayOrObservableArrayOf(ChartPropTypes.incident)
    .isRequired,
  leftMargin: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedIncident: ChartPropTypes.incident,
  selectedStatistic: ChartPropTypes.statisticPeriod,
  xScale: PropTypes.func.isRequired,
  linkIncident: PropTypes.func,
  diversionRowSelected: PropTypes.bool,
}

DiversionIncidentLayer.defaultProps = {
  selectedIncident: undefined,
  selectedStatistic: undefined,
}

export default DiversionIncidentLayer
