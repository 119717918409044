import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DateHelpers } from 'common'
import { Source, Tooltip } from '../index'

const TimeEntryTooltip = ({ timeEntry, source, children }) => {
  let content = 'Loading...'

  if (timeEntry) {
    const { startReason, location, start, end } = timeEntry
    content = ''

    if (start && end && moment(start).isAfter(end))
      content += '(Possible incorrect data) '

    startReason ? (content += startReason) : (content += 'No Start Reason')

    if (start || end) {
      const app = {
        startTime: start ? start : '',
        endTime: end ? end : '',
      }
      if (app.endTime && !app.startTime) app.startTime = app.endTime
      const formatted = DateHelpers.formatDates(app)
      content += ` on ${formatted.join(' ').trim()}`
      if (start && !end) content += ' (No End Time Provided)'
      if (!start && end) content += ' (No Start Time Provided)'
    } else {
      content += ' No Clock In and Out Data'
    }

    if (location) content += ` in ${location}`
    if (source) {
      content = (
        <div>
          {content}
          <br />
          <Source source={source} className="tooltip-small" raw />
        </div>
      )
    }
  }

  return <Tooltip content={content}>{children}</Tooltip>
}

TimeEntryTooltip.propTypes = {
  children: PropTypes.node,
  timeEntry: PropTypes.shape({
    startTime: PropTypes.string,
    location: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    startReason: PropTypes.string,
  }),
  source: PropTypes.string,
}

TimeEntryTooltip.defaultProps = {
  timeEntry: null,
  source: null,
}

export default TimeEntryTooltip
