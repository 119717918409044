import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import AnimateHeight from 'react-animate-height'
import classnames from 'classnames'
import { nodeIsType } from '../../utils/ComponentUtils'
import Header from './Header'
import Summary from './Summary'
import Detail from './Detail'
import Section from './Section'
import styles from './index.module.scss'
import Anchor from '../Anchor'

const ExpandCollapseCard = forwardRef(
  (
    { children, warning, defaultCollapsed, selected, 'data-cy': dataCy },
    ref
  ) => {
    const [isExpanded, setIsExpanded] = React.useState(!defaultCollapsed)
    const [isAnimating, setIsAnimating] = React.useState(false)
    if (!Array.isArray(children)) children = [children]
    const header = children.find(node =>
      nodeIsType(node, ExpandCollapseCard.Header)
    )
    const summary = children.find(node =>
      nodeIsType(node, ExpandCollapseCard.Summary)
    )
    const details = children.filter(node =>
      nodeIsType(node, ExpandCollapseCard.Detail)
    )

    const needsExpandCollapseButton = details.length > 0

    function toggleExpandCollapse(e) {
      e.stopPropagation()
      setIsAnimating(true)
      setIsExpanded(!isExpanded)
    }

    return (
      <div ref={ref}>
        <div
          className={classnames(styles.staticSections, {
            [styles.collapsed]:
              !needsExpandCollapseButton || (!isAnimating && !isExpanded),
            [styles.selected]: selected,
          })}
          data-cy={dataCy}
        >
          <Section
            className={classnames(styles.headerSection, {
              [styles.warning]: warning,
            })}
          >
            <div>{header}</div>
            {needsExpandCollapseButton && (
              <Anchor
                tag="span"
                className={styles.expandCollapseButton}
                onClick={e => toggleExpandCollapse(e)}
                role="button"
                tabIndex="0"
                onKeyDown={e => ({ key }) =>
                  (key === ' ' || key === 'Enter') && toggleExpandCollapse(e)}
              >
                <i
                  className={classnames(
                    'material-icons',
                    isExpanded ? 'icon-expand_less' : 'icon-expand_more'
                  )}
                />
              </Anchor>
            )}
          </Section>
          {summary && (
            <Section
              className={classnames({
                [styles.selected]: selected,
              })}
            >
              {summary}
            </Section>
          )}
        </div>
        {details.length > 0 && (
          <AnimateHeight
            height={isExpanded ? 'auto' : 0}
            duration={250}
            className={styles.animatedSections}
            onAnimationEnd={() => setIsAnimating(false)}
          >
            {details.map(detail => (
              <Section
                className={classnames({
                  [styles.selected]: selected,
                })}
                key={detail.props.key || Math.random()}
              >
                {detail}
              </Section>
            ))}
          </AnimateHeight>
        )}
      </div>
    )
  }
)

ExpandCollapseCard.propTypes = {
  'data-cy': PropTypes.string,
  warning: PropTypes.bool,
  selected: PropTypes.bool,
  defaultCollapsed: PropTypes.bool,
  children: function({ children }) {
    if (!Array.isArray(children)) children = [children]
    // Only consider non-null-like nodes.
    children = children.filter(n => n)

    if (
      children.filter(node => nodeIsType(node, ExpandCollapseCard.Header))
        .length !== 1
    )
      return new Error('Exactly one Header required.')

    if (
      children.filter(node => nodeIsType(node, ExpandCollapseCard.Summary))
        .length > 1
    )
      return new Error('At most one Summary is supported.')

    if (
      children.filter(
        node =>
          nodeIsType(node, ExpandCollapseCard.Header) ||
          nodeIsType(node, ExpandCollapseCard.Summary) ||
          nodeIsType(node, ExpandCollapseCard.Detail)
      ).length !== children.length
    )
      return new Error(
        'Only Header, Summary, and Detail sub-components are supported.'
      )
  },
}

ExpandCollapseCard.defaultProps = {
  selected: false,
  warning: false,
  defaultCollapsed: false,
  children: [],
}

ExpandCollapseCard.displayName = 'ExpandCollapseCard'

export default ExpandCollapseCard

ExpandCollapseCard.Header = Header
ExpandCollapseCard.Summary = Summary
ExpandCollapseCard.Detail = Detail
