import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { PropTypes as mobxPropTypes } from 'mobx-react'

import ChartPropTypes from '../PropTypes'
import VerticalRuleLayer from '../../VerticalRuleLayer'
import DiversionStatisticPeriodLayer from '../DiversionStatisticPeriodLayer'
import DiversionIncidentLayer from '../DiversionIncidentLayer'
import DiversionActivityLabelLayer from '../DiversionActivityLabelLayer'
import GutterLayer from '../../GutterLayer'
import TimeEntryLayerContainer from '../../layers/TimeEntryLayerContainer'
import styles from './index.module.scss'

/**
 * This chart shows diversion incidents segmented by 30 day assessment periods.
 */

const DiversionActivityChart = ({
  diversionRowSelected,
  height,
  incidents,
  leftMargin,
  leftPadding,
  rightPadding,
  xScale,
  selectedIncident,
  selectedStatistic,
  statisticPeriods,
  onSelectIncident,
  onSelectRow,
  onSelectStatisticPeriodStartDate,
}) => (
  <div
    className={classnames(styles.svgWrapper, {
      [styles.selected]: diversionRowSelected,
    })}
    style={{ height }}
    data-cy="diversion-row"
  >
    <svg
      className="chart"
      width="100%"
      height={height}
      onClick={() => onSelectRow()}
    >
      <VerticalRuleLayer />
      <TimeEntryLayerContainer />
      <DiversionStatisticPeriodLayer
        xScale={xScale}
        statisticPeriods={statisticPeriods}
        height={height}
        leftMargin={leftMargin}
        onSelectStatisticPeriodStartDate={onSelectStatisticPeriodStartDate}
        selectedIncident={selectedIncident}
      />
      <DiversionIncidentLayer
        diversionRowSelected={diversionRowSelected}
        linkIncident={onSelectRow}
        xScale={xScale}
        incidents={incidents}
        height={height}
        leftMargin={leftMargin}
        onSelect={onSelectIncident}
        selectedIncident={selectedIncident}
        selectedStatistic={selectedStatistic}
      />
      <DiversionActivityLabelLayer height={height} leftMargin={leftMargin} />
      <GutterLayer
        leftMargin={leftMargin}
        leftPadding={leftPadding}
        rightPadding={rightPadding}
      />
    </svg>
  </div>
)

DiversionActivityChart.propTypes = {
  diversionRowSelected: PropTypes.bool.isRequired,
  height: PropTypes.number.isRequired,
  incidents: mobxPropTypes.arrayOrObservableArrayOf(ChartPropTypes.incident)
    .isRequired,
  leftMargin: PropTypes.number.isRequired,
  leftPadding: PropTypes.number.isRequired,
  rightPadding: PropTypes.number.isRequired,
  xScale: PropTypes.func.isRequired,
  selectedIncident: ChartPropTypes.incident,
  selectedStatistic: ChartPropTypes.statisticPeriod,
  statisticPeriods: mobxPropTypes.arrayOrObservableArrayOf(
    ChartPropTypes.statisticPeriod
  ).isRequired,
  onSelectIncident: PropTypes.func.isRequired,
  onSelectRow: PropTypes.func.isRequired,
  onSelectStatisticPeriodStartDate: PropTypes.func.isRequired,
}

DiversionActivityChart.defaultProps = {
  selectedIncident: undefined,
  selectedStatistic: undefined,
}

export default DiversionActivityChart
