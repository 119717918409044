import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DropdownList, Multiselect } from 'react-widgets'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import moment from 'moment'
import { AutomatedEmailStore, mapValuesToArray } from 'common'
import Store from '../stores/TemplateStore'
import { Input, TextareaAutocomplete, MultilineInput } from '../../ui'
import styles from './index.module.scss'

const Template = observer(
  class Template extends React.Component {
    static propTypes = {
      type: PropTypes.string,
      startAt: PropTypes.string,
      template: PropTypes.shape({}),
      settingsCallback: PropTypes.func,
    }

    /*
    The settings callback func is passed a bool of action completion
    and used in the template settings page to trigger modify and/or create components closeing.
    Set it to a empty function if Template is not called from settings
  */

    static defaultProps = {
      settingsCallback: () => {},
    }

    componentDidMount() {
      const { type, startAt, template } = this.props
      Store.setTemplateType(type)
      /*
      the template settings section needs to start the template at a
      certain stage. That is set with the 'startAt' string in the props
    */
      switch (startAt) {
        case 'createNew':
          Store.handleNewTemplate()
          break
        case 'modify':
          Store.templateStateChange('modify')
          Store.onTemplateChange(template)
          break
        default:
          break
      }
    }

    componentWillUnmount() {
      Store.reset()
    }

    autoFillDataProvider = () => [
      { name: 'Case Event Date', char: '{Case_Event_Date}' },
      { name: 'Case Number', char: '{Case_Number}' },
      { name: 'Case Owner', char: '{Case_Owner}' },
      { name: 'Case Type', char: '{Case_Type}' },
      { name: 'User Department', char: '{EMR_User_Dept}' },
      { name: 'User Email', char: '{EMR_User_Email}' },
      { name: 'User Name', char: '{EMR_User_Name}' },
      { name: 'User Organization', char: '{EMR_User_Org}' },
      { name: 'User Role', char: '{EMR_User_Role}' },
      { name: 'Future Encounter Date', char: '{Future_Encounter_Date}' },
      { name: 'Future Encounter Department', char: '{Future_Encounter_Dept}' },
      { name: 'Last Encounter Date', char: '{Last_Encounter_Date}' },
      { name: 'Last Encounter Department', char: '{Last_Encounter_Dept}' },
      { name: 'Patient DOB', char: '{Patient_DOB}' },
      { name: 'Patient Name', char: '{Patient_Name}' },
    ]

    renderInput(ref, label, value, disabled = false) {
      if (ref === 'emailSubject') {
        // only use the Autocomplete component if we are editing or creating emailSubject
        if (Store.modifyingTemplate || Store.creatingNewTemplate) {
          return (
            <div key="email-subject" className={styles.textareaAsSubject}>
              <TextareaAutocomplete
                autoFillDataProvider={this.autoFillDataProvider}
                field={ref}
                key="emailSubjectModify"
                onChange={Store.onChange}
                placeholder="Subject Body, type { to insert a tag"
                value={value}
                rows={1}
                maxRows={1}
              />
            </div>
          )
        }
      }

      return (
        <Input
          autoFocus
          borderRadius="md"
          darkBackground
          disabled={disabled}
          font="sm"
          error={Store.invalidList.includes(ref)}
          key={`Input-title-${ref}`}
          label={label}
          margin="md"
          onChange={val => Store.onChange(val, ref)}
          padding="md"
          placeholder={label}
          value={value}
          name="template-name-input"
        />
      )
    }

    renderTextarea() {
      // only use the Autocomplete component if we are editing or creating a template
      if (Store.modifyingTemplate || Store.creatingNewTemplate) {
        return (
          <TextareaAutocomplete
            autoFillDataProvider={this.autoFillDataProvider}
            field="textareaValue"
            key="textareaModify"
            darkBackground
            onChange={Store.onChange}
            placeholder="Template Body, type { to insert a tag"
            value={Store.textareaValue}
          />
        )
      }

      return (
        <MultilineInput
          autoFocus
          key="textarea"
          darkBackground
          onChange={val => Store.onChange(val, 'textareaValue')}
          placeholder="Template Body"
          value={Store.textareaValue}
          maxRows={10}
        />
      )
    }

    renderMultiselectInput(key, ref, label, onChange, value, text, disabled) {
      return (
        <div
          key={`multiselect__${key}`}
          className={classnames(
            `multiselect__to input-container flat ${
              Store.invalidList.includes(ref) ? 'js-error' : ''
            }`,
            { 'js-hide-label': !value.length && !text }
          )}
        >
          <label> {label} </label>
          <Multiselect
            defaultValue={value.slice()}
            data={value.slice()}
            disabled={disabled}
            textField="name"
            allowCreate
            onCreate={name => Store.handleCreate(key, name)}
            onChange={(...args) => onChange(...args, key)}
            value={value.slice()}
            placeholder={label}
            onBlur={() => onChange(text, 'add', key)}
            searchTerm={text}
            onSearch={(t, metadata) => {
              // clear happens when a user clicks X on one of the widgets, don't wanna call
              // handle search because it will clear out the currently entered text
              if (metadata.action === 'clear') return null
              if (!t.includes(',')) return Store.handleSearch(key, t)
              return null
            }}
            onKeyUp={e => Store.handleKeyUp(key, e)}
          />
        </div>
      )
    }

    renderDropdown() {
      return (
        <DropdownList
          key="dropdown"
          data={toJS(Store.templates)}
          onChange={Store.onTemplateChange}
          placeholder="Select a Template"
          textField="name"
          value={Store.templateTitle || ''}
        />
      )
    }

    renderCreatingNewTemplate() {
      return {
        header: [
          <span key="title" className="template-actionTitle">
            Creating Template
          </span>,
          <li
            key="discard"
            data-cy="discard-template-button"
            className="text-danger"
            onClick={() => {
              Store.reset()
              this.props.settingsCallback()
            }}
          >
            {' '}
            Discard{' '}
          </li>,
          <li
            key="create"
            data-cy="create-new-template-button"
            className={classnames({
              muted: !Store.validateContent || !Store.validateTitle,
            })}
            onClick={() => {
              Store.createNewTemplate(this.props.settingsCallback)
            }}
          >
            {' '}
            Create New Template{' '}
          </li>,
        ],
        content: [
          this.renderInput(
            'templateTitle',
            'Template Name',
            Store.templateTitle
          ),
          Store.templateType === 'email'
            ? this.renderInput('emailSubject', 'Subject', Store.emailSubject)
            : null,
          this.renderTextarea(),
        ],
      }
    }

    renderLastEditInfo() {
      const {
        lastModifiedBy: { firstName, lastName } = {},
        lastModified,
        created,
      } = Store.template
      const pastAction = lastModified === created ? 'created' : 'last modified'
      return (
        <div className="template-lastEdit">{`This template was ${pastAction} on ${moment(
          lastModified
        ).format('LL')} by ${firstName} ${lastName} `}</div>
      )
    }

    renderUsingEmailTemplate() {
      let fromAddress = AutomatedEmailStore.address
      if (AutomatedEmailStore.alias)
        fromAddress += ` (${AutomatedEmailStore.alias})`
      if (AutomatedEmailStore.replyTo)
        fromAddress += ` - replies are sent to ${AutomatedEmailStore.replyTo}`

      const obj = {
        header: [
          <span key="title" className="template-actionTitle">
            {Store.templateTitle}
          </span>,
          <li
            key="modify"
            className={classnames({ hidden: !Store.canModifyTemplates })}
            onClick={() => Store.templateStateChange('modify')}
            data-cy="modify-template-button"
          >
            {' '}
            Modify Template{' '}
          </li>,
          <li
            key="add"
            className={classnames({ muted: Store.sending })}
            onClick={Store.save}
          >
            {' '}
            {`${Store.sending ? 'Sending...' : 'Send Email'}`}{' '}
          </li>,
        ],
        content: [
          this.renderDropdown(),
          this.renderInput('from', 'From', fromAddress, true),
          this.renderMultiselectInput(
            'to',
            'toEmail',
            'To',
            Store.onEmailsChange,
            Store.toEmails,
            Store.totext
          ),
          this.renderMultiselectInput(
            'cc',
            'ccEmail',
            'CC',
            Store.onEmailsChange,
            Store.ccEmails,
            Store.cctext,
            toJS(Store.defaultCC)
          ),
          this.renderInput('emailSubject', 'Subject', Store.emailSubject),
          this.renderTextarea(),
        ],
        footer: this.renderLastEditInfo(),
      }

      if (Store.sendError) {
        obj.header = (
          <span key="error" className="text-danger">
            An error occured when sending the email. Please contact{' '}
            <a href="mailto:help@protenus.com">Protenus Support.</a>
          </span>
        )
      }
      return obj
    }

    renderUsingNoteTemplate() {
      return {
        header: [
          <span key="title" className="template-actionTitle">
            {Store.templateTitle}
          </span>,
          <li
            key="modify"
            className={classnames({ hidden: !Store.canModifyTemplates })}
            onClick={() => Store.templateStateChange('modify')}
          >
            {' '}
            Modify Template{' '}
          </li>,
          <li
            key="add"
            className={classnames({
              muted: !Store.validateContent,
            })}
            onClick={Store.save}
          >
            {' '}
            Save & Add to Case{' '}
          </li>,
        ],
        content: [this.renderDropdown(), this.renderTextarea()],
        footer: this.renderLastEditInfo(),
      }
    }

    renderEditingTemplate() {
      // If trying to delete, show the confirmation buttons.
      let head
      if (Store.deletingTemplate) {
        head = [
          <span key="title" className="template-actionTitle">
            Deleting Template
          </span>,
          <li
            key="delete"
            data-cy="confirm-delete-template-button"
            className="text-danger"
            onClick={() => {
              Store.onDeleteTemplate(this.props.settingsCallback)
            }}
          >
            {' '}
            Confirm Delete{' '}
          </li>,
          <li
            key="cancel"
            onClick={() => {
              Store.toggleDelete(false)
              this.props.settingsCallback()
            }}
          >
            Cancel
          </li>,
        ]
      } else {
        head = [
          <span key="template-actionTitle" className="template-actionTitle">
            Modifying Template
          </span>,
          <li
            key="delete"
            data-cy="delete-template-button"
            className="text-danger"
            onClick={() => {
              Store.toggleDelete(true)
            }}
          >
            {' '}
            Delete Template{' '}
          </li>,
          <li
            key="cancel"
            onClick={() => {
              Store.templateStateChange('cancel')
              this.props.settingsCallback()
            }}
          >
            {' '}
            Cancel Update{' '}
          </li>,
          <li
            key="update"
            data-cy="update-template-button"
            className={classnames({ muted: !Store.templateDirty })}
            onClick={() => {
              Store.onUpdateTemplate(this.props.settingsCallback)
            }}
          >
            {' '}
            Update Template{' '}
          </li>,
        ]
      }
      return {
        header: head,
        content: [
          this.renderInput(
            'templateTitle',
            'Template Name',
            Store.templateTitle
          ),
          Store.templateType === 'email'
            ? this.renderInput('emailSubject', 'Subject', Store.emailSubject)
            : null,
          this.renderTextarea(),
        ],
      }
    }

    renderTemplates() {
      let renderContent = {}

      // Making a new template
      if (Store.creatingNewTemplate)
        renderContent = this.renderCreatingNewTemplate()
      // Using either a Email or Note template
      else if (Store.selectedTemplate && !Store.modifyingTemplate)
        renderContent =
          Store.templateType === 'email'
            ? this.renderUsingEmailTemplate()
            : this.renderUsingNoteTemplate()
      // modify either a email or note template
      else if (Store.selectedTemplate && Store.modifyingTemplate)
        renderContent = this.renderEditingTemplate()
      // no template selected
      else {
        renderContent = {
          header: [
            Store.canModifyTemplates ? (
              <li key="new" onClick={Store.handleNewTemplate}>
                {' '}
                Create New Template{' '}
              </li>
            ) : null,
          ],
          content: this.renderDropdown(),
        }
      }
      return (
        <div
          className={classnames('template', {
            'confirm-delete': Store.deletingTemplate,
          })}
        >
          <div className="template__header">
            <ul
              className={classnames({
                empty: renderContent.header && !renderContent.header.length,
              })}
            >
              {renderContent.header}
            </ul>
          </div>
          <div className="template__content">
            {Store.tagErrors.size > 0 && (
              <span className="text-danger">
                {`The following tag${
                  Store.tagErrors.size > 1 ? 's are ' : ' is'
                } not valid`}
                &nbsp;
              </span>
            )}
            {mapValuesToArray(Store.tagErrors).map((err, idx) => (
              <span className="text-danger" key={`${err}+${idx}`}>
                {err}&nbsp;
              </span>
            ))}
            <form onSubmit={e => e.preventDefault()}>
              {renderContent.content}
            </form>
            {renderContent.footer}
          </div>
        </div>
      )
    }

    render() {
      return (
        <div className={`${Store.templateType}Template`}>
          {this.renderTemplates()}
        </div>
      )
    }
  }
)

Template.displayName = 'Template'

export default Template
