import { FeatureFlags, HalUtils, localStorageHasFlag } from 'common'
import Store from '../../activity/stores/Store'
import moment from 'moment'

/**
 * Filters relationships that we don't want to surface
 *
 * @param employeeId (optional)
 * @param relationships
 * @returns {*}
 */
export const filterRelationships = ({
  employeeId = '',
  relationships = [],
}) => {
  let processedRel = relationships

  if (employeeId)
    processedRel = processedRel.filter(
      r =>
        r.ids[0].id === employeeId &&
        !r.relationships.includes('alias') && // aliases are handled by a separate component
        !r.relationships.includes('primary care provider') // no need to list all the patients this user provides care for
    )
  else
    processedRel = processedRel.filter(
      r => !r.relationships.includes('alias') // aliases are handled by a separate component
    )
  // set family member relationship behind feature flag
  if (!localStorageHasFlag(FeatureFlags.FAMILY_MEMBER_RELATIONSHIPS))
    processedRel = processedRel.filter(
      r => !r.relationships.includes('family member')
    )

  return processedRel
}

/**
 * Groups relationships and the other person
 *
 * @param relationships
 * @returns Array of the related person
 */
export const groupByRelationshipType = (relationships = []) => {
  const result = []

  relationships.forEach(r => {
    const other = Object.assign({}, r.people[1])
    other.id = r.ids[1].id
    other._links = r.ids[1]._links

    if (r.sources) {
      if (r.sources.length === 1) other.relationshipSource = r.sources[0]
      else other.relationshipSource = `${r.sources.length} Sources`
    } else {
      other.relationshipSource = 'Unknown Source'
    }

    // if there is metadata copy that into the other person
    r.relationships.forEach(relType => {
      other.type = relType
      if (r.metadata && r.metadata[relType])
        other.metadata = r.metadata[relType]
    })

    result.push(other)
  })

  // desc sort if it has created timestamp
  result.sort((a, b) => {
    if (a.metadata?.created) {
      if (!b.metadata?.created) return -1
      return moment(a.metadata.created).isBefore(b.metadata.created) ? 1 : -1
    } else if (b.metadata?.created) return 0
    return null
  })

  return result
}

/**
 * Constructs title (relationship type e.g. manager) string
 *
 * @param type
 * @returns {string}
 */
export const getTitle = (type = '') =>
  type.capitalizeFirstLetter() + (type === 'sponsored' ? ' By' : ' Of')

/**
 * Parses href link for person
 *
 * @param person
 * @returns {*}
 */
export const getPersonLink = person => {
  const isPatient = HalUtils.getEndpoint(person) === 'patients'
  return isPatient
    ? Store.getPatientLink({ patientId: person.id })
    : Store.getUserLink({ userId: person.id })
}
