import React from 'react'
import { computed, decorate } from 'mobx'
import { observer } from 'mobx-react'

import {
  AppUserStore,
  HalUtils,
  LoginStore,
  RouterContainer,
  GroupStore,
  convertMultiValue,
} from 'common'

import AppUserSelect from '../AppUserSelect'
import OpenCasesStore from '../stores/OpenCasesStore'

/**
 * Component to render the total open cases report.
 * @extends React.Component
 */
const CaseReportTotalOpen = observer(
  class CaseReportTotalOpen extends React.Component {
    // computed
    get groupParam() {
      return OpenCasesStore.group
        ? convertMultiValue(OpenCasesStore.group)
        : convertMultiValue(GroupStore.groupIds)
    }

    /**
     * COMPUTED
     * Compute the total number of open cases for a specific user.
     * @return {Object} a React DOM node
     */
    get ownerOpenCasesTotal() {
      if (OpenCasesStore.owner !== null) {
        const params = {}
        params.resolution = 'null'
        params.owner = OpenCasesStore.owner
        params.group = this.groupParam

        return (
          <span
            onClick={() => RouterContainer.go('/cases', params)}
            className="prot-a data__count"
          >
            {OpenCasesStore.ownerTotal}
          </span>
        )
      }
      return <span className="data__count">{OpenCasesStore.ownerTotal}</span>
    }

    userName(userId) {
      if (userId === null) {
        return 'All Users'
      }
      const user = AppUserStore.enabledActiveCaseOwners.find(
        el => HalUtils.getId(el) === userId
      )
      if (user) {
        return userId === LoginStore.id
          ? 'My Cases'
          : `${user.firstName} ${user.lastName}`
      }

      return 'All Users'
    }

    /**
     * Render the component to the DOM.
     * @return {Object} React DOM Object
     */
    render() {
      return (
        <section
          className="home-sub-section report-totals"
          data-cy="report-totals"
        >
          <h2>Open Cases</h2>
          <AppUserSelect
            paramName="openCasesOwner"
            value={OpenCasesStore.owner}
            users={AppUserStore.enabledActiveCaseOwners}
            inverted
          />
          <ul className="columns large data">
            <li className="column">
              <span>All Cases</span>
              <span
                onClick={() =>
                  RouterContainer.go('cases', {
                    resolution: 'null',
                    group: this.groupParam,
                  })
                }
                className="prot-a data__count"
                data-cy="stat__cases--open"
              >
                {OpenCasesStore.total}
              </span>
            </li>
            <li className="column">
              <span>{this.userName(OpenCasesStore.owner)}</span>
              <span data-cy="stat__cases--owned">
                {this.ownerOpenCasesTotal}
              </span>
            </li>
          </ul>
          <ul className="columns data">
            <li className="column">
              <span>Unassigned Cases</span>
              <span
                onClick={() =>
                  RouterContainer.go('cases', {
                    owner: 'null',
                    resolution: 'null',
                    group: this.groupParam,
                  })
                }
                className="data__count prot-a"
                data-cy="stat__cases--unassigned"
              >
                {OpenCasesStore.unassignedTotal}
              </span>
            </li>
            <li className="column">
              <span>Recently Modified</span>
              <span className="data__count" data-cy="stat__cases--recent">
                {OpenCasesStore.recentlyModifiedTotal}
              </span>
            </li>
          </ul>
        </section>
      )
    }
  }
)

decorate(CaseReportTotalOpen, {
  ownerOpenCasesTotal: computed,
})

CaseReportTotalOpen.displayName = 'CaseReportTotalOpen'

export default CaseReportTotalOpen
