import React from 'react';
import PropTypes from 'prop-types';
import { AsidePanel, TimePlusCalendarSelector } from '../../ui';
import { DropdownList } from 'react-widgets';
import cn from 'classnames';
import {
  formatFilterDate,
  handleOnChange,
  hasThisOption,
  mapKeys,
} from '../IncidentFilterBar/helpers';
import styles from './index.module.scss';
import DropdownItem from './DropdownItem';
import ExclusionMultiselect from '../../ui/Filters/ExclusionMultiselect';
import ExclusionFreeForm from '../../ui/Filters/ExclusionFreeForm';
import commaSeparatedToArray from '../../utils/commaSeparatedToArray';
import { FeatureFlags, localStorageHasFlag } from 'common';

const IncidentAdvancedFilters = ({
  isOpen,
  history,
  filters,
  queryCriteria,
  toggleOpen,
}) => {
  const hasIncidentReportsFeature = localStorageHasFlag(
    FeatureFlags.INCIDENT_REPORTS
  );
  return (
    <AsidePanel
      title="Advanced Filters"
      leftAnimation
      isOpen={isOpen}
      onClose={toggleOpen}
    >
      <div className={styles.values}>
        <h4 className={`${styles.subheader} ${styles.filterMargin} `}>
          <i className={cn('material-icons icon-assignment', styles.icon)} />
          Incident Details
        </h4>
        {Object.entries(filters).map(([key, options]) => {
          const data = options;
          if (key === 'dates') {
            return (
              <TimePlusCalendarSelector
                className={styles.filterMargin}
                CustomListItem={DropdownItem}
                key={key}
                filterKey="range"
                createdInformation={[
                  queryCriteria.rangeAfter,
                  queryCriteria.rangeBefore,
                ]}
                label="Time"
                options={data}
                defaultValue={queryCriteria.range}
                onCalendarChange={(date, valid, beforeOrAfter) => {
                  if (valid) {
                    handleOnChange(
                      beforeOrAfter,
                      formatFilterDate(date),
                      queryCriteria,
                      history.push
                    );
                  }
                }}
                onChangeDropdown={val =>
                  handleOnChange(key, val, queryCriteria, history.push)
                }
                hiddenCalendar={queryCriteria.range === 'custom'}
                restrictFutureDate
              />
            );
          }
          if (key === 'userFullNames') {
            const isExcluding = queryCriteria.excludeFilters?.includes(
              mapKeys(key)
            );
            const value = commaSeparatedToArray(
              queryCriteria[mapKeys('userFullNames')]
            );
            return (
              <ExclusionFreeForm
                key={key}
                selectedValues={commaSeparatedToArray(
                  queryCriteria[mapKeys('userFullNames')]
                )}
                hideExclusion={true}
                label="User Name"
                inputKey="userFullNames"
                handleOnChange={handleOnChange}
                queryCriteria={queryCriteria}
                history={history}
                isExcluding={isExcluding}
                onToggleExclusion={() => {
                  handleOnChange(
                    key,
                    value,
                    queryCriteria,
                    history.push,
                    !isExcluding
                  );
                }}
              />
            );
          } else if (key === 'patientFullNames') {
            const isExcluding = queryCriteria.excludeFilters?.includes(
              mapKeys(key)
            );
            const value = commaSeparatedToArray(
              queryCriteria[mapKeys('patientFullNames')]
            );
            return (
              <ExclusionFreeForm
                key={key}
                selectedValues={commaSeparatedToArray(
                  queryCriteria[mapKeys('patientFullNames')]
                )}
                hideExclusion={true}
                label="Patient Name"
                inputKey="patientFullNames"
                handleOnChange={handleOnChange}
                queryCriteria={queryCriteria}
                history={history}
                isExcluding={isExcluding}
                onToggleExclusion={() => {
                  handleOnChange(
                    key,
                    value,
                    queryCriteria,
                    history.push,
                    !isExcluding
                  );
                }}
              />
            );
          }
          if (key === 'medications' && hasIncidentReportsFeature) {
            const isExcluding = queryCriteria.excludeFilters?.includes(
              mapKeys(key)
            );
            const value = commaSeparatedToArray(queryCriteria[mapKeys(key)]);
            return (
              <ExclusionFreeForm
                key={key}
                selectedValues={commaSeparatedToArray(
                  queryCriteria[mapKeys(key)]
                )}
                hideExclusion={false}
                label="Medication Name"
                inputKey={key}
                value={value}
                handleOnChange={handleOnChange}
                queryCriteria={queryCriteria}
                history={history}
                isExcluding={isExcluding}
                onToggleExclusion={() => {
                  handleOnChange(
                    key,
                    value,
                    queryCriteria,
                    history.push,
                    !isExcluding
                  );
                }}
              />
            );
          }

          if (
            hasIncidentReportsFeature &&
            (key === 'groups' || key === 'types' || key === 'adcs')
          ) {
            const value = data.filter(({ value, id }) =>
              (queryCriteria[mapKeys(key)] || []).includes(
                key === 'groups' ? id : value
              )
            );
            const label =
              key === 'groups' ? 'Group' : key === 'types' ? 'Type' : 'ADC';

            const isExcluding = queryCriteria.excludeFilters?.includes(
              mapKeys(key)
            );

            return (
              <ExclusionMultiselect
                disabled={data.length <= 1}
                key={key}
                label={label}
                options={data}
                value={value}
                onChange={values => {
                  handleOnChange(
                    key,
                    values,
                    queryCriteria,
                    history.push,
                    isExcluding
                  );
                }}
                textField="name"
                valueField={key === 'groups' ? 'id' : 'value'}
                hideExclusion={false}
                isExcluding={isExcluding}
                onToggleExclusion={() => {
                  handleOnChange(
                    key,
                    value,
                    queryCriteria,
                    history.push,
                    !isExcluding
                  );
                }}
              />
            );
          }

          if (!hasIncidentReportsFeature && key === 'groups') {
            const value = data.filter(({ id }) =>
              (queryCriteria[mapKeys(key)] || []).includes(id)
            );

            return (
              <ExclusionMultiselect
                disabled={data.length <= 1}
                key={key}
                label="Group"
                options={data}
                value={value}
                onChange={values => {
                  handleOnChange(key, values, queryCriteria, history.push);
                }}
                textField="name"
                valueField="id"
                hideExclusion
                hideExclusionSpacing={false}
              />
            );
          }

          if (
            key === 'status' &&
            localStorageHasFlag(FeatureFlags.INCIDENT_REPORTS)
          ) {
            const isExcluding = queryCriteria.excludeFilters?.includes(
              mapKeys(key)
            );
            const value = data.filter(({ value }) => {
              let criteria = queryCriteria[mapKeys(key)] || [];
              if (typeof criteria === 'string') {
                criteria = criteria.split(',');
              }
              return criteria.includes(value);
            });

            return data.length > 1 ? (
              <ExclusionMultiselect
                key={key}
                label="Status"
                options={data}
                value={value}
                valueField="value"
                textField="name"
                onChange={val =>
                  handleOnChange(
                    key,
                    val,
                    queryCriteria,
                    history.push,
                    isExcluding
                  )
                }
                hideExclusion={false}
                isExcluding={isExcluding}
                onToggleExclusion={() => {
                  handleOnChange(
                    key,
                    value,
                    queryCriteria,
                    history.push,
                    !isExcluding
                  );
                }}
              />
            ) : null;
          }

          // Only show adc or medication filter if feature flag is on
          if (
            (key === 'medications' || key === 'adcs') &&
            !hasIncidentReportsFeature
          )
            return null;

          // Status
          const value = hasThisOption(queryCriteria[mapKeys(key)], options) || {
            label: options[0]?.label,
            value: -1,
            name: null,
          };

          return (
            <DropdownList
              key={key}
              className={styles.filterMargin}
              data={data}
              valueField="value"
              textField="name"
              value={value}
              onChange={val =>
                handleOnChange(key, val, queryCriteria, history.push)
              }
              valueComponent={DropdownItem}
            />
          );
        })}
        <ExclusionFreeForm
          selectedValues={commaSeparatedToArray(
            queryCriteria[mapKeys('incidentIds')]
          )}
          label="Incident ID"
          inputKey="incidentIds"
          handleOnChange={handleOnChange}
          queryCriteria={queryCriteria}
          history={history}
        />
      </div>
    </AsidePanel>
  );
};

IncidentAdvancedFilters.displayName = 'IncidentAdvancedFilters';

IncidentAdvancedFilters.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  queryCriteria: PropTypes.shape({
    range: PropTypes.string,
    rangeAfter: PropTypes.string,
    rangeBefore: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  filters: PropTypes.shape({}).isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default IncidentAdvancedFilters;
