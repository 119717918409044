import React from 'react'
import PropTypes from 'prop-types'
import { DateHelpers } from 'common'
import { CASES_BY_OPTIONS } from '../stores/const'
import classnames from 'classnames'

const CaseSummary = ({
  casesBy,
  casesTotal,
  isPrinted,
  reportSettingsDateAfter,
  reportSettingsDateBefore,
  reportSettingsDateRange,
}) => {
  const casesByLabel = CASES_BY_OPTIONS.find(opt => opt.value === casesBy)
    ?.label
  return (
    <section
      className={classnames({
        forPrint: isPrinted,
        forScreen: !isPrinted,
      })}
      data-cy="case-summary--text"
    >
      <strong>{casesTotal} Cases</strong> with {casesByLabel} between{' '}
      {DateHelpers.getDateRangeString(
        reportSettingsDateRange,
        reportSettingsDateAfter,
        reportSettingsDateBefore
      )}
    </section>
  )
}

CaseSummary.propTypes = {
  casesBy: PropTypes.string,
  casesTotal: PropTypes.number,
  isPrinted: PropTypes.bool,
  reportSettingsDateAfter: PropTypes.string,
  reportSettingsDateBefore: PropTypes.string,
  reportSettingsDateRange: PropTypes.string,
}

export default CaseSummary
