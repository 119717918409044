import {
  action,
  comparer,
  computed,
  decorate,
  observable,
  reaction,
  toJS,
} from 'mobx'
import { AppAuditClient, DateHelpers, SingletonStore } from 'common'
import queryString from 'query-string'
import commaSeparatedToArray from '../../../utils/commaSeparatedToArray'
import { defaultOptions } from '../../../incident/stores/IncidentReportFilterStore/const'
import { DEFAULT_DATES } from '../../../incident/IncidentFilterBar/helpers'
import { AuditLogSummaryStore } from '../AuditLogSummaryStore'
import { AuditLogStore } from '../AuditLogStore'
import moment from 'moment'

class AuditLogFilterStore extends SingletonStore {
  constructor({ appAuditClient, auditLogStore }) {
    super()

    this.appAuditClient = appAuditClient
    this.auditLogStore = auditLogStore

    this.summaryStore = new AuditLogSummaryStore({
      appAuditClient,
      auditLogStore,
      auditLogFilterStore: this,
    })

    // re-fetch filters when date range changes
    reaction(
      () => [this.query.range, this.query.rangeBefore, this.query.rangeAfter],
      () => {
        this.refresh()
      },
      {
        equals: comparer.structural,
      }
    )
  }

  // Observables
  query = {}

  // Observes dropdown UI filter options
  dateFilterOptions = DateHelpers.dateOptions // default comes from DateHelpers.timePeriods

  fetch() {
    // TODO
    return []
  }

  // Actions
  /**
   * Sets query parameters in store from URL
   * @param  {Object} newQuery - the Object containing params to be converted
   */
  setQueryFromURL = newQuery => {
    this.query = {
      ...defaultOptions,
      ...newQuery,
    }
  }

  setSelectedDateRange = (selected, historyPush) => {
    if (selected) {
      const newCriteria = { ...this.query }
      const { value } = selected

      // clear out any custom range params since this is just the dropdown
      delete newCriteria.rangeAfter
      delete newCriteria.rangeBefore

      newCriteria.range = value
      this.query = { ...newCriteria }

      historyPush({
        pathname: `/auditLog`,
        search: `?${queryString.stringify(this.query)}`,
      })
    }
  }

  // split this into calendar/date select
  setSelectedCalendarRange = (selected, historyPush, beforeOrAfter) => {
    if (selected) {
      const newCriteria = { ...this.query }
      const { value } = selected

      if (!newCriteria.rangeAfter) {
        newCriteria.rangeAfter = DEFAULT_DATES[0]
      }
      if (!newCriteria.rangeBefore) {
        newCriteria.rangeBefore = DEFAULT_DATES[1]
      }

      const key = beforeOrAfter === 'After' ? 'rangeAfter' : 'rangeBefore'
      newCriteria[key] = value
      this.query = { ...newCriteria }

      historyPush({
        pathname: `/auditLog`,
        search: `?${queryString.stringify(this.query)}`,
      })
    }
  }

  setSelectedPeople = (type, selected, historyPush) => {
    if (selected) {
      const newCriteria = { ...this.query }
      delete newCriteria[type]
      if (type === 'userFullNames') {
        newCriteria[type] = selected.value
      } else {
        newCriteria[type] = selected
          .map(option => option.trim())
          .filter(option => option !== '')
          .join()
        if (newCriteria[type] === '') newCriteria[type] = null
      }
      this.query = { ...newCriteria }
      historyPush({
        pathname: `/auditLog`,
        search: `?${queryString.stringify(this.query)}`,
      })
    }
  }

  // Computeds
  get filters() {
    return this.result || {}
  }

  get isLoading() {
    return this.summaryStore.loading
  }

  get isAdvancedFiltersOpen() {
    return Boolean(this.query.showFilters)
  }

  get selectedUsers() {
    return (
      this.protenusUsers.filter(({ value }) => {
        return (this.query?.userFullNames ?? []).includes(value)
      }) ?? []
    )
  }

  get protenusUsers() {
    return this.summaryStore.users
      .filter(user => {
        const { id } = user
        return id !== 'all'
      })
      .map(user => {
        const { id, fullName } = user
        return {
          value: id,
          label: fullName,
        }
      })
  }

  get selectedEmployees() {
    return commaSeparatedToArray(this.query?.employeeFullNames)
  }

  get selectedPatients() {
    return commaSeparatedToArray(this.query?.patientFullNames)
  }

  get selectedRange() {
    return {
      default: this.query?.range,
      after: this.query?.rangeAfter,
      before: this.query?.rangeBefore,
    }
  }

  get dates() {
    if (this.query.range === 'custom') {
      if (this.selectedRange.after !== undefined) {
        return [
          moment(this.selectedRange.after)
            .utc()
            .startOf('day'),
          moment(this.selectedRange.before).endOf('day'),
        ]
      } else {
        return [moment().subtract(1, 'month'), moment()]
      }
    }
    const dateRange = DateHelpers.rangeForPeriod(this.query.range)
    return dateRange
      ? [
          moment(dateRange[0])
            .utc()
            .startOf('day'),
          moment().endOf('day'),
        ]
      : []
  }

  nextAdvancedFiltersURL = () => {
    // closing advanced filters, remove any user and or patient values that were applied
    if (this.isAdvancedFiltersOpen) {
      if (this.query.range === 'custom') {
        delete this.query.rangeAfter
        delete this.query.rangeBefore
        delete this.query.range
      }

      const currentQuery = { ...this.query }
      delete currentQuery.userFullNames
      delete currentQuery.patientFullNames
      delete currentQuery.showFilters

      return `/auditLog?${queryString.stringify({
        ...currentQuery,
      })}`
    }

    // opening the advanced filters, take the applied filters and move them to the advanced filter panel
    return `/auditLog?${queryString.stringify({
      ...toJS(this.query),
      ...{ showFilters: true },
    })}`
  }
}

decorate(AuditLogFilterStore, {
  // Observables
  query: observable,
  dateFilterOptions: observable,
  userFilterOptions: observable,
  // Actions
  setQueryFromURL: action,
  setSelectedPeople: action,
  setSelectedDateRange: action,
  setSelectedCalendarRange: action,
  // Computeds
  isAdvancedFiltersOpen: computed,
  filters: computed,
  isLoading: computed,
  selectedUsers: computed,
  selectedEmployees: computed,
  selectedPatients: computed,
  selectedRange: computed,
  protenusUsers: computed,
  dates: computed,
})

export default new AuditLogFilterStore({
  appAuditClient: AppAuditClient,
  auditLogStore: AuditLogStore,
})

export { AuditLogFilterStore }
