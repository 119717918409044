import React from 'react';
import { observer } from 'mobx-react';
import { Cell } from 'fixed-data-table-2';

import classnames from 'classnames';

import { PermissionStore } from 'common';
import Checkbox from '../../../ui/Checkbox';

const CreateCasesCell = observer(({ groupActive, data, updateRow }) => {
  const toggleChecked = () => {
    // If we previously had a limit, we will set it to 0 which effectively
    // disables it. Otherwise we set it back to 1.
    updateRow(data, data.limit ? 0 : 1);
  };

  const active = data.limit !== 0;
  const tooltipContent = PermissionStore.has('ALERT_CATEGORY_SAVE')
    ? 'Create Cases'
    : undefined;

  return (
    <Cell
      className={classnames('settings__table-cell', {
        selected: active,
        inactive: !groupActive,
      }
      )}
      data-cy="settings-list-checkbox"
    >
      <Checkbox
        checked={active}
        disabled={!PermissionStore.has('ALERT_CATEGORY_SAVE') || !groupActive}
        onChange={() => toggleChecked()}
        tooltip={tooltipContent}
      />
    </Cell>
  );
});

CreateCasesCell.displayName = 'CreateCasesCell';

export default CreateCasesCell;
