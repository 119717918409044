import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Detail from '../Detail';
import Encounter from '../LoadableEncounter';
import styles from './index.module.scss';

const Access = ({ access, getActivityDescription }) => {
  const {
    activityId,
    advancedSearch,
    encounter,
    environment,
    eventDate,
    instance,
    other,
    search,
    source,
    type,
    workstationId,
  } = access;

  let advancedSearchLabel;
  let advancedSearchValue;
  if (advancedSearch) {
    advancedSearchLabel = `${Object.keys(advancedSearch).join('/')} Search`;
    advancedSearchValue = Object.keys(advancedSearch).map(
      (s, idx, searchArray) => (
        <span key={s} className={styles.advancedSearch}>
          <span>{`${searchArray.length === 1 ? '' : `${s} : `}`}</span>
          <span>{advancedSearch[s]}</span>
        </span>
      )
    );
  }

  let epicAction;
  if (source.startsWith('Epic') && type) epicAction = type;

  let envFromLegacyStr;
  if (source.startsWith('Epic-') && !environment)
    envFromLegacyStr = source.substring(5);

  const sourceEnv = [source, environment].filter(s => s).join('-');

  let description;
  if (activityId === undefined) description = type;
  else
    description =
      getActivityDescription(activityId) ||
      `Could not find definition for activity ${activityId}`;

  return (
    <ul className="list-unstyled">
      <Detail
        label={eventDate.format('dddd, MMMM D, YYYY h:mm:ss A')}
        value={description}
        source={sourceEnv}
        instance={instance}
      />
      <Detail label="Instance" value={instance} />
      <Detail label="Search Text" value={search} />
      {advancedSearch && (
        <Detail label={advancedSearchLabel} value={advancedSearchValue} />
      )}
      {encounter && <Encounter data={encounter} />}
      <Detail label="Workstation" value={workstationId} />
      <Detail label="Epic Action" value={epicAction} />
      {source === 'Epic' && activityId && (
        <Detail label="Epic Metric ID" value={activityId} />
      )}
      <Detail label="Environment" value={environment || envFromLegacyStr} />
      {other &&
        Object.entries(other).map(entry => (
          <Detail key={entry[0]} label={entry[0]} value={entry[1]} />
        ))}
    </ul>
  );
};

Access.propTypes = {
  access: PropTypes.shape({
    activityId: PropTypes.number,
    advancedSearch: PropTypes.shape({}),
    encounter: PropTypes.shape({}),
    environment: PropTypes.string,
    eventDate: PropTypes.instanceOf(moment).isRequired,
    instance: PropTypes.string,
    other: PropTypes.shape({}),
    search: PropTypes.string,
    source: PropTypes.string,
    type: PropTypes.string,
    workstationId: PropTypes.string,
  }).isRequired,
  getActivityDescription: PropTypes.func.isRequired,
};

export default Access;
