import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import classnames from 'classnames'
import { Authenticated } from 'common'
import SearchForm from '../../../ui/registry/RegistrySearch'
import AssignForm from '../AssignForm'
import UserCardContainer from '../../../people/cards/UserCardContainer'

const AssignView = ({
  assignStore,
  isLoading,
  employeeId,
  pathname,
  reset,
  resetSearchStore,
  selectedUserId,
  setType,
}) => {
  useEffect(() => {
    setType('users')
    if (employeeId) {
      assignStore.setUser(employeeId)
    }
  }, [assignStore, employeeId, setType])

  const makeSelection = () => {
    if (selectedUserId) {
      assignStore.setUser(selectedUserId)
    }

    resetSearchStore()
  }

  const collection = []
  if (assignStore.user) {
    collection.push(assignStore.user)
  }

  const nextLink = () => {
    if (selectedUserId) {
      return `${pathname}/employee/${selectedUserId}`
    }
    return pathname
  }

  const renderAddButton = () => (
    <Link
      to={nextLink()}
      onClick={makeSelection}
      type="button"
      className="button"
      disabled={!selectedUserId}
    >
      Select a User
    </Link>
  )

  const renderCard = (data, idx) => (
    <article
      className="card_holder full_width margin_collapse"
      key={`selected_card_${idx + 1}`}
    >
      {data.userId && <UserCardContainer user={data} openLinkNew />}
    </article>
  )

  return (
    <Authenticated permission="PERSON_TAG_ASSIGN">
      <div className="view-content">
        <div className="row">
          <div className="col-xs-10 col-xs-offset-1">
            <section
              className={classnames('view-content__body registry', {
                hidden: isLoading,
              })}
            >
              <header className="page-header">
                <h2>Manage a User&apos;s Tags</h2>
                <p
                  className={classnames({
                    hidden: collection.length > 1,
                  })}
                >
                  Add an EMR User to manage his/her tags.
                </p>
              </header>
              <div
                className={classnames({
                  hidden: !collection.length,
                })}
              >
                {collection.length > 0 &&
                  collection.map((i, idx) => renderCard(i, idx))}
              </div>
              <div
                className={classnames({
                  hidden: collection.length > 0,
                })}
              >
                <SearchForm sourceStore={assignStore} />
                <ul className="registry__search-actions list-inline">
                  <li>{renderAddButton()}</li>
                  <li>
                    <Link
                      to="/user-tags"
                      onClick={reset}
                      className="button button--link"
                    >
                      Cancel
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                className={classnames({
                  hidden: collection.length < 1,
                })}
              >
                <AssignForm store={assignStore} reset={reset} />
              </div>
            </section>
          </div>
        </div>
      </div>
    </Authenticated>
  )
}

AssignView.propTypes = {
  assignStore: PropTypes.shape({
    user: PropTypes.shape({}),
    reset: PropTypes.func,
    setUser: PropTypes.func,
    isLoading: PropTypes.bool,
  }),
  reset: PropTypes.func,
  resetSearchStore: PropTypes.func,
  setType: PropTypes.func,
  searchStore: PropTypes.shape({
    clear: PropTypes.func,
    selectedPatient: PropTypes.shape({}),
    criteria: PropTypes.string,
    setCriteria: PropTypes.func,
    loading: PropTypes.bool,
  }),
  pathname: PropTypes.string,
  employeeId: PropTypes.string,
  selectedUserId: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default AssignView
