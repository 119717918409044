import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import $ from 'jquery'

import PrintInfo from './PrintInfo'

import ChartNote from '../ChartNote'
import Tooltip from '../../ui/Tooltip'
import { renderTrend, formatStat } from './utils'

const NOTE_KEY = 'ByTheNumbers'

const ByTheNumbers = ({
  allGroups,
  currentStatement,
  globalPHCStats,
  groupPHCStats,
  noGroupPHCStats,
  subtitle,
  note,
  prevStatement,
  saveNote,
  removeNote,
  prevStartDate,
  prevEndDate,
  onNoteEdit,
}) => {
  const [addingNote, setAddingNote] = useState(false)

  const onNoteClick = () => {
    setAddingNote(true)
    scroll()
  }

  const reset = () => setAddingNote(false)

  const scroll = () => {
    $('.statements_view__body section').animate(
      {
        scrollTop: $('#by_the_numbers__wrapper').height() - 200,
      },
      700
    )
  }

  const renderTimeFrame = () => {
    const time = currentStatement

    if (time) {
      return (
        <p className="times">
          {' '}
          {moment(time.startDate)
            .utc()
            .format('LL')}{' '}
          -{' '}
          {moment(time.endDate)
            .utc()
            .subtract(1, 'day')
            .format('LL')}
        </p>
      )
    }
    return null
  }

  const renderPHCStat = stat => {
    return (
      <li key={stat.title}>
        {renderTrend(stat.data.curr, stat.data.prev, prevStatement)}
        <div className="title">{stat.title}</div>
        <span className="stat">{formatStat(stat)}</span>
      </li>
    )
  }

  const renderNote = () => {
    if (addingNote || note) {
      return (
        <ChartNote
          onRemove={() => {
            removeNote(NOTE_KEY)
            reset()
          }}
          onSave={note => saveNote(note)}
          note={note}
          onReset={reset}
          onNoteEdit={dirty => onNoteEdit(NOTE_KEY, dirty)}
        />
      )
    }
  }

  return (
    <div id="by_the_numbers__wrapper">
      <div className="by_the_numbers-header">
        <h1> By the Numbers </h1>
        {renderTimeFrame()}
      </div>
      <div className="numbers">
        <ul>
          <li>
            <Tooltip content="Add Note" placement="left">
              <span onClick={onNoteClick}>
                <i
                  className="material-icons icon-note_add prot-a"
                  data-cy="scroll-to-add-note"
                />
              </span>
            </Tooltip>
            <h2 className="by_the_numbers-sub-header">
              For Entire Organization
            </h2>
          </li>
        </ul>
      </div>
      <ul className="list-unstyled stats">
        {globalPHCStats.map(el => renderPHCStat(el))}
        {/* Hide the second header if they have all the possible groups applied or if they have no groups */}
        <li className={classnames({ hidden: allGroups })}>
          <h2 className="by_the_numbers-sub-header">{subtitle}</h2>
        </li>
        {/* groupPHCStats are all the stats that are dependent on the applied group */}
        {groupPHCStats.map(el => renderPHCStat(el))}
        {/* noGroupPHCStats is the stats that can only be computed right if all the possible groups are applied or there are no groups */}
        {allGroups ? noGroupPHCStats.map(el => renderPHCStat(el)) : null}
      </ul>
      <PrintInfo
        groupStatus={allGroups}
        subtitle={subtitle}
        groupPHCStats={groupPHCStats}
        noGroupPHCStats={noGroupPHCStats}
        globalPHCStats={globalPHCStats}
        prevStatement={prevStatement}
        prevStartDate={prevStartDate}
        prevEndDate={prevEndDate}
      />
      {renderNote()}
    </div>
  )
}

ByTheNumbers.propTypes = {
  groupPHCStats: PropTypes.array,
  noGroupPHCStats: PropTypes.array,
  globalPHCStats: PropTypes.array,
  allGroups: PropTypes.bool,
  onNoteEdit: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  note: PropTypes.shape({}),
  currentStatement: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  saveNote: PropTypes.func,
  prevStatement: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  prevStartDate: PropTypes.string,
  prevEndDate: PropTypes.string,
  removeNote: PropTypes.func.isRequired,
  paramsId: PropTypes.string,
}

ByTheNumbers.defaultProps = {
  allGroups: false,
  subtitle: '',
}

export default ByTheNumbers
