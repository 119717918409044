import React, { useEffect } from 'react'
import MultiRecordPersonHeader from '../../people/PersonBox/MultiRecordPersonHeader'
import PropTypes from 'prop-types'
import { generateMRVPersonLink } from '../utils/LinkUtils'

const MultiRecordPersonHeaderSection = ({
  personType,
  personHeaders,
  match,
  setCurrentMatch,
  userLink,
  patientLink,
  loading,
}) => {
  useEffect(() => {
    if (match) setCurrentMatch(match)
  }, [match, setCurrentMatch])

  let personHeader
  let personLink
  let personSwitchLink

  personHeader = personHeaders.find(
    personHeader => personHeader.personType === personType
  )

  // this personLink is generated in container and varies
  // depending on view (Case vs. Activity)
  personLink = personType === 'USER' && personHeader ? userLink : patientLink

  if (!personHeader) {
    personHeader = {}
    personLink = '/'
  } else {
    const generatedLinks = generateMRVPersonLink(
      personType,
      personHeader.personId,
      personHeader.linkId
    )
    personSwitchLink = generatedLinks.personSwitchLink
  }

  let component
  if (!loading)
    component = (
      <>
        <MultiRecordPersonHeader
          personType={personType}
          person={personHeader}
          personLink={personLink}
          personSwitchLink={personSwitchLink}
        />
      </>
    )

  return <div>{component}</div>
}

MultiRecordPersonHeaderSection.propTypes = {
  personType: PropTypes.string.isRequired,
  personHeaders: PropTypes.array,
  match: PropTypes.object,
  setCurrentMatch: PropTypes.func,
  userLink: PropTypes.string,
  patientLink: PropTypes.string,
  loading: PropTypes.bool,
}

export default MultiRecordPersonHeaderSection
