import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Anchor, MultilineInput, TextFormatter } from '../../ui'
import styles from './index.module.scss'
import classnames from 'classnames'

const IncidentNote = ({
  note,
  title,
  isEditing,
  onDirtied,
  onSave,
  onCancel,
  onDelete,
}) => {
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)
  const [editedNoteValue, setEditedNoteValue] = useState('')
  const [hasMarkedDirty, setHasMarkedDirty] = useState(false)

  const onKeyDown = handler => ({ key }) =>
    (key === ' ' || key === 'Enter') && handler()
  const canDelete = !isEditing && note.created
  const trySave = text => (text || '').trim() && onSave(text)

  return (
    <div className={classnames(styles.note, { [styles.editing]: isEditing })}>
      {isEditing ? (
        <div className={styles.notesBodyEditing}>
          <MultilineInput
            placeholder="Add a Note"
            value={editedNoteValue}
            onChange={value => {
              setEditedNoteValue(value)
              if (value.trim().length > 0 && !hasMarkedDirty) {
                onDirtied()
                setHasMarkedDirty(true)
              }
            }}
          />
        </div>
      ) : (
        <div className={styles.noteBody} data-cy="incident-note-body">
          <TextFormatter text={note.notes} />
        </div>
      )}
      <div className={styles.noteHeading}>
        <div>
          <strong>{title || note.createdBy.fullName}</strong>
          {note.created && (
            <span>{moment(note.created).format('MMMM D, YYYY h:mm A')}</span>
          )}
        </div>
        {canDelete && !isConfirmingDelete && (
          <div className={styles.noteActions} data-cy="incident-note-actions">
            <Anchor
              tag={'span'}
              role="button"
              tabIndex="0"
              onClick={() => setIsConfirmingDelete(true)}
              onKeyDown={() => setIsConfirmingDelete(true)}
            >
              Delete
            </Anchor>
          </div>
        )}
        {canDelete && isConfirmingDelete && (
          <div className={styles.noteActions} data-cy="incident-note-actions">
            <Anchor
              tag={'span'}
              role="button"
              tabIndex="0"
              className={styles.confirmDelete}
              onClick={onDelete}
              onKeyDown={onKeyDown(onDelete)}
            >
              Confirm Delete
            </Anchor>
            <Anchor
              tag={'span'}
              role="button"
              tabIndex="0"
              onClick={() => setIsConfirmingDelete(false)}
              onKeyDown={() => setIsConfirmingDelete(false)}
            >
              Cancel
            </Anchor>
          </div>
        )}
        {isEditing && (
          <div className={styles.noteActions} data-cy="incident-note-actions">
            <Anchor
              tag={'span'}
              role="button"
              tabIndex="0"
              className={styles.confirmDelete}
              onClick={onCancel}
              onKeyDown={onKeyDown(onCancel)}
            >
              Cancel
            </Anchor>
            <Anchor
              tag={'span'}
              role="button"
              tabIndex="0"
              onClick={() => trySave(editedNoteValue)}
              onKeyDown={onKeyDown(() => trySave(editedNoteValue))}
            >
              Save
            </Anchor>
          </div>
        )}
      </div>
    </div>
  )
}

IncidentNote.propTypes = {
  note: PropTypes.shape({
    createdBy: PropTypes.shape({ fullName: PropTypes.string }),
    created: PropTypes.string,
    notes: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
  onDirtied: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

IncidentNote.defaultProps = {
  title: null,
}

export default IncidentNote
