import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { PermissionStore } from 'common'

import CustomDropdown from '../CustomDropdown'
import styles from './index.module.scss'

const CaseExportMenu = observer(
  ({ iTip, exportCb, eventTypes, cb, menuDisabled }) => {
    const eventRows = eventTypes.map(e => {
      const onClick = e.active
        ? () => {
            exportCb(e.name)
          }
        : null
      return (
        <li key={e.name} className={e.active ? undefined : styles.disabled}>
          <span onClick={onClick} role="button">
            {e.name}
          </span>
        </li>
      )
    })

    return (
      <CustomDropdown
        wrapperClass={styles.export}
        buttonClass={styles.button}
        iClass="material-icons icon-get_app"
        iTip={iTip}
        cb={cb}
        iPlace="left"
        menuDisabled={
          !PermissionStore.has('MEDICATION_ADMINISTRATION_VIEW') || menuDisabled
        }
        data-cy="case-export-menu"
      >
        <ul>{eventRows}</ul>
      </CustomDropdown>
    )
  }
)

CaseExportMenu.propTypes = {
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
    })
  ),
  buttonClass: PropTypes.string,
  iTip: PropTypes.string,
  exportCb: PropTypes.func,
  cb: PropTypes.func,
  menuDisabled: PropTypes.bool,
}

CaseExportMenu.defaultProps = {
  menuDisabled: false,
}

CaseExportMenu.displayName = 'NewExportMenu'

export default CaseExportMenu
