import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CaseActivity from '../CaseActivity'
import CaseReports from '../CaseReports'
import CaseReportsOpen from '../CaseReportsOpen'
import { ApplyBodyClassName, DocumentTitle } from '../../ui'
import { GroupQuickFilterContainer } from '../../ui/GroupQuickFilterWithGroupStore'

const HomeView = ({
  groupNames,
  activeUserCheck,
  setCaseActivityFilters,
  setOpenCaseFilters,
  setCaseReportCountFilters,
  setCaseAgesFilters,
  query,
}) => {
  useEffect(() => {
    const setUserFromLocalStorage = localStorageValue => {
      const user = localStorage.getItem(localStorageValue)
      if (!user) return 'all'
      return activeUserCheck(user) || 'all'
    }

    const newQuery = Object.assign(query, {
      activityType: localStorage.getItem('activityType') || 'all',
      activityUser: setUserFromLocalStorage('activityUser'),
      newCasesRange: localStorage.getItem('newCasesRange') || 'week',
      newOwner: setUserFromLocalStorage('newOwner'),
      openCasesOwner: setUserFromLocalStorage('openCasesOwner'),
      caseAgesOwner: setUserFromLocalStorage('caseAgesOwner'),
      closedOwner: setUserFromLocalStorage('closedOwner'),
    })

    setOpenCaseFilters(newQuery)
    setCaseActivityFilters(newQuery)
    setCaseReportCountFilters(newQuery)
    setCaseAgesFilters(newQuery)
  }, [
    query,
    groupNames,
    activeUserCheck,
    setOpenCaseFilters,
    setCaseActivityFilters,
    setCaseAgesFilters,
    setCaseReportCountFilters,
  ])

  return (
    <main className="home-view__body">
      <DocumentTitle text="Protenus" />
      <ApplyBodyClassName className="home-view" />
      <GroupQuickFilterContainer />
      <section className="home-section col-1 dark home__cases-open">
        <CaseReports />
        <CaseReportsOpen />
      </section>
      <CaseActivity />
    </main>
  )
}

HomeView.propTypes = {
  groupNames: PropTypes.array,
  setOpenCaseFilters: PropTypes.func,
  setCaseActivityFilters: PropTypes.func,
  setCaseReportCountFilters: PropTypes.func,
  setCaseAgesFilters: PropTypes.func,
  activeUserCheck: PropTypes.func,
  query: PropTypes.shape({}),
}

HomeView.displayName = 'HomeView'

export default HomeView
