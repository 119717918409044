import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { decorate, observable } from 'mobx'
import { Column, Cell } from 'fixed-data-table-2'

import Registry from '../../../ui/registry/Registry'

import PersonNameCell from '../../PersonNameCell'
import DateCell from '../../DateCell'

const UsersList = observer(
  class UsersList extends React.Component {
    static propTypes = {
      baseUrl: PropTypes.string.isRequired,
      rowHeight: PropTypes.number.isRequired,
      store: PropTypes.object.isRequired,
    }

    // Observable
    width = 1

    componentDidMount() {
      window.addEventListener('resize', this.onResize)
      this.onResize()
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResize)
    }

    onResize = () => {
      this.width = this.node.offsetWidth
    }

    setCellWidth(percentage) {
      percentage /= 100
      return this.width * percentage
    }

    render() {
      const { baseUrl, store, ...other } = this.props,
        data = store.results

      return (
        <Registry
          ref={node => (this.node = node)}
          dataCount={data.length}
          width={this.width}
          {...other}
        >
          <Column
            header={<Cell>EMR User</Cell>}
            width={this.setCellWidth(34)}
            cellClassName="no-wrap"
            data={data}
            cell={({ rowIndex }) => {
              const user = data[rowIndex].user,
                isActive = user && user.id === store.activeUserId

              return (
                <PersonNameCell
                  lastNameFirst
                  baseUrl={baseUrl}
                  data={user}
                  person={user}
                  isActive={isActive}
                />
              )
            }}
          />
          <Column
            header={<Cell>Tags Last Modified</Cell>}
            width={this.setCellWidth(33)}
            cellClassName="no-wrap"
            data={data}
            cell={({ rowIndex }) => {
              const user = data[rowIndex].user,
                modified = data[rowIndex].lastModified,
                isActive = user && user.id === store.activeUserId

              return (
                <DateCell
                  baseUrl={baseUrl}
                  data={user}
                  date={modified}
                  isActive={isActive}
                />
              )
            }}
          />
          <Column
            header={<Cell>Tags Last Modified By</Cell>}
            width={this.setCellWidth(33)}
            cellClassName="no-wrap"
            data={data}
            cell={({ rowIndex }) => {
              const user = data[rowIndex].user,
                modifier = data[rowIndex].lastModifiedBy,
                isActive = user && user.id === store.activeUserId

              return (
                <PersonNameCell
                  baseUrl={baseUrl}
                  data={user}
                  person={modifier}
                  isActive={isActive}
                />
              )
            }}
          />
        </Registry>
      )
    }
  }
)

decorate(UsersList, {
  width: observable,
})

UsersList.displayName = 'UsersList'

export default UsersList
