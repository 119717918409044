import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import PeopleByTagListView from '../../PeopleByTagListView';
import UsersList from '../UsersList';
import PersonTagView from '../../PersonTagView';
import { DocumentTitle } from '../../../ui';
import AsideHelpers from '../../../ui/AsidePanel/AsideHelpers';

const BASE_URL = '/user-tags',
  SECTION_TITLE = 'User Tags';

const UsersByTagListView = ({
  addBanner,
  activeTag,
  activeUserId,
  count,
  caseId,
  hideTable,
  store,
  userTagsStore,
  employeeStoreFetch,
  pathname,
  tagParamsId,
  userParamsId,
  tagId,
  // eslint-disable-next-line no-unused-vars
  userId,
  failure,
  setValue,
  setActiveUserTagId,
  setEmployeeStoreId,
}) => {
  const viewPath = new RegExp(/\/user-tags\/\w+\/users\/\w+/);
  const createPath = `${BASE_URL}/assign`;
  const editPath = new RegExp(/\/users\/\w+/);

  useEffect(() => {
    if (failure) {
      addBanner(failure, 'danger');
    }
  }, [addBanner, failure]);

  useEffect(() => {
    if (tagParamsId) {
      if (tagParamsId !== tagId) {
        setActiveUserTagId(tagParamsId);
        setValue('tagId', tagParamsId);
      }
    }
    if (userParamsId) {
      if (activeUserId !== userParamsId) {
        setValue('activeUserId', userParamsId);
        setEmployeeStoreId(userParamsId);
        employeeStoreFetch(userParamsId);
      }
    }
  }, [
    employeeStoreFetch,
    setValue,
    tagParamsId,
    activeUserId,
    tagId,
    userParamsId,
    setActiveUserTagId,
    setEmployeeStoreId,
  ]);

  useEffect(() => {
    pathname.match(editPath)
      ? AsideHelpers.openPanel()
      : AsideHelpers.closePanel();
  }, [editPath, pathname]);

  const pageTitle = () => {
    const sectionLink = (
      <Link to={`${BASE_URL}/${tagId}`}>{SECTION_TITLE}</Link>
    );

    if (activeTag) {
      return (
        <span>
          {sectionLink}
          <i className="breadcrumbs__separator material-icons icon-keyboard_arrow_right" />
          {activeTag.name}
        </span>
      );
    } else {
      return sectionLink;
    }
  };

  const detailsView = (
    <PersonTagView
      store={store}
      tagsStore={userTagsStore}
      cases={count}
      caseId={caseId}
      panelTitle="User Tag Detail"
      panelLinkTo={`${BASE_URL}/${tagId}/users`}
      redirectTo={`/user-tags/${activeTag.id}/users`}
      panelHeading={'EMR User'}
      personLink={`/activity/employee/${activeUserId}`}
    />
  );

  return (
    <>
      <DocumentTitle
        text={
          activeTag ? `${SECTION_TITLE} > ${activeTag.name}` : SECTION_TITLE
        }
      />
      <PeopleByTagListView
        tag={activeTag}
        baseUrl={BASE_URL}
        peopleStore={store}
        tagsStore={userTagsStore}
        viewPath={viewPath}
        detailsView={detailsView}
        createPath={createPath}
        sectionTitle={SECTION_TITLE}
        pageTitle={pageTitle()}
      >
        <UsersList
          rowHeight={50}
          store={store}
          className={classnames({
            hidden: hideTable,
          })}
          label="USERS BY TAG"
          baseUrl={`/user-tags/${activeTag.id}/users`}
        />
      </PeopleByTagListView>
    </>
  );
};

UsersByTagListView.propTypes = {
  addBanner: PropTypes.func,
  activeTag: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  activeUserId: PropTypes.string,
  count: PropTypes.number,
  caseId: PropTypes.string,
  hideTable: PropTypes.bool,
  store: PropTypes.shape({}),
  userTagsStore: PropTypes.shape({}),
  setEmployeeStoreId: PropTypes.func,
  employeeStoreFetch: PropTypes.func,
  pathname: PropTypes.string,
  tagParamsId: PropTypes.string,
  userParamsId: PropTypes.string,
  tagId: PropTypes.string,
  userId: PropTypes.string,
  failure: PropTypes.string,
  setValue: PropTypes.func,
  setActiveUserTagId: PropTypes.func,
};

UsersByTagListView.defaultProps = {
  activeTag: {},
};

export default UsersByTagListView;
