import React, { useEffect } from 'react'
import TopNavBar from '../../../navbar/TopNavBar'
import VisibleSystemQuickFilter from '../../VisibleSystemQuickFilter'
import PropTypes from 'prop-types'
import {
  systemPropType,
  quickFilterDataPropType,
} from '../../VisibleSystemQuickFilter/propTypes'
import { convertMultiValue } from 'common'

const TopNav = ({
  quickFilterOptions,
  allSelected,
  savedQuickFilterOptions = [],
  hasMultipleSystems,
  handleUpdateQuickFilterOptions,
  setQuickFilterOptionsFromQueryParams,
  handleSetSavedQuickFilterOptions,
  query = {},
  pathname,
  historyReplace,
  useTopNav,
  showRememberFilterButton,
  sources,
  isCaseView,
  sourceIds,
  isUserDetailsView,
  hasSystemParams,
}) => {
  // Converts query.system to string so we can use as dependency
  const querySystemParamsString = query?.system?.toString()
  const querySystemParams = query?.system
  const sourcesLength = sources?.length

  useEffect(() => {
    if (savedQuickFilterOptions.length && !hasSystemParams && !isCaseView) {
      handleUpdateQuickFilterOptions(
        null,
        'savedOptions',
        { ...query, system: savedQuickFilterOptions },
        pathname,
        historyReplace
      )
    }
  }, [
    pathname,
    historyReplace,
    savedQuickFilterOptions,
    handleUpdateQuickFilterOptions,
    query,
    isCaseView,
    hasSystemParams,
  ])

  useEffect(() => {
    if (isUserDetailsView && !querySystemParams) {
      handleUpdateQuickFilterOptions(
        null,
        'isCaseView',
        { system: sourceIds },
        pathname,
        historyReplace
      )
    }
  }, [
    handleUpdateQuickFilterOptions,
    historyReplace,
    isUserDetailsView,
    pathname,
    querySystemParams,
    sourceIds,
  ])

  useEffect(() => {
    if (querySystemParams && !isCaseView) {
      setQuickFilterOptionsFromQueryParams(querySystemParamsString)
    }
  }, [
    setQuickFilterOptionsFromQueryParams,
    querySystemParams,
    querySystemParamsString,
    isCaseView,
    sourcesLength,
  ])

  // hide if there's only one system or advanced filter is open
  if (!hasMultipleSystems || query.showFilters === 'true') return null

  const filter = (
    <VisibleSystemQuickFilter
      data={quickFilterOptions}
      handleCheckboxOnChange={id =>
        handleUpdateQuickFilterOptions(
          id,
          'checkbox',
          query,
          pathname,
          historyReplace
        )
      }
      handleSetThisSystemOnly={id =>
        handleUpdateQuickFilterOptions(
          id,
          'systemOnly',
          query,
          pathname,
          historyReplace
        )
      }
      handleSetLocalStorage={() =>
        handleSetSavedQuickFilterOptions(convertMultiValue(query.system) || [])
      }
      showRememberFilterButton={showRememberFilterButton}
      allSelected={allSelected}
      isCaseView={isCaseView}
    />
  )

  if (useTopNav) return <TopNavBar secondary>{filter}</TopNavBar>
  else return filter
}

TopNav.propTypes = {
  quickFilterOptions: quickFilterDataPropType,
  savedQuickFilterOptions: systemPropType,
  handleUpdateQuickFilterOptions: PropTypes.func.isRequired,
  hasMultipleSystems: PropTypes.bool.isRequired,
  setQuickFilterOptionsFromQueryParams: PropTypes.func.isRequired,
  handleSetSavedQuickFilterOptions: PropTypes.func,
  query: PropTypes.shape({
    system: systemPropType,
    showFilters: PropTypes.string,
  }),
  pathname: PropTypes.string,
  historyReplace: PropTypes.func.isRequired,
  useTopNav: PropTypes.bool,
  showRememberFilterButton: PropTypes.bool,
  allSelected: PropTypes.bool,
  isCaseView: PropTypes.bool,
  isActivityView: PropTypes.bool,
  isUserDetailsView: PropTypes.bool,
}

export default TopNav
