import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import AnimateHeight from 'react-animate-height'
import IncidentNote from './IncidentNote'
import styles from './index.module.scss'

const IncidentNotes = React.memo(
  ({
    id,
    notes,
    isAddingNote,
    onDirtied,
    onAddNote,
    onCancelAddNote,
    onDeleteNote,
    isExpanded,
    setIsExpanded,
  }) => {
    const contentHeight = isExpanded || isAddingNote ? 'auto' : 0

    const addingIncidentNote = useMemo(
      () => (
        <IncidentNote
          title=" "
          note={{ notes: '' }}
          onDirtied={onDirtied}
          isEditing
          onSave={text => {
            setIsExpanded(true)
            onAddNote(id, text)
          }}
          onCancel={onCancelAddNote}
          onDelete={() => null}
        />
      ),
      [id, onCancelAddNote, onAddNote, onDirtied, setIsExpanded]
    )

    return (
      <div className={styles.notes} data-cy="incident-note-container">
        <AnimateHeight
          height={contentHeight}
          duration={250}
          contentClassName={styles.noteContainer}
          animationStateClasses={{
            staticHeightZero: styles.noteContainerHeightZero,
          }}
        >
          {isAddingNote && addingIncidentNote}
          {notes.map((note, index) => (
            <IncidentNote
              key={note.created}
              note={note}
              isEditing={false}
              onDirtied={() => null}
              onSave={() => null}
              onCancel={() => null}
              onDelete={() => {
                onDeleteNote(id, index)
                onCancelAddNote(id, null)
              }}
            />
          ))}
        </AnimateHeight>
      </div>
    )
  }
)

IncidentNotes.displayName = 'IncidentsNotes'

IncidentNotes.propTypes = {
  id: PropTypes.string.isRequired,
  notes: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      createdBy: PropTypes.shape({ fullName: PropTypes.string }),
      created: PropTypes.string,
      notes: PropTypes.string,
    })
  ),
  isAddingNote: PropTypes.bool,
  onDirtied: PropTypes.func.isRequired,
  onAddNote: PropTypes.func.isRequired,
  onCancelAddNote: PropTypes.func.isRequired,
  onDeleteNote: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
}

IncidentNotes.defaultProps = {
  notes: [],
  isAddingNote: false,
}

export default IncidentNotes
