import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { autorun, computed, decorate, observable } from 'mobx'
import { Link } from 'react-router-dom'
import { Multiselect } from 'react-widgets'

import { RouterContainer } from 'common'

import Button from '../../../ui/Button'

import NotificationStore from '../../../stores/NotificationStore'

import PersonTagsCountStore from '../../stores/PersonTagsCountStore'

import UsersByTagStore from '../../users/stores/UsersByTagStore'
import UserTagInfoStore from '../../users/stores/UserTagInfoStore'
import AssignStore from '../stores/AssignStore'
import Store from '../../../activity/stores/Store'

const AssignForm = observer(
  class AssignForm extends React.Component {
    static propTypes = {
      reset: PropTypes.func,
    }

    static defaultProps = {
      reset: () => {},
    }

    // Observable
    tags = []
    open = false

    // Computed
    get dirty() {
      if (AssignStore.user) {
        const existingLength = AssignStore.user.tags
          ? AssignStore.user.tags.length
          : 0
        if (existingLength === this.tags.length) {
          for (let i = 0; i < this.tags.length; i++) {
            if (AssignStore.user.tags[i].id !== this.tags[i].id) {
              return true
            }
          }

          return false
        }
        return true
      }
      return false
    }

    componentDidMount() {
      this.componentWillUnmount = autorun(this.load.bind(this))
    }

    onSubmit(e) {
      e.preventDefault()
      const form = {
        tags: this.tags.slice(),
        user: AssignStore.user.id,
      }

      AssignStore.assign(
        UserTagInfoStore.tagInfoByUserId.get(AssignStore.user.id),
        form
      ).then(
        () => {
          PersonTagsCountStore.refresh()
          UsersByTagStore.refresh()
          Store.refresh()
          const content = (
            <span>
              <i className="material-icons icon-check_circle" />
              Successfully updated user tags
            </span>
          )
          NotificationStore.add({ level: 'success', content })
          RouterContainer.go('/user-tags')
        },
        () => {
          const content = (
            <span>
              <i className="material-icons icon-warning" />
              There was a problem updating this user&apos;s tags.
            </span>
          )
          NotificationStore.add({ level: 'warning', content })
        }
      )
    }

    onChange = tags => {
      this.tags = tags
    }

    reset() {
      if (this.props.reset) this.props.reset()
    }

    load() {
      this.tags = (AssignStore.user && AssignStore.user.tags) || []

      if (AssignStore.user) UserTagInfoStore.fetch(AssignStore.user.id)
    }

    render() {
      return (
        <form
          className="record__assign-form record__form"
          onSubmit={this.onSubmit.bind(this)}
        >
          <ul className="row no-gutter">
            <li className="input-fullwidth">
              <Multiselect
                placeholder="Tags"
                data={AssignStore.availableTags}
                value={this.tags.slice()}
                valueField="id"
                textField="name"
                onChange={this.onChange}
                onFocus={() => (this.open = true)}
                onBlur={() => (this.open = false)}
                onKeyDown={e =>
                  (this.open = e.keyCode !== 27 && e.keyCode !== 8)
                }
                onClick={e => (this.open = e.target.tagName !== 'LI')}
                onSelect={() => (this.open = false)}
                open={this.open}
                onToggle={() => {}}
                data-cy="user-tag-add-tag"
              />
            </li>
            <li className="form__actions">
              <Button
                className="assign-form__submit"
                type="submit"
                value="Submit"
                disabled={!this.dirty}
                data-cy="user-tag-submit-add"
              />
              <Link
                to="/user-tags"
                onClick={this.reset.bind(this)}
                className="button button--link"
              >
                Cancel
              </Link>
            </li>
          </ul>
        </form>
      )
    }
  }
)

decorate(AssignForm, {
  tags: observable,
  open: observable,
  dirty: computed,
})

AssignForm.displayName = 'AssignForm'

export default AssignForm
