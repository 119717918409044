import { PagedStore } from 'common';
import { action, computed, decorate, observable, reaction } from 'mobx';
import { 
  DEFAULT_DELIVERY_RANGE,
  DEFAULT_DELIVERY_BEFORE,
  DEFAULT_DELIVERY_AFTER,
  DEFAULT_DELIVERY_AFTER_DATE,
  DEFAULT_DELIVERY_BEFORE_DATE,
  DELIVERY_AFTER_STORAGE_KEY,
  DELIVERY_BEFORE_STORAGE_KEY,
  calculateDate,
  processRegexParam
} from './helpers';

const PAGE_SIZE = 30;
const DEFAULT_NAME = '';
const DEFAULT_SORT = 'created,desc';
const DEFAULT_PROCESSING_STATE = 'Any';
const DEFAULT_PATH = 'Any';
const DEFAULT_SOURCE = '';

const processingStateToQuery = {
  Failed: { completed: true, error: true, ignored: false },
  Processed: { completed: true, error: false, results: true, ignored: false },
  Pending: { completed: false, ignored: false },
  Unprocessed: {
    completed: true,
    error: false,
    results: false,
    ignored: false,
  },
  Any: undefined,
};

const createdAfterMap = {
  day: 'P-1D',
  week: DEFAULT_DELIVERY_AFTER,
  month: 'P-30D',
  ninetyDays: 'P-90D',
  year: 'P-365D',
};

const parseSort = sort => {
  const splits = sort.split(',');
  return {
    sortBy: splits[0],
    sortDir: splits[1],
  };
};

export const DEFAULT_QUERY = {
  deliveryRange: DEFAULT_DELIVERY_RANGE,
  processingState: DEFAULT_PROCESSING_STATE,
  size: PAGE_SIZE,
  sort: DEFAULT_SORT,
  path: DEFAULT_PATH,
  source: DEFAULT_SOURCE,
};

/**
 * Houses a file delivery report serving data containing
 * array of individual file statuses
 *
 */
class DeliveryStore extends PagedStore {
  constructor({ dataFeedClient }) {
    super();

    this.dataFeedClient = dataFeedClient;

    this.disposers = [];

    reaction(
      () => JSON.stringify(this.fetchParams),
      () => {
        this.refresh();
      }
    );
  }

  deliveryRange = DEFAULT_DELIVERY_RANGE;
  deliveryBefore = DEFAULT_DELIVERY_AFTER_DATE;
  deliveryAfter = DEFAULT_DELIVERY_BEFORE_DATE;
  computedCreatedAfter;
  computedCreatedBefore;
  name = DEFAULT_NAME;
  processingState = DEFAULT_PROCESSING_STATE;
  size = PAGE_SIZE;
  sort = DEFAULT_SORT;
  path = DEFAULT_PATH;
  source = DEFAULT_SOURCE;

  tearDown = () => {
    this.disposers.forEach(d => d());
  };

  // Action
  setQueryFromURI = query => {
    if (query) {
      this.name = query.name;
      this.sort = query.sort || DEFAULT_SORT;
      this.size = query.size || PAGE_SIZE;
      this.processingState = query.processingState || DEFAULT_PROCESSING_STATE;
      this.deliveryRange = query.deliveryRange || DEFAULT_DELIVERY_RANGE;
      this.path = query.path || DEFAULT_PATH;
      this.source = query.source || DEFAULT_SOURCE;
    }
    if(this.deliveryRange === 'custom') {
      this.deliveryAfter = localStorage.getItem(DELIVERY_AFTER_STORAGE_KEY);
      this.deliveryBefore = localStorage.getItem(DELIVERY_BEFORE_STORAGE_KEY);
    }
  };

  // Computed
  get currentSort() {
    return parseSort(this.sort || '');
  }

  // Computed
  get fetchParams() {
    let computedCreatedAfter;
    let computedCreatedBefore = DEFAULT_DELIVERY_BEFORE;
    if (this.deliveryRange === 'custom') {
      computedCreatedAfter = calculateDate(new Date(this.deliveryAfter));
      computedCreatedBefore = calculateDate(new Date(this.deliveryBefore), true);
    } else {
      computedCreatedAfter = createdAfterMap[this.deliveryRange]
    }

    return {
      createdAfter: computedCreatedAfter,
      createdBefore: computedCreatedBefore,
      ...processingStateToQuery[this.processingState],
      regex: processRegexParam(this.path, this.source, this.name),
      size: this.size,
      sort: this.sort,
    };
  }

  fetch() {
    return this.dataFeedClient.statuses(this.fetchParams);
  }
}

decorate(DeliveryStore, {
  deliveryAfter: observable,
  deliveryBefore: observable,
  deliveryRange: observable,
  name: observable,
  processingState: observable,
  size: observable,
  sort: observable,
  path: observable,
  source: observable,
  currentSort: computed,
  fetchParams: computed,
  setQueryFromURI: action,
});

export { DeliveryStore };
