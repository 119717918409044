import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Cell } from 'fixed-data-table-2'

const DateCell = ({ data }) => (
  <Cell data-cy="date-cell">{data.eventDate.format('l HH:mm:ss')}</Cell>
)

DateCell.propTypes = {
  data: PropTypes.shape({
    eventDate: PropTypes.instanceOf(moment).isRequired,
  }).isRequired,
}

export default DateCell
