import React from 'react'
import PropTypes from 'prop-types'
import ChartAndTableContainer from '../ChartAndTableContainer'
import styles from './index.module.scss'
import CaseSummaryContainer from '../CaseSummaryContainer'
import { Pandy } from 'common'

const CaseReportsChartAndTableView = ({
  caseReportsCaseResolutionsStore,
  caseReportsCaseResolutionsOverTimeStore,
  caseReportsCaseTypesStore,
  caseReportsCaseTypesOverTimeStore,
  caseReportsFilterSelectionsStore,
  caseReportsTimeToResolveStore,
  hasCases,
}) =>
  hasCases ? (
    <div className={styles.chartAndTableWrapper} data-cy="chartAndTableWrapper">
      <CaseSummaryContainer
        caseReportsCaseResolutionsStore={caseReportsCaseResolutionsStore}
        caseReportsFilterSelectionsStore={caseReportsFilterSelectionsStore}
        isPrinted
      />
      <ChartAndTableContainer
        store={caseReportsCaseResolutionsStore}
        data-cy="case-report--case-resolutions"
      />
      <ChartAndTableContainer store={caseReportsCaseTypesStore} />
      <ChartAndTableContainer
        store={caseReportsCaseResolutionsOverTimeStore}
        chartType="lineChart"
      />
      <ChartAndTableContainer
        store={caseReportsCaseTypesOverTimeStore}
        chartType="lineChart"
      />
      <ChartAndTableContainer
        store={caseReportsTimeToResolveStore}
        showLegend={true}
      />
    </div>
  ) : (
    <Pandy visible mood="happy">
      <div className="speechBubble">
        <span>Nothing to show. Sorry!</span>
      </div>
    </Pandy>
  )

CaseReportsChartAndTableView.propTypes = {
  caseReportsCaseResolutionsStore: PropTypes.shape({}),
  caseReportsCaseResolutionsOverTimeStore: PropTypes.shape({}),
  caseReportsCaseTypesStore: PropTypes.shape({}),
  caseReportsCaseTypesOverTimeStore: PropTypes.shape({}),
  caseReportsFilterSelectionsStore: PropTypes.shape({}),
  caseReportsTimeToResolveStore: PropTypes.shape({}),
  tableCollapsed: PropTypes.bool,
  hasCases: PropTypes.bool,
}

export default CaseReportsChartAndTableView
