import React from 'react'
import styles from './index.module.scss'
import PropTypes from 'prop-types'
import { Anchor } from '../../ui'
import { DropdownList } from 'react-widgets'
import { CASES_BY_OPTIONS, CASES_STATISTIC_CALCULATION } from '../stores/const'

const Header = ({
  action,
  casesBy,
  disabledIntervals,
  interval,
  setChartCasesBy,
  setChartInterval,
  showChartCasesBy,
  showIntervalFilter,
  showChartStatisticCalculation,
  setChartStatisticCalculation,
  statisticCalculation,
  title,
  type,
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <h1>{title}</h1>
        {type ? <p>{` by ${type}`}</p> : null}
      </div>
      <span className={styles.spacer} />
      <div className={styles.actions}>
        {showChartStatisticCalculation && (
          <DropdownList
            data={CASES_STATISTIC_CALCULATION}
            onChange={({ value }) => setChartStatisticCalculation(value)}
            textField="label"
            value={statisticCalculation}
            valueField="value"
            valueComponent={({ item }) => (
              <span>
                <strong>Show</strong> {item.label}
              </span>
            )}
          />
        )}

        {showChartCasesBy && (
          <DropdownList
            className={styles.chartCasesBy}
            data={CASES_BY_OPTIONS}
            onChange={({ value }) => setChartCasesBy(value)}
            textField="label"
            value={CASES_BY_OPTIONS.find(({ value }) => value === casesBy)}
            valueField="value"
            valueComponent={({ item }) => (
              <span>
                <strong>Date of</strong> {item.label}
              </span>
            )}
          />
        )}

        {showIntervalFilter && (
          <DropdownList
            data={['day', 'week', 'month', 'quarter', 'year']}
            disabled={disabledIntervals}
            onChange={value => setChartInterval(value)}
            textField="name"
            value={interval}
            valueField="value"
            valueComponent={({ item }) => (
              <span data-cy="by-dropdown">
                <strong>By</strong> {item}
              </span>
            )}
          />
        )}
        <Anchor
          tag={'a'}
          onClick={e => {
            e.preventDefault()
            action()
          }}
          data-cy="chartAndTable-header-anchor"
        >
          <i className="material-icons icon-get_app" />
        </Anchor>
      </div>
    </div>
  )
}

Header.propTypes = {
  action: PropTypes.func.isRequired,
  casesBy: PropTypes.string,
  disabledIntervals: PropTypes.array,
  interval: PropTypes.string,
  queryValue: PropTypes.string,
  setChartCasesBy: PropTypes.func,
  setChartInterval: PropTypes.func,
  setChartStatisticCalculation: PropTypes.func,
  showChartStatisticCalculation: PropTypes.bool,
  showChartCasesBy: PropTypes.bool,
  showIntervalFilter: PropTypes.bool,
  statisticCalculation: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
}

export default Header
