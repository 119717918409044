import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import { Pandy } from 'common'
import { Checkbox } from '../../../ui'
import DiversionAssessment from '../../assessment/DiversionAssessment'
import PrivacyAssessment from '../../assessment/PrivacyAssessment'
import classnames from 'classnames'
import styles from './index.module.scss'
import { computeAssessmentAddedBy } from '../Caseflow/helper'

const AssessmentForCaseType = ({
  // common props
  analyticAssessments,
  analyticAssessmentLogs,
  assessmentsHeadlines,
  type,
  checkable,
  condensedHeader,
  linkTo,
  // diversion specific
  lastViewedByUser,
  incidentsByPeriod,
  unreconciledDrugs,
  selectInc,
  scrollToStatistic,
  clearScrollTo,
  setStatistic,
  setChecked,
  // privacy specific
  caseId,
  caseNumber,
  patientId,
  userId,
}) => {
  if (analyticAssessments.length === 0) {
    return (
      <Pandy
        className={styles.pandy}
        visible={true}
        mood={'sad'}
        data-cy="case-assessment-pandy"
      >
        <div className="speechBubble">
          <span>No Assessments. Sorry!</span>
        </div>
      </Pandy>
    )
  }

  if (type === 'diversion')
    return analyticAssessments.map((assessment, i) => {
      const author = computeAssessmentAddedBy(
        assessment.minEventDate,
        assessment.maxEventDate,
        analyticAssessmentLogs
      )
      const source = assessment.user && assessment.user.source
      return (
        <div
          className={classnames(styles.wrapper, {
            [styles.checkable]: checkable,
          })}
          key={Math.random()}
        >
          {checkable && (
            <div data-cy="assessment-checkable">
              <Checkbox
                checked={assessment.checked}
                disabled={!assessment.checkEnabled}
                onChange={() => setChecked(assessment)}
              />
            </div>
          )}
          <DiversionAssessment
            author={author}
            analyticAssessment={assessment}
            assessmentsHeadlines={assessmentsHeadlines[i]}
            unreconciledDrugs={unreconciledDrugs[i]}
            incidentsByPeriod={incidentsByPeriod[i]}
            selected={assessment.selected}
            onHeaderClick={setStatistic}
            linkTo={linkTo}
            lastViewedByUser={lastViewedByUser}
            selectInc={selectInc}
            scrollTo={scrollToStatistic === i}
            clearScrollTo={clearScrollTo}
            source={source}
            condensedHeader={condensedHeader}
          />
        </div>
      )
    })

  if (type === 'privacy')
    return analyticAssessments.map((assessment, i) => {
      const author = computeAssessmentAddedBy(
        assessment.minEventDate,
        assessment.maxEventDate,
        analyticAssessmentLogs
      )
      return (
        <div className={styles.wrapper} key={Math.random()}>
          <PrivacyAssessment
            author={author}
            analyticAssessment={assessment}
            headline={assessmentsHeadlines[i]}
            caseId={caseId}
            linkTo={linkTo}
            userId={userId}
            caseNumber={caseNumber}
            patientId={patientId}
            condensedHeader={condensedHeader}
          />
        </div>
      )
    })

  return null
}

AssessmentForCaseType.propTypes = {
  // common props
  type: PropTypes.string.isRequired,
  analyticAssessments: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  analyticAssessmentLogs: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({})
  ),
  assessmentsHeadlines: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  ),
  checkable: PropTypes.bool,
  selectInc: PropTypes.func,
  setStatistic: PropTypes.func,
  setChecked: PropTypes.func,
  condensedHeader: PropTypes.bool,
  linkTo: PropTypes.string,
  // diversion specific
  lastViewedByUser: PropTypes.string,
  unreconciledDrugs: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.arrayOf(PropTypes.string)
  ),
  incidentsByPeriod: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.array),
  scrollToStatistic: PropTypes.number,
  clearScrollTo: PropTypes.func,
  // privacy specific
  caseId: PropTypes.string,
  userId: PropTypes.string,
  caseNumber: PropTypes.number,
  patientId: PropTypes.string,
}

AssessmentForCaseType.defaultProps = {
  analyticAssessments: [],
  assessmentsHeadlines: [],
  analyticAssessmentLogs: [],
  condensedHeader: false,
  linkTo: '',
  // diversion specific
  checkable: false,
  incidentsByPeriod: [],
  lastViewedByUser: '',
  selectInc: () => {},
  setChecked: () => {},
  setStatistic: () => {},
  scrollToStatistic: null,
  clearScrollTo: () => {},
  unreconciledDrugs: [],
  // privacy specific
  caseId: null,
  caseNumber: 0,
  patientId: '',
  userId: '',
}

export default AssessmentForCaseType
