import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import classnames from 'classnames'
import { Card } from '../../../ui/Card'
import PatientCardHeader from './PatientCardHeader'
import PatientCardFields from './PatientCardFields'
import SelectButton from '../SelectButton'
import styles from '../index.module.scss'

const PatientCard = ({
  canCreateAuths,
  canViewAuths,
  disabled,
  existingAuths,
  onSelect,
  openLinkNew,
  patient,
  registeredVIP,
  selectable,
  selected,
  showAuthInfo,
  vipStatus,
}) => {
  return (
    <Card
      className={classnames(styles.personCard, {
        'is-disabled': disabled,
        'is-selected': selected,
      })}
    >
      <PatientCardHeader
        fullName={patient.fullName}
        id={patient.id}
        instance={patient.instance}
        openLinkNew={openLinkNew}
        registeredVIP={registeredVIP}
        source={patient.source}
        vipStatus={vipStatus}
      />
      <PatientCardFields
        addresses={patient.addresses}
        canCreateAuths={canCreateAuths}
        canViewAuths={canViewAuths}
        dateOfBirth={patient.dateOfBirth}
        existingAuths={existingAuths}
        id={patient.id}
        medicalRecordNumbers={patient.medicalRecordNumbers}
        showAuthInfo={showAuthInfo}
      >
        {selectable && !disabled && (
          <SelectButton onSelect={onSelect} selected={selected} />
        )}
      </PatientCardFields>
    </Card>
  )
}

PatientCard.propTypes = {
  canCreateAuths: PropTypes.bool,
  canViewAuths: PropTypes.bool,
  disabled: PropTypes.bool,
  existingAuths: PropTypes.bool,
  onSelect: PropTypes.func,
  openLinkNew: PropTypes.bool,
  patient: PropTypes.shape({
    addresses: mobxPropTypes.arrayOrObservableArrayOf(
      PropTypes.shape({
        address1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
      })
    ),
    dateOfBirth: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    instance: PropTypes.string,
    medicalRecordNumbers: mobxPropTypes.arrayOrObservableArrayOf(
      PropTypes.string
    ),
    source: PropTypes.string,
  }).isRequired,
  registeredVIP: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  showAuthInfo: PropTypes.bool,
  vipStatus: PropTypes.string,
}

PatientCard.defaultProps = {
  canCreateAuths: false,
  canViewAuths: false,
  disabled: false,
  existingAuths: false,
  onSelect: null,
  openLinkNew: false,
  registeredVIP: false,
  selectable: false,
  selected: false,
  showAuthInfo: false,
  vipStatus: null,
}

export default PatientCard
