import UserFilters from '../../ui/UserFilters'
import { withRouterAndQuery } from '../../utils/RouteHelper'
import { observerWrappedContainerFactory } from '../../utils/observerWrappedContainerFactory'
import { CaseReportsContext } from '../Context'

export function mapProps({
  caseReportsFilterSelectionsStore,
  caseResultsFiltersStore,
  history,
  location,
  query,
}) {
  return {
    history,
    location,
    query,

    queryForToggle: caseReportsFilterSelectionsStore.queryForToggle,
    queryForSelectionChange:
      caseReportsFilterSelectionsStore.queryForSelectionChange,

    loadOrganizations: caseResultsFiltersStore.loadOrganizations,
    loadDepartments: caseResultsFiltersStore.loadDepartments,

    organizationOptions: caseResultsFiltersStore.userOrganizations,
    organizationSelections:
      caseReportsFilterSelectionsStore.displayOrganization,

    departmentOptions: caseResultsFiltersStore.userDepartments,
    departmentSelections: caseReportsFilterSelectionsStore.displayDepartment,

    roleOptions: caseResultsFiltersStore.userTypes,
    roleSelections: caseReportsFilterSelectionsStore.displayUserType,
    roleExcluded: caseReportsFilterSelectionsStore.userTypeExclude,

    tagOptions: caseResultsFiltersStore.userTags,
    tagSelections: caseReportsFilterSelectionsStore.displayTags,
    tagExcluded: caseReportsFilterSelectionsStore.userTagsExclude,
  }
}

const UserFiltersContainer = withRouterAndQuery(
  observerWrappedContainerFactory({
    Context: CaseReportsContext,
    mapProps,
    PresentationalComponent: UserFilters,
  })
)

UserFiltersContainer.displayName = 'UserFiltersContainer'

export default UserFiltersContainer
