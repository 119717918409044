import { action, computed, decorate } from 'mobx'
import { GRC_PRODUCT } from 'common'

export const getOptionValues = (opts = []) => {
  if (opts.length) {
    return opts.map(({ name }) => name)
  } else return undefined
}

class CasePrintLogStore {
  constructor({
    caseCustomPrintStore,
    incidentsPrintOptionsStore,
    assessmentsPrintOptionsStore,
    incidentsFromCase,
    assessmentsFromCase,
    caseType,
    caseId,
    caseLogClient,
    refreshLogs,
  }) {
    this.caseCustomPrintStore = caseCustomPrintStore
    this.incidentsPrintOptionsStore = incidentsPrintOptionsStore
    this.assessmentsPrintOptionsStore = assessmentsPrintOptionsStore
    this.incidentsFromCase = incidentsFromCase
    this.assessmentsFromCase = assessmentsFromCase
    this.caseType = caseType
    this.caseId = caseId
    this.caseLogClient = caseLogClient
    this.refreshLogs = refreshLogs
  }

  get caseflowOptions() {
    const { caseCustomPrintStore } = this
    const {
      selectedCaseflowOption,
      caseflowFieldsToInclude,
    } = caseCustomPrintStore

    return {
      caseflow: [selectedCaseflowOption],
      caseflowCustom: getOptionValues(caseflowFieldsToInclude),
    }
  }

  get incidentsOptions() {
    const { incidentsPrintOptionsStore } = this
    const {
      selectedMainOption,
      incidentIdsToInclude,
      selectedIncidentTimeRanges,
      selectedIncidentTypes,
      selectedIncidentStatuses,
    } = incidentsPrintOptionsStore

    return {
      incidents: [selectedMainOption],
      incidentId: getOptionValues(incidentIdsToInclude),
      incidentTimeRange: getOptionValues(selectedIncidentTimeRanges),
      incidentType: getOptionValues(selectedIncidentTypes),
      incidentStatus: getOptionValues(selectedIncidentStatuses),
    }
  }

  get assessmentsOptions() {
    const { assessmentsPrintOptionsStore } = this
    const {
      selectedMainOption,
      selectedAssessmentsTimeRanges,
    } = assessmentsPrintOptionsStore

    return {
      assessments: [selectedMainOption],
      assessmentTimeRange: getOptionValues(selectedAssessmentsTimeRanges),
    }
  }

  get entityDetailsOptions() {
    const { caseCustomPrintStore } = this
    const { selectedDetailsOption } = caseCustomPrintStore

    return {
      userDetails: [selectedDetailsOption],
    }
  }

  get allPrintOptions() {
    const {
      caseflowOptions,
      incidentsOptions,
      assessmentsOptions,
      entityDetailsOptions,
      incidentsFromCase,
      assessmentsFromCase,
      caseType,
    } = this

    let options = {
      ...caseflowOptions,
    }

    if ((incidentsFromCase || []).length)
      options = { ...options, ...incidentsOptions }
    if ((assessmentsFromCase || []).length)
      options = { ...options, ...assessmentsOptions }
    if (caseType !== GRC_PRODUCT)
      options = { ...options, ...entityDetailsOptions }

    return options
  }

  print = () => {
    const { caseId, caseLogClient, allPrintOptions, refreshLogs } = this
    Object.keys(allPrintOptions).forEach(
      key => allPrintOptions[key] === undefined && delete allPrintOptions[key]
    )
    const params = {
      id: caseId,
      options: allPrintOptions,
    }

    const assessments = document.querySelectorAll(
      '.case-event--analyticalAssessment'
    )

    if (this.assessmentsPrintOptionsStore.selectedMainOption === 'custom') {
      // if assessment print option is custom loop through assessments and hide assessments that were not selected in time ranges
      const classNames = this.assessmentsPrintOptionsStore.selectedAssessmentsTimeRanges.map(
        r => r.value
      )
      for (let i = 0; i < assessments.length; i++) {
        if (classNames.some(c => assessments[i].classList.contains(c))) {
          //noop
        } else if (classNames.length === 0) {
          //user has not selected a time range thus the default is all time ranges
          //noop
        } else {
          assessments[i].classList.add('forScreen')
        }
      }
    }

    if (this.assessmentsPrintOptionsStore.selectedMainOption === 'none') {
      // if assessment print option is none hide assessment elements
      if (assessments) {
        for (let i = 0; i < assessments.length; i++) {
          assessments[i].classList.add('forScreen')
        }
      }
    }

    const caseEvents = document.querySelector('.case-event-container')

    // reverse caseflow elements for print
    if (caseEvents) {
      for (let i = 1; i < caseEvents.childNodes.length; i++) {
        caseEvents.insertBefore(caseEvents.childNodes[i], caseEvents.firstChild)
      }
    }

    window.print()
    if (caseId) {
      caseLogClient.print(params)

      // when dialog closes reverse items back
      for (let i = 1; i < caseEvents.childNodes.length; i++) {
        caseEvents.insertBefore(caseEvents.childNodes[i], caseEvents.firstChild)
      }

      // remove class that hides assessment elements after print dialog closes
      for (let i = 0; i < assessments.length; i++) {
        assessments[i].classList.remove('forScreen')
      }
      refreshLogs()
    }
  }
}

decorate(CasePrintLogStore, {
  caseflowOptions: computed,
  incidentsOptions: computed,
  assessmentsOptions: computed,
  entityDetailsOptions: computed,
  allPrintOptions: computed,
  print: action,
})

export { CasePrintLogStore }
