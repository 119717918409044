import CaseReportsChartAndTableBaseStore from '../CaseReportsChartAndTableBaseStore'
import { makeLegendData } from '../../ChartAndTable/BarChartTheme'
import { computed, decorate, observable } from 'mobx'
import { formatDates, showYear } from '../utils'

class CaseReportsTimeToResolveStore extends CaseReportsChartAndTableBaseStore {
  showIntervalFilter = true
  showChartStatisticCalculation = true
  skipAlphaNumericSort = true
  chartStatisticCalculation = 'average'

  get title() {
    return 'Days to Resolution'
  }

  get groupBy() {
    return ['created']
  }

  get primaryField() {
    return 'created'
  }

  get legendData() {
    const AVERAGE_LINE_COLOR = '#69d5e4'

    const legendLabel = [[`Time Range Average`]]
    const uniqueLegendLabels = Object.values(
      this.coreData.map(this.legendValueGetter).reduce((hash, labels) => {
        return { ...hash, [labels.join()]: labels }
      }, {})
    )

    return this.pivotOn
      ? [
          ...makeLegendData(uniqueLegendLabels),
          ...makeLegendData(legendLabel, AVERAGE_LINE_COLOR),
        ]
      : makeLegendData(legendLabel, AVERAGE_LINE_COLOR)
  }

  get configuration() {
    return {
      maxBars: 60,
      maxLines: 15,
      maxRowsBeforeScroll: 10,
      singleColorBars: !this.pivotOn, // single color unless there is a pivot option
      showAverageLine: true,
    }
  }

  get averageData() {
    let total = 0
    this.coreData.map(d => {
      total += d.count
      return d
    })
    return (total / this.coreData.length).toFixed(1)
  }

  computeAvg = tableData => {
    return tableData.map((d, i) => {
      if (i === tableData.length - 1) {
        d.total = (d.total / (tableData.length - 1)).toFixed(1)
        d[`Created ${this.interval}`] = 'Time Range Average'
      }
      delete d['%']
      d[this.statCalcString] = d.total
      delete d.total
      return d
    })
  }

  get statCalcString() {
    return this.chartStatisticCalculation
      ? this.chartStatisticCalculation + ' Days to Resolution'
      : 'Average Days to Resolution'
  }

  get tableData() {
    const tableData = this.buildTableData()
    return this.computeAvg(tableData, this.statCalcString)
  }

  get exportTableData() {
    const tableData = this.buildTableData(true)
    return this.computeAvg(tableData, this.statCalcString)
  }

  /**
   * computed getter to pre-process response data,
   * this mainly formats date strings
   * the first group must be "case.created"
   */
  get result() {
    // if we don't deep clone, the obj reference that's being observed
    // will cause infinite recalculation
    const deepClone = JSON.parse(JSON.stringify(this.response))
    const res = [...(deepClone || [])]
    // Determine the formatting of the dates
    // omit the year display if all of them are in the same year
    let format = 'M/D'
    if (showYear(res)) format = 'M/D/Y'
    // Format the dates
    return formatDates(res, this.interval, format)
  }
}

decorate(CaseReportsTimeToResolveStore, {
  chartStatisticCalculation: observable,
  averageData: computed,
  configuration: computed,
  legendData: computed,
  result: computed,
  tableData: computed,
  statCalcString: computed,
  exportTableData: computed,
})

export default CaseReportsTimeToResolveStore
