import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MoreBack from '../MoreBack'
import Columns from '../Columns'
import Column from '../Column'

/**
 * Layout component used by the EmployeeBox and PatientBox.
 * We are told what to render in two columns and whether to show both columns
 * at once or toggle back and forth in one view.
 *
 * If we are only showing one column at a time, we also need to render the
 * other column for print media.
 *
 * @param renderFirstColumn
 * @param renderSecondColumn
 * @param showTwoColumns
 * @param moreLabel
 * @param backLabel
 * @return {JSX.Element}
 * @constructor
 */
const MaybeTwoColumns = ({
  renderFirstColumn,
  renderSecondColumn,
  showTwoColumns,
  moreLabel,
  backLabel,
}) => {
  const [showNextColumn, setShowNextColumn] = useState(false)

  return (
    <>
      {!showTwoColumns && (
        <MoreBack
          showingMore={showNextColumn}
          onToggle={() => setShowNextColumn(!showNextColumn)}
          moreLabel={moreLabel}
          backLabel={backLabel}
          data-cy={moreLabel
            .toLowerCase()
            .split(' ')
            .join('-')
            .replace('-&', '')}
        />
      )}
      <Columns>
        <Column printOnly={!showTwoColumns && showNextColumn}>
          {renderFirstColumn()}
        </Column>
        <Column printOnly={!showTwoColumns && !showNextColumn}>
          {renderSecondColumn()}
        </Column>
      </Columns>
    </>
  )
}

MaybeTwoColumns.propTypes = {
  renderFirstColumn: PropTypes.func.isRequired,
  renderSecondColumn: PropTypes.func.isRequired,
  showTwoColumns: PropTypes.bool.isRequired,
  moreLabel: PropTypes.string.isRequired,
  backLabel: PropTypes.string.isRequired,
}

export default MaybeTwoColumns
