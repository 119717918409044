import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import { Authenticated } from 'common'

import NavBarItem from '../NavBarItem'
import TopNavBar from '../TopNavBar'
import Tooltip from '../../ui/Tooltip'
import LinkWithForwardRef from '../../ui/LinkWithForwardRef'
import styles from './index.module.scss'
import ExportMenu from '../../ui/ExportMenu'

const ActionNav = observer(
  class ActionNav extends React.Component {
    static propTypes = {
      advancedFiltersOnClick: PropTypes.func,
      clearFilter: PropTypes.func,
      count: PropTypes.number,
      createPath: PropTypes.string,
      createPermission: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
      ]),
      createRole: PropTypes.string,
      createIcon: PropTypes.string,
      createLabel: PropTypes.string,
      additionalIcons: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          icon: PropTypes.string,
          path: PropTypes.string,
        })
      ),
      downloadCSV: PropTypes.func,
      downloadCSVWithOptions: PropTypes.func,
      patient: PropTypes.string,
      secondary: PropTypes.bool,
      title: PropTypes.string.isRequired,
      user: PropTypes.string,
      showLink: PropTypes.bool,
      linkRoute: PropTypes.string,
      linkText: PropTypes.string,
      eventTypes: PropTypes.array,
      exportMenuWrapperClass: PropTypes.string,
    }

    static defaultProps = {
      advancedFiltersOnClick: null,
      clearFilter: () => {},
      count: undefined,
      createPath: undefined,
      createPermission: undefined,
      createRole: undefined,
      createIcon: undefined,
      createLabel: undefined,
      additionalIcons: [],
      downloadCSV: undefined,
      downloadCSVWithOptions: undefined,
      patient: undefined,
      secondary: true,
      user: undefined,
      showLink: false,
      linkRoute: undefined,
      linkText: undefined,
      eventTypes: [],
    }

    renderCSVDownload(
      downloadCSV,
      downloadCSVWithOptions,
      eventTypes,
      exportMenuWrapperClass
    ) {
      if (downloadCSV) {
        return (
          <Tooltip content="Export CSV" placement="left">
            <span
              onClick={downloadCSV}
              className="float-right icon"
              data-cy="download-csv"
            >
              <i className="material-icons icon-get_app" />
            </span>
          </Tooltip>
        )
      }
      if (downloadCSVWithOptions) {
        return (
          <ExportMenu
            buttonClass="icon"
            wrapperClass={exportMenuWrapperClass}
            cb={downloadCSVWithOptions}
            exportCb={downloadCSVWithOptions}
            eventTypes={eventTypes}
            iTip={'Export CSV'}
          />
        )
      }
    }

    renderadditionalIcons() {
      const { additionalIcons } = this.props
      const navItems = []
      if (additionalIcons) {
        additionalIcons.forEach(addIcon => {
          const { label, path, icon } = addIcon

          navItems.push(
            <Tooltip content={label} placement="left" key={`${label}-${path}`}>
              <LinkWithForwardRef to={path} className="float-right icon">
                <i className={`material-icons icon-${icon}`} />
              </LinkWithForwardRef>
            </Tooltip>
          )
        })
      }
      return navItems
    }

    renderCreateButton({
      createPath,
      createPermission,
      createRole,
      createIcon,
      createLabel,
    }) {
      if (createPath && (createPermission || createRole)) {
        let link

        if (createIcon) {
          link = (
            <Tooltip content={createLabel} placement="left">
              <LinkWithForwardRef to={createPath} className="float-right icon">
                <i
                  className={`material-icons icon-${createIcon}`}
                  data-cy="create-new-app-user---btn"
                />
              </LinkWithForwardRef>
            </Tooltip>
          )
        } else {
          link = (
            <Link to={createPath} className="button float-right">
              Add New
            </Link>
          )
        }

        const authProps = {
          anyPermission: true,
          [createPermission ? 'permission' : 'appRole']:
            createPermission || createRole,
        }
        return <Authenticated {...authProps}>{link}</Authenticated>
      }
    }

    renderAdvancedFilters() {
      const { advancedFiltersOnClick } = this.props
      return advancedFiltersOnClick ? (
        <Tooltip content="Advanced Filters" placement="bottom">
          <NavBarItem
            onClick={e => {
              e.preventDefault()
              advancedFiltersOnClick()
            }}
          >
            <i className="material-icons icon-tune" data-cy="vip-nav--aside" />
          </NavBarItem>
        </Tooltip>
      ) : null
    }

    renderCount() {
      const { count, clearFilter, patient, title, user } = this.props

      let supplemental
      if (user)
        supplemental = (
          <span>
            With EMR User <ins>{user}</ins>
          </span>
        )
      else if (patient)
        supplemental = (
          <span>
            With Patient <ins>{patient}</ins>
          </span>
        )

      const navItems = []

      if (count !== undefined) {
        navItems.push(
          <NavBarItem key="result-count">
            <strong>{count}</strong> {title}
            {count === 1 ? '' : 's'} {supplemental}
          </NavBarItem>
        )
      }

      if (supplemental) {
        navItems.push(
          <NavBarItem key="clear-filter">
            <span className="icon" onClick={clearFilter}>
              <i className="material-icons icon-clear" />
            </span>
          </NavBarItem>
        )
      }

      return navItems
    }

    render() {
      const {
        createPath,
        createPermission,
        createRole,
        createIcon,
        createLabel,
        downloadCSV,
        downloadCSVWithOptions,
        eventTypes,
        secondary,
        showLink,
        linkRoute,
        linkText,
        exportMenuWrapperClass,
      } = this.props

      return (
        <TopNavBar actionBar secondary={secondary}>
          {this.renderAdvancedFilters()}
          {this.renderCount()}
          {showLink && (
            <Link className={styles.link} to={linkRoute}>
              {linkText}
            </Link>
          )}
          <li className="pull-right">
            <ul>
              {this.renderadditionalIcons()}
              {this.renderCreateButton({
                createPath,
                createPermission,
                createRole,
                createIcon,
                createLabel,
              })}
              {this.renderCSVDownload(
                downloadCSV,
                downloadCSVWithOptions,
                eventTypes,
                exportMenuWrapperClass
              )}
            </ul>
          </li>
        </TopNavBar>
      )
    }
  }
)

ActionNav.displayName = 'ActionNav'

export default ActionNav
