import React from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { decorate, observable } from 'mobx'

import BulkCaseActionFormActions from '../BulkCaseActionFormActions'
import { MultilineInput } from '../..'

const AddCaseNote = observer(
  class AddCaseNote extends React.Component {
    static propTypes = {
      store: PropTypes.shape({
        performBulkAction: PropTypes.func.isRequired,
        performingBulkAction: PropTypes.bool.isRequired,
        percentComplete: PropTypes.number.isRequired,
      }).isRequired,
    }

    // Observable
    dirty = false
    newNotes = ''

    /**
     * Set the case notes to a new value. This is the onClick handler for the form.
     * the associated store.
     * @param {Object} e the event
     * @return {undefined}
     */
    onSubmit = e => {
      e.preventDefault()
      const {
        newNotes,
        props: { store },
      } = this

      store.performBulkAction('addNote', { notes: newNotes })
    }

    /**
     * Handle the notes value change. This is the onChange callback handler for
     * the editor
     * @param  {string} value   The new value being set.
     *
     * @return {void}
     */
    onChange = value => {
      this.newNotes = value
      this.dirty = !!value
    }

    render() {
      const { store } = this.props
      const { performingBulkAction } = store

      return (
        <form className="form" onSubmit={this.onSubmit}>
          <ul>
            <li>
              <div className="input-section bundleActions--caseNotes--input">
                <MultilineInput
                  label="Case Notes"
                  value={this.newNotes}
                  onChange={this.onChange}
                  readOnly={performingBulkAction}
                  bordered
                />
              </div>
            </li>
            <BulkCaseActionFormActions
              store={store}
              buttonActionType="updateNotes"
              helpText="This action will add a note for all selected cases and cannot be undone."
            />
          </ul>
        </form>
      )
    }
  }
)

decorate(AddCaseNote, {
  dirty: observable,
  newNotes: observable,
})

AddCaseNote.displayName = 'AddCaseNote'

export default AddCaseNote
