import React from 'react';
import { SingletonStore, ConfigClient, HalUtils } from 'common';
import NotificationStore from '../NotificationStore';

const CONTENT = {
  warning: (
    <span>
      <i className="material-icons icon-warning" />
      There was a problem while saving your settings.
    </span>
  ),
  success: (
    <span>
      <i className="material-icons icon-check_circle" />
      Successfully updated.
    </span>
  ),
};

class GeneralSettingsStore extends SingletonStore {
  fetch() {
    return ConfigClient.get(this.category, this.key);
  }

  toastThatStuff(level) {
    NotificationStore.add({ level, content: CONTENT[level] });
  }

  update = params => {
    const obj =
      typeof params === 'object'
        ? Object.assign({}, { properties: params })
        : { value: params };

    if (this.response) {
      return ConfigClient.update(HalUtils.getSelf(this.response), obj).then(
        () => {
          this.toastThatStuff('success');
          this.refresh();
        },
        () => this.toastThatStuff('warning')
      );
    }

    obj.category = this.category;
    obj.key = this.key;

    return ConfigClient.create(obj).then(
      () => {
        this.toastThatStuff('success');
        this.refresh();
      },
      () => this.toastThatStuff('warning')
    );
  };
}

export default GeneralSettingsStore;
