import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import classnames from 'classnames'

export const ValueComponent = ({ item }) => {
  return (
    <div className={styles.valueInput}>
      <p>
        <span className={styles.icon}>
          <i
            className={classnames('material-icons', item.icon.class)}
            style={{
              color: item.icon.color,
            }}
          />
        </span>
        {item.label}
      </p>
    </div>
  )
}

const itemProps = {
  label: PropTypes.string,
  icon: PropTypes.shape({
    class: PropTypes.string,
    color: PropTypes.string,
  }),
}

ValueComponent.propTypes = {
  item: PropTypes.shape(itemProps),
}

ValueComponent.defaultProps = {
  item: {},
}

export const ItemComponent = ({ item }) => {
  return (
    <div className={styles.item}>
      <p>
        <span className={styles.icon}>
          <i
            className={classnames('material-icons', item.icon.class)}
            style={{
              color: item.icon.color,
            }}
          />
        </span>
        {item.label}
      </p>
    </div>
  )
}

ItemComponent.propTypes = {
  item: PropTypes.shape(itemProps),
}

ItemComponent.defaultProps = {
  item: {},
}
