import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { computed, decorate } from 'mobx'
import { observer } from 'mobx-react'

import { Pandy } from 'common'

import Loader from '../../ui/Loader'
import MetalogStore from '../stores/MetalogStore'
import Store from '../stores/Store'
import ChartStore from '../stores/ChartStore'
import FullActivityChart from '../chart/FullActivityChart'
import UserOnlyActivityChart from '../chart/UserOnlyActivityChart'
import DiversionActivityChartContainer from '../chart/diversion/DiversionActivityChartContainer'

const ActivityVisualization = observer(
  class extends React.Component {
    static propTypes = {
      className: PropTypes.string,
    }
    // Computed
    get noData() {
      return (
        (!MetalogStore.loading &&
          MetalogStore.filteredMetalogs &&
          MetalogStore.filteredMetalogs.length === 0) ||
        false
      )
    }

    // Computed
    get days() {
      return (Store.toDate && Store.toDate.diff(Store.fromDate, 'days')) || 0
    }

    fullName(person) {
      const fn = person.firstName
      const ln = person.lastName

      let name = ''
      if (fn) name += ` ${fn}`
      if (ln) name += ` ${ln}`
      return name
    }

    renderUserOnlyChart() {
      if (ChartStore.showUserOnly) {
        return (
          <div
            className={classnames('events_chart_wrapper user-only', {
              hidden: MetalogStore.loading,
            })}
          >
            <UserOnlyActivityChart />
          </div>
        )
      }

      return null
    }

    renderDiversionActivityChart() {
      if (!ChartStore.showDiversion) return null
      return <DiversionActivityChartContainer />
    }

    renderPandy() {
      if (Store.badDates) {
        // user entered a bad date range
        return (
          <Pandy visible={Store.badDates} mood="sad" className="activity_pandy">
            <div className="speechBubble">
              <span>Whoops!</span>
            </div>
            <p>
              The start date does not occur before the end date.
              <br />
              Please try adjusting your date range.
            </p>
          </Pandy>
        )
      }

      if (this.noData && !Store.badDates && Store.activeFiltersCount === 0) {
        // no data because of event filters
        return (
          <Pandy
            visible={this.noData && !Store.badDates}
            mood="sad"
            className="activity_pandy"
          >
            <div className="speechBubble">
              <span>Sorry!</span>
            </div>
            <p>
              We couldn&apos;t find anything for the dates you selected.
              <br />
              Please try adjusting your date range or filters.
            </p>
          </Pandy>
        )
      }

      if (
        this.noData &&
        !Store.badDates &&
        (Store.subFilter1 || Store.subFilter2)
      ) {
        // no data because of event subfilters
        let message = ''
        if (Store.isUserFocus && Store.user) {
          message = `${this.fullName(
            Store.user
          )} did not access any patients matching the current filter`
        } else if (Store.patient) {
          message = `${this.fullName(
            Store.patient
          )} was not accessed by any users matching the current filter`
        }

        return (
          <Pandy
            visible={this.noData && !Store.badDates}
            mood="sad"
            className="activity_pandy"
          >
            <div className="speechBubble">
              <span>Sorry!</span>
            </div>
            <p>{message}</p>
          </Pandy>
        )
      }

      if (this.noData && !Store.badDates) {
        // no data in the time range
        return (
          <Pandy
            visible={this.noData && !Store.badDates}
            mood="sad"
            className="activity_pandy"
          >
            <div className="speechBubble">
              <span>Sorry!</span>
            </div>
            <p>
              We couldn’t find anything for the dates you selected.
              <br />
              Please try adjusting your date range.
            </p>
          </Pandy>
        )
      }

      return null
    }

    render() {
      const loaderCn = classnames({
        longWait: this.days && this.days > 30,
      })
      const { className } = this.props
      return (
        <section className={className}>
          {this.renderUserOnlyChart()}
          {this.renderDiversionActivityChart()}
          <div className="scrollable">
            {this.renderPandy()}
            <Loader loaded={!MetalogStore.loading} className={loaderCn}>
              <div className="events_chart_wrapper" data-cy="events-chart">
                <FullActivityChart />
              </div>
            </Loader>
          </div>
        </section>
      )
    }
  }
)

decorate(ActivityVisualization, {
  noData: computed,
  days: computed,
})

ActivityVisualization.displayName = 'ActivityVisualization'

export default ActivityVisualization
