import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import $ from 'jquery'
import { DropdownList } from 'react-widgets'
import DropdownItem from '../DropdownItem'
import styles from './index.module.scss'

export const setFilter = ({
  caseId,
  incidentFilterParams,
  targetParam,
  filter,
}) => {
  const mergeCriteria = {
    ...incidentFilterParams,
    [targetParam]: filter.value || '',
  }
  return {
    pathname: `/case/${caseId}/incidents`,
    search: '?' + $.param(mergeCriteria),
  }
}

const MedicationIncidentFilter = ({
  filterOptions,
  incidentFilterParams,
  targetParam,
  caseId,
  history,
  'data-cy': dataCy,
}) => {
  let query = incidentFilterParams[targetParam] || filterOptions[0]
  // Guard against a bogus option value being provided.
  if (!filterOptions.find(option => option.value === query)) {
    query = filterOptions[0]
  }

  const groupBy = Object.prototype.hasOwnProperty.call(
    filterOptions[0],
    'category'
  )
    ? 'category'
    : ''
  const handleOnChange = filter => {
    const newCriteria = setFilter({
      caseId,
      incidentFilterParams,
      targetParam,
      filter,
    })
    return history.push(newCriteria)
  }

  return (
    <div className={styles.wrapper} data-cy={dataCy}>
      <DropdownList
        data={filterOptions}
        groupBy={groupBy}
        textField="name"
        valueField="value"
        value={query}
        onChange={filter => handleOnChange(filter)}
        valueComponent={DropdownItem}
      />
    </div>
  )
}

MedicationIncidentFilter.propTypes = {
  'data-cy': PropTypes.string,
  filterOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.shape({})),
  incidentFilterParams: PropTypes.shape({}),
  targetParam: PropTypes.string.isRequired,
  caseId: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
}

MedicationIncidentFilter.defaultProps = {
  filterOptions: [{}],
  incidentFilterParams: {},
}

export default MedicationIncidentFilter
