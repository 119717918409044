import { computed, decorate } from 'mobx'
import { SingletonStore, BuildVersionClient } from 'common'

class VersionStore extends SingletonStore {
  constructor({ buildVersionClient }) {
    super()
    this.buildVersionClient = buildVersionClient
    this.refresh()
  }

  fetch() {
    return this.buildVersionClient.find()
  }

  get versionInfo() {
    return this.result?.git
  }
}

decorate(VersionStore, {
  versionInfo: computed,
})

export default new VersionStore({ buildVersionClient: BuildVersionClient })
