import React from 'react'
import { observer } from 'mobx-react'
import { AlertCategoryStore } from 'common'
import CaseResultsStore from '../stores/CaseResultsStore'
import FiltersBannerStore from '../../stores/FiltersBannerStore'

import CaseResultsView from '../CaseResultsView'
import { withRouterAndQuery } from '../../utils/RouteHelper'

export function mapProps({
  AlertCategoryStore,
  CaseResultsStore,
  FiltersBannerStore,
  query,
}) {
  const storePagingProps = {
    nextPage: CaseResultsStore.nextPage,
    loading: CaseResultsStore.loading,
    paging: CaseResultsStore.paging,
    size: CaseResultsStore.size,
  }

  return {
    failure: CaseResultsStore.failure,
    hasMore: CaseResultsStore.hasMore,
    showFilters: !!CaseResultsStore.showFilters,
    storeParams: CaseResultsStore.params,
    cases: CaseResultsStore.cases,
    toggleAdvFilters: CaseResultsStore.toggleAdvFilters,
    storePagingProps,
    expandoVisible: CaseResultsStore.expandoVisible,
    shouldShowMsg: FiltersBannerStore.shouldShowMsg,
    useGroups: AlertCategoryStore.useGroups,
    query,
  }
}

const CaseResultsViewContainer = withRouterAndQuery(
  observer(({ query }) => (
    <CaseResultsView
      {...mapProps({
        AlertCategoryStore,
        CaseResultsStore,
        FiltersBannerStore,
        query,
      })}
    />
  ))
)

export default CaseResultsViewContainer
