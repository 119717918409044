import React, { useEffect, useState } from 'react'
import { Authenticated, PermissionStore, CaseClient, GroupStore } from 'common'
import { DocumentTitle } from '../../ui'
import styles from './index.module.scss'
import ActionNav from '../ActionNav'
import CaseReportsFilters from '../CaseReportsFilters'
import { CaseReportsContext } from '../Context'
import { useEphemeralStore } from '../../utils/hooks'
import { CaseReportsFilterSelectionsStore } from '../stores/CaseReportsFilterSelectionsStore'
import CaseResultsFiltersStore from '../../stores/CaseResultsFiltersStore'
import {
  routerAndQueryPropTypes,
  withRouterAndQuery,
} from '../../utils/RouteHelper'
import CaseReportsCaseTypesStore from '../stores/CaseReportsCaseTypesStore'
import CaseReportsCaseResolutionsStore from '../stores/CaseReportsCaseResolutionsStore'
import CaseReportsChartAndTableViewContainer from '../CaseReportsChartAndTableViewContainer'
import CaseReportsCaseResolutionsOverTimeStore from '../stores/CaseReportsCaseResolutionsOverTimeStore'
import CaseReportsCaseTypesOverTimeStore from '../stores/CaseReportsCaseTypesOverTimeStore'
import CaseReportsTimeToResolveStore from '../stores/CaseReportsTimeToResolveStore'
import { processParams } from '../stores/CaseReportsChartAndTableBaseStore/helpers'
import PrintHeader from '../../ui/PrintHeader'

const CaseReportsRootView = ({ query }) => {
  const caseReportsFilterSelectionsStore = useEphemeralStore(
    () =>
      new CaseReportsFilterSelectionsStore({
        caseResultsFiltersStore: CaseResultsFiltersStore,
        permissionStore: PermissionStore,
      })
  )

  const storeParams = {
    caseClient: CaseClient,
    caseReportsFilterSelectionsStore,
    processParams,
  }
  const caseReportsCaseResolutionsStore = useEphemeralStore(
    () =>
      new CaseReportsCaseResolutionsStore({
        ...storeParams,
        permissionStore: PermissionStore,
      })
  )
  const caseReportsCaseResolutionsOverTimeStore = useEphemeralStore(
    () => new CaseReportsCaseResolutionsOverTimeStore(storeParams)
  )
  const caseReportsCaseTypesStore = useEphemeralStore(
    () => new CaseReportsCaseTypesStore(storeParams)
  )
  const caseReportsCaseTypesOverTimeStore = useEphemeralStore(
    () => new CaseReportsCaseTypesOverTimeStore(storeParams)
  )
  const caseReportsTimeToResolveStore = useEphemeralStore(
    () => new CaseReportsTimeToResolveStore(storeParams)
  )

  useEffect(() => {
    if (!query.group) query.group = GroupStore.groupIds
    caseReportsFilterSelectionsStore.setQuery(query)
  })

  const [tableCollapsed, setTableCollapsed] = useState(false)
  const handleSetTableCollapsed = () => setTableCollapsed(!tableCollapsed)

  return (
    <Authenticated permission={['CASE_REPORT_VIEW']}>
      <DocumentTitle text="Reports" />
      <CaseReportsContext.Provider
        value={{
          caseReportsFilterSelectionsStore,
          caseResultsFiltersStore: CaseResultsFiltersStore,
          permissionStore: PermissionStore,
          caseReportsCaseResolutionsStore,
          caseReportsCaseResolutionsOverTimeStore,
          caseReportsCaseTypesStore,
          caseReportsCaseTypesOverTimeStore,
          caseReportsTimeToResolveStore,
          groupStore: GroupStore,
          tableCollapsed,
          handleSetTableCollapsed,
        }}
      >
        <PrintHeader showGeneratedTime={false} />
        <section className={styles.container}>
          <CaseReportsFilters />
          <div className={styles.body} data-cy="cases-report--body">
            <ActionNav
              caseReportsCaseResolutionsStore={caseReportsCaseResolutionsStore}
              caseReportsFilterSelectionsStore={
                caseReportsFilterSelectionsStore
              }
              handleSetTableCollapsed={handleSetTableCollapsed}
              tableCollapsed={tableCollapsed}
            />
            <CaseReportsChartAndTableViewContainer />
          </div>
        </section>
      </CaseReportsContext.Provider>
    </Authenticated>
  )
}

CaseReportsRootView.propTypes = {
  ...routerAndQueryPropTypes,
}

export default withRouterAndQuery(CaseReportsRootView)
