import React from 'react'
import { AsidePanel } from '../../ui'
import styles from './index.module.scss'
import ReportSettingsFiltersContainer from '../ReportSettingsFiltersContainer'
import CaseDetailFiltersContainer from '../CaseDetailFiltersContainer'
import UserFiltersContainer from '../UserFiltersContainer'
import ReportFilterActionsContainer from '../ReportFilterActionsContainer'
import classnames from 'classnames'
import PrintableFiltersContainer from '../PrintableFiltersContainer'

const CaseReportsFilters = () => (
  <>
    <AsidePanel
      actionNavHeight
      title="Cases"
      disableAnimation
      disableClose
      isOpen
      onClose={() => {}}
      className={classnames('forScreen', styles.aside)}
    >
      <form
        name="lastpass-disable-search"
        onSubmit={e => e.preventDefault()}
        className={styles.form}
      >
        <ReportSettingsFiltersContainer />
        <CaseDetailFiltersContainer />
        <UserFiltersContainer />
      </form>
      <ReportFilterActionsContainer />
    </AsidePanel>

    <div className="forPrint">
      <PrintableFiltersContainer />
      <span className={styles.horizontalRule}></span>
    </div>
  </>
)

CaseReportsFilters.displayName = 'CaseReportsFilters'
export default CaseReportsFilters
