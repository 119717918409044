import React from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import { Cell } from 'fixed-data-table-2'
import { FullName } from 'common'

const PatientName = ({ rows, rowKey, rowIndex }) => {
  const patient = rows[rowIndex][rowKey]
  if (patient) {
    const { firstName, middleName, lastName } = patient
    if (!firstName && !middleName && !lastName) return null
    return (
      <Cell>
        <FullName person={patient} lastNameFirst />
      </Cell>
    )
  }
  return null
}

PatientName.propTypes = {
  rows: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      inputPatient: PropTypes.shape({
        firstName: PropTypes.string,
        middleName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      matchedPatient: PropTypes.shape({
        firstName: PropTypes.string,
        middleName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    })
  ),
  rowIndex: PropTypes.number,
  rowKey: PropTypes.string,
}

PatientName.defaultProps = {
  rows: [
    {
      inputPatient: null,
      matchedPatient: null,
    },
  ],
  rowIndex: null,
  rowKey: null,
}

export default PatientName
