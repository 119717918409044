import {
  DIVERSION_PRODUCT,
  GRC_PRODUCT,
  PRIVACY_PRODUCT,
  SingletonStore,
  validateEmail,
} from 'common'
import { action, computed, decorate, observable, reaction } from 'mobx'
import { compare } from '../../../../utils/compare'
const crypto = require('crypto')

class CustomerAdministeredAccountsEditUserStore extends SingletonStore {
  constructor({
    alertCategoryGroupStore,
    allUsersStore,
    appRolesStore,
    approvedDomainsStore,
    appUserClient,
    customerAdministeredAccountsListStore,
    halUtils,
    loginClient,
    loginStore,
    userAccountLimitSettingsStore,
    permissionStore,
  }) {
    super()

    this.alertCategoryGroupStore = alertCategoryGroupStore
    this.allUsersStore = allUsersStore
    this.appUserClient = appUserClient
    this.appRolesStore = appRolesStore
    this.approvedDomainsStore = approvedDomainsStore
    this.customerAdministeredAccountsListStore = customerAdministeredAccountsListStore
    this.halUtils = halUtils
    this.loginClient = loginClient
    this.loginStore = loginStore
    this.userAccountLimitSettingsStore = userAccountLimitSettingsStore
    this.permissionStore = permissionStore

    this.refresh()
    this.initializeEditedFields()

    this.disposers = [
      reaction(
        () => this.user,
        () => {
          this.initializeEditedFields() // Initialize the edited fields once the user is loaded
        }
      ),
    ]
  }

  tearDown = () => {
    this.disposers.forEach(d => d())
  }

  // Fields
  _userId
  _editedFields
  _saveError

  // Computed
  get user() {
    return this.result
  }

  get userId() {
    return this._userId
  }

  get saveError() {
    return this._saveError
  }

  get editedFields() {
    return this._editedFields ?? {}
  }

  get availableRoles() {
    // If the current user is ADMIN then simply return all roles. Otherwise, we will return a set of available roles
    // given the current user's product access and the roles available for those products.

    if (this.loginStore.roles.includes('ADMIN')) return this.appRolesStore.roles

    const rolesByProduct = this.appRolesStore.rolesByProduct
    const userProducts = this.permissionStore.getProducts()

    const availableRoles = [...rolesByProduct.General]
    if (userProducts.includes(PRIVACY_PRODUCT))
      availableRoles.push(...rolesByProduct[PRIVACY_PRODUCT])
    if (userProducts.includes(DIVERSION_PRODUCT))
      availableRoles.push(...rolesByProduct[DIVERSION_PRODUCT])
    if (userProducts.includes(GRC_PRODUCT))
      availableRoles.push(...rolesByProduct[GRC_PRODUCT])

    const excludedRoles = ['ADMIN', 'BETA', 'CASE BUILDER']
    if (!this.loginStore.permissions.includes('EPCS_VIEW')) {
      excludedRoles.push('EPCS')
    }

    return availableRoles
      .filter(role => !excludedRoles.includes(role.name))
      .filter(
        (role, index, arr) => arr.findIndex(x => x.name === role.name) === index
      )
      .sort((a, b) => compare(a.name, b.name))
  }

  get isUserLocked() {
    return (this.user?.enabled && this.user?.attemptsLeft === 0) ?? false
  }

  get isSavedUserEnabled() {
    return this.user?.enabled ?? false
  }

  get isSavedUserTriage() {
    return this.user?.triage ?? false
  }

  get isFormValid() {
    return !!(
      this.editedFields.email?.trim() || this.editedFields.username?.trim()
    )
  }

  get licenseLimit() {
    return this.userAccountLimitSettingsStore.userAccountLimitSettings
  }

  get licensesRemaining() {
    return this.licenseLimit - this.allUsersStore.licenseUsers.length
  }

  get licenseLimitReachedFromEdit() {
    // If we have seats, we are good to go.
    if (this.licensesRemaining > 0) return false

    // If the user is disabled or being disabled, they are good to go.
    if (!this.editedFields.enabled) return false

    // If neither the email nor enabled is changing, they are good to go.
    if (
      this.user?.email === this.editedFields.email &&
      this.user?.enabled === this.editedFields.enabled
    )
      return false

    // We have no more seats, the edited enabled value is true, and now we just
    // need to check if the email is not protenus.
    const editedEmail = this.editedFields.email
    const isProtenusEmail =
      !!editedEmail &&
      validateEmail(editedEmail) &&
      this.approvedDomainsStore.isProtenusDomain(editedEmail)

    return !isProtenusEmail
  }

  get emailValidityProperties() {
    const email = this.editedFields.email

    // email is invalid if it's not already saved as invalid and it is set and it is either invalid by regex or by approved domains store
    const isSavedUserDomainInvalid =
      (this.user &&
        !this.approvedDomainsStore.isApprovedDomain(this.user.email)) ??
      false
    const isEmailRegexInvalid = !validateEmail(email)
    const isEmailDomainInvalid =
      !!email && !this.approvedDomainsStore.isApprovedDomain(email)
    const emailErrorMessage =
      email && !isSavedUserDomainInvalid
        ? isEmailRegexInvalid
          ? 'is not valid'
          : isEmailDomainInvalid
          ? 'does not belong to the list of allowed domains'
          : null
        : null

    return {
      isSavedUserDomainInvalid,
      isEmailInvalid: isEmailRegexInvalid || isEmailDomainInvalid,
      emailErrorMessage,
    }
  }

  get isFormDirty() {
    return (
      (this.editedFields.firstName ?? '') !== (this.user?.firstName ?? '') ||
      (this.editedFields.lastName ?? '') !== (this.user?.lastName ?? '') ||
      (this.editedFields.username ?? '') !== (this.user?.username ?? '') ||
      this.editedFields.password !== '' ||
      (this.editedFields.email ?? '') !== (this.user?.email ?? '') ||
      (this.editedFields.enabled ?? false) !== (this.user?.enabled ?? false) ||
      (this.editedFields.roles?.sort().join(',') ?? '') !==
        (this.user?.roles?.sort().join(',') ?? '') ||
      (this.editedFields.groups?.sort().join(',') ?? '') !==
        (this.user?.groups?.sort().join(',') ?? '') ||
      (this.editedFields.triage ?? false) !== (this.user?.triage ?? false)
    )
  }

  // Actions
  setUserId = userId => {
    this._userId = userId
    this.refresh()
  }

  setField = (fieldName, value) => {
    if (fieldName === 'groups' && value.some(group => group.id === 'ALL')) {
      value = this.alertCategoryGroupStore.groups.slice()
    }

    this._editedFields = {
      ...this._editedFields,
      [fieldName]: value,
    }
  }

  sendWelcomeEmail = () => {
    return this.loginClient.resetPassword(this.user.email, true)
  }

  unlockAccount = () => {
    return this.appUserClient
      .update(this.halUtils.getSelf(this.user), {
        attemptsLeft: null,
      })
      .then(() => {
        this.refreshStores()
      })
  }

  resetMFA = () => {
    return this.appUserClient
      .update(this.halUtils.getSelf(this.user), {
        mfaEnabled: null,
        totpSecret: null,
      })
      .then(() => {
        this.refreshStores()
      })
  }

  save = () => {
    const payload = this.buildUserPayload()

    let promise
    if (this.user) {
      promise = this.appUserClient.update(
        this.halUtils.getSelf(this.user),
        payload
      )
    } else {
      promise = this.appUserClient.create(payload)
    }

    return promise
      .then(() => {
        this._saveError = undefined
        this.refreshStores()

        if (!this.user) {
          this.initializeEditedFields()
        }

        if (this.userId === this.loginStore.id) {
          this.loginStore.renew()
        }
      })
      .catch(data => {
        if (data.status === 409) {
          this._saveError = 'A user with this username or email already exists.'
        } else {
          this._saveError = data.responseText
        }
      })
  }

  // Helpers/Overrides
  fetch() {
    if (this._userId) return this.appUserClient.get(this._userId)
  }

  initializeEditedFields() {
    this._editedFields = {
      firstName: this.user?.firstName ?? '',
      lastName: this.user?.lastName ?? '',
      username: this.user?.username ?? '',
      password: '',
      email: this.user?.email ?? '',
      roles: this.user?.roles ?? [],
      groups: this.user?.groups ?? [],
      enabled: this.user?.enabled ?? false,
      triage: this.user?.triage ?? false,
    }
  }

  buildUserPayload() {
    const payload = {
      email: this.editedFields.email.trim(),
      enabled: this.editedFields.enabled,
      firstName: this.editedFields.firstName.trim(),
      groups: this.editedFields.groups.map(g => g.id),
      lastName: this.editedFields.lastName.trim(),
      triage: this.editedFields.triage,
      roles: this.editedFields.roles.slice(),
      username: this.editedFields.username.trim()
        ? this.editedFields.username.trim()
        : this.editedFields.email.trim(),
    }

    const generatePassword = (
      length = 16,
      wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$'
    ) =>
      Array.from(crypto.randomFillSync(new Uint8Array(length)))
        .map(x => wishlist[x % wishlist.length])
        .join('')

    if (this.editedFields.password) {
      payload.newPassword = this.loginStore.samlConfiguration?.required
        ? generatePassword()
        : this.editedFields.password
    }

    if (!payload.email) delete payload.email

    return payload
  }

  refreshStores() {
    this.allUsersStore.refresh()
    this.customerAdministeredAccountsListStore.refresh()
    this.refresh()
  }
}

decorate(CustomerAdministeredAccountsEditUserStore, {
  _editedFields: observable,
  _userId: observable,
  _saveError: observable,
  availableRoles: computed,
  editedFields: computed,
  emailValidityProperties: computed,
  isFormDirty: computed,
  isFormValid: computed,
  isSavedUserEnabled: computed,
  isSavedUserTriage: computed,
  isUserLocked: computed,
  licenseLimit: computed,
  licensesRemaining: computed,
  licenseLimitReachedFromEdit: computed,
  saveError: computed,
  user: computed,
  userId: computed,
  resetMFA: action,
  save: action,
  sentWelcomeEmail: action,
  setField: action,
  setUserId: action,
  unlockAccount: action,
})

export { CustomerAdministeredAccountsEditUserStore }
