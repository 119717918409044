import { computed, decorate, reaction } from 'mobx'
import moment from 'moment'
import {
  LoginStore,
  PermissionStore,
  CaseLogClient,
  SingletonStore,
  HalUtils,
} from 'common'
import CaseStore from '../CaseStore'
import { resolutionDescriptionDisplayName } from '../../../utils/resolutionDescriptions'

export const constructResolutionLogText = (
  logParameters,
  getDisplayName = resolutionDescriptionDisplayName
) => {
  const parameters = { ...logParameters }
  const { moreLikeThis, resolution, resolutionDescription } = parameters

  let resolutionLogText = ''
  const showResolutionDescription =
    resolutionDescription && resolutionDescription[0]
  const isViolation = resolution && resolution[0] === 'a violation'
  const isNotViolation = resolution && resolution[0] === 'not a violation'
  const moreLikeThisExists = moreLikeThis && moreLikeThis[0]

  if (showResolutionDescription && (isViolation || isNotViolation))
    resolutionLogText = ` (${getDisplayName(resolutionDescription[0])})`
  else if (moreLikeThisExists && isNotViolation) {
    resolutionLogText = ` (${
      moreLikeThis[0] === 'false' ? 'False Positive' : 'Good Catch'
    })`
  }
  return resolutionLogText
}

class CaseLogStore extends SingletonStore {
  constructor() {
    super()
    reaction(
      () => [CaseStore.case],
      () => this.refresh()
    )
  }

  // Computed
  get logs() {
    return HalUtils.getData(this.result)
  }

  get resolveLog() {
    return this.mostRecentOfType('RESOLVE') || {}
  }

  get assessmentLog() {
    return (
      this.mostRecentOfType('EDIT_ASSESSMENT') ||
      this.mostRecentOfType('CREATE_ASSESSMENT') ||
      {}
    )
  }

  get analyticAssessmentLogs() {
    return this.logs.filter(l => l.action === 'ADD_ANALYTIC_ASSESSMENT')
  }

  get lastSyncLog() {
    return this.mostRecentOfType('EXTERNAL_SYNC') || {}
  }

  get syncLogs() {
    return this.logs.filter(log => log.action === 'EXTERNAL_SYNC')
  }

  get lastSyncDate() {
    return moment(this.lastSyncLog.created).format('LLL')
  }

  fetch() {
    if (!CaseStore.caseId) return []
    else {
      return CaseLogClient.findByCase(CaseStore.caseId, 10000, 'created,desc')
    }
  }

  canDelete(log) {
    if (PermissionStore.has('CASE_COMMENT_DELETE')) {
      return true
    } else if (
      LoginStore.username === log.createdBy.username &&
      PermissionStore.has('CASE_COMMENT_DELETE_CREATED_BY')
    ) {
      return true
    } else return false
  }

  delete(log) {
    return CaseLogClient.deleteComment(HalUtils.getSelf(log)).then(
      this.refresh.bind(this)
    )
  }

  comment(text) {
    return CaseLogClient.createComment(CaseStore.caseId, text).then(
      this.refresh.bind(this)
    )
  }

  mostRecentOfType(type) {
    return this.logs
      .filter(log => log.action === type)
      .sort((a, b) => {
        if (a.lastModified > b.lastModified) return -1
        if (a.lastModified < b.lastModified) return 1
        return 0
      })[0]
  }
}

decorate(CaseLogStore, {
  analyticAssessmentLogs: computed,
  assessmentLog: computed,
  lastSyncLog: computed,
  lastSyncDate: computed,
  logs: computed,
  resolveLog: computed,
  syncLogs: computed,
})

export default new CaseLogStore()
