import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import classnames from 'classnames'
import Anchor from '../../ui/Anchor'
import { ArrowSpinner } from '../../ui/Spinner'
import {
  SAI_360_INTEGRATION,
  RADAR_INTEGRATION,
} from '../../settings/stores/CaseIntegrationStore/const'

const CaseIntegrationSync = ({
  externalId,
  lastSyncDate,
  radarLink,
  sync,
  isSyncing,
  syncLoadingMsg,
  integrationType,
  integrationLabel,
}) => {
  const isSAI360 = integrationType === SAI_360_INTEGRATION
  const isRadar = integrationType === RADAR_INTEGRATION

  return (
    <section id="case-integration-sync">
      <div className="item">
        <h5>{integrationLabel}</h5>
        <ul className="list-unstyled">
          <li data-cy="integration-create-incident">
            {!externalId ? (
              <Anchor
                id="create-case-external-id"
                tag="span"
                onClick={isSyncing ? null : sync}
                disabled={isSyncing}
              >
                {isSyncing ? syncLoadingMsg : 'Create Now'}
                {isSyncing && <ArrowSpinner />}
              </Anchor>
            ) : isSAI360 ? (
              <p>Created</p>
            ) : isRadar ? (
              <Anchor
                id="open-radar-case-link"
                tag="a"
                href={radarLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {externalId}
              </Anchor>
            ) : (
              <p>{externalId}</p>
            )}
          </li>
        </ul>
      </div>

      {externalId && (
        <div className="item">
          <div className={styles.flexHeader}>
            <div data-cy="integration-sync-incident">
              <h5>Last Sync</h5>
              <h5
                id="sync-now-link"
                onClick={sync}
                className={classnames('prot-a', styles.syncNowLink)}
              >
                {isSyncing ? '' : 'Sync Now'}
              </h5>
              <div className={styles.clear}>
                {isSyncing ? syncLoadingMsg : lastSyncDate}
                {isSyncing && <ArrowSpinner />}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

CaseIntegrationSync.propTypes = {
  externalId: PropTypes.string,
  lastSyncDate: PropTypes.string,
  radarLink: PropTypes.string,
  sync: PropTypes.func.isRequired,
  isSyncing: PropTypes.bool,
  syncLoadingMsg: PropTypes.string,
  integrationType: PropTypes.string,
  integrationLabel: PropTypes.string,
}

export default CaseIntegrationSync
