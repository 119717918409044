import { action, computed, decorate, observable, reaction } from 'mobx'
import { convertMultiValue, CaseClient, SingletonStore } from 'common'

class PrivacyStatementCaseStore extends SingletonStore {
  constructor() {
    super()

    reaction(
      () => [this.createdAfter, this.createdBefore, this.group],
      () => {
        this.refresh()
      },
      { fireImmediately: true }
    )
  }

  // Observables
  createdAfter
  createdBefore
  group

  // Action
  setDateRange(start, end) {
    this.createdAfter = start
    this.createdBefore = end
  }

  // Action
  setGroup(group) {
    this.group = group
  }

  // Computed
  get cases() {
    return this.result || []
  }

  fetch() {
    // wait for date range to be initialized
    if (!this.createdAfter || !this.createdBefore) return Promise.resolve([])

    const p = {
      createdAfter: this.createdAfter,
      createdBefore: this.createdBefore,
      sort: ['created,desc'],
    }

    if (this.group) p.group = convertMultiValue(this.group)

    return CaseClient.getStatementCases(p)
  }
}

decorate(PrivacyStatementCaseStore, {
  cases: computed,
  createdAfter: observable,
  createdBefore: observable,
  group: observable,
  setDateRange: action,
  setGroup: action,
})

export default new PrivacyStatementCaseStore()
