import React from 'react'
import PropTypes from 'prop-types'
import NavBarItem from '../../navbar/NavBarItem'
import { DropdownList } from 'react-widgets'
import DropdownItem from './DropdownItem'
import { handleOnChange, hasThisOption } from './helpers'
import styles from './index.module.scss'
import {
  filterOptionsPropType,
  filterOptionsPropTypeDefaults,
} from './propTypes'

const VipsFilters = ({
  advancedFiltersOpen,
  statusFilterOptions,
  createdByFilterOptions,
  lastModifiedByFilterOptions,
  query,
  historyPush,
}) => {
  const renderFilter = opts => {
    const { param, label, options } = opts
    const value = {
      label,
      name:
        hasThisOption({ filterOpt: query[param], options })?.name ||
        options[0].name,
    }

    return (
      <div className={styles.filterListWrapper} key={label}>
        <div className={styles.list} data-cy="vip--filter">
          <NavBarItem className={styles.listItem}>
            <DropdownList
              className={styles.rwDropdownOverrides}
              data={options}
              valueField="value"
              textField="name"
              value={value}
              onChange={val =>
                handleOnChange({
                  key: param,
                  opt: val,
                  queryCriteria: query,
                  historyPush,
                })
              }
              valueComponent={DropdownItem}
            />
          </NavBarItem>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.filterListWrapper}>
      {!advancedFiltersOpen ? (
        <>
          {renderFilter(statusFilterOptions)}
          {renderFilter(createdByFilterOptions)}
          {renderFilter(lastModifiedByFilterOptions)}
        </>
      ) : null}
    </div>
  )
}

VipsFilters.propTypes = {
  advancedFiltersOpen: PropTypes.bool,
  statusFilterOptions: filterOptionsPropType,
  createdByFilterOptions: filterOptionsPropType,
  lastModifiedByFilterOptions: filterOptionsPropType,
  query: PropTypes.shape({}),
  historyPush: PropTypes.func.isRequired,
}

VipsFilters.defaultProps = {
  advancedFiltersOpen: false,
  statusFilterOptions: filterOptionsPropTypeDefaults,
  createdByFilterOptions: filterOptionsPropTypeDefaults,
  lastModifiedByFilterOptions: filterOptionsPropTypeDefaults,
  query: {},
}

VipsFilters.displayName = 'VipsFilters'

export default VipsFilters
