import React from 'react'
import PropTypes from 'prop-types'
import { observer, PropTypes as mobxPropTypes } from 'mobx-react'
import { computed, decorate } from 'mobx'
import moment from 'moment'

import { HalUtils } from 'common'
import { Card, CardHeader } from '../../ui/Card'

const AuthorizationLI = observer(
  class AuthorizationLI extends React.Component {
    static propTypes = {
      data: PropTypes.shape({
        requestedEnd: PropTypes.string,
        revocationDate: PropTypes.string,
        start: PropTypes.string,
        authorizationType: PropTypes.string,
        user: PropTypes.shape({
          userId: PropTypes.string,
          dateOfBirth: PropTypes.string,
        }),
        patient: PropTypes.shape({
          dateOfBirth: PropTypes.string,
          medicalRecordNumbers: mobxPropTypes.arrayOrObservableArrayOf(
            PropTypes.shape({})
          ),
        }),
      }),
    }
    // Computed
    get startDate() {
      return this.formatDate(this.props.data.start)
    }

    // Computed
    get endDate() {
      const data = this.props.data
      const { requestedEnd, revocationDate } = data
      const end = revocationDate || requestedEnd

      return end ? this.formatDate(end) : <em>Indefinite</em>
    }

    unknown(str) {
      return str || <em>Unknown</em>
    }

    formatDate(date) {
      if (date) return moment(date).format('l')
      return this.unknown(date)
    }

    formatBirthdate(date) {
      if (date)
        return moment(date)
          .utc()
          .format('l')
      return this.unknown(date)
    }

    renderTitle() {
      const data = this.props.data
      const patient = data.patient
      const user = data.user
      const patientName = this.renderName(patient)
      const userName = this.renderName(user)

      return (
        <span>
          {patientName} authorized {userName} from {this.startDate} &ndash;{' '}
          {this.endDate}
        </span>
      )
    }

    renderName(model) {
      return (
        <span>
          {this.unknown(model.firstName)} {this.unknown(model.lastName)}
        </span>
      )
    }

    render() {
      const data = this.props.data
      const { authorizationType, user, patient } = data
      const link = `/authorizations/${HalUtils.getId(data)}`

      let mrn
      if (patient.medicalRecordNumbers) {
        if (patient.medicalRecordNumbers.length === 1)
          mrn = patient.medicalRecordNumbers[0]
        else mrn = `${patient.medicalRecordNumbers.length} MRNs`
      }

      return (
        <li>
          <Card className="auth fadeIn">
            <CardHeader title={this.renderTitle()} link={link}>
              <small className="label label-secondary">
                {authorizationType === 'TEMPORARY_PERMISSION'
                  ? 'Temporary Permission'
                  : 'Authorization'}
              </small>
            </CardHeader>
            <ul className="card__meta-container">
              <li className="card__meta-item">
                <h5>Patient DOB</h5>
                <p>{this.formatBirthdate(patient.dateOfBirth)}</p>
              </li>
              <li className="card__meta-item">
                <h5>Patient MRN</h5>
                <p>{this.unknown(mrn)}</p>
              </li>
              <li className="card__meta-item">
                <h5>EMR User DOB</h5>
                <p>{this.formatBirthdate(user.dateOfBirth)}</p>
              </li>
              <li className="card__meta-item">
                <h5>User ID</h5>
                <p>{this.unknown(user.userId)}</p>
              </li>
            </ul>
          </Card>
        </li>
      )
    }
  }
)

decorate(AuthorizationLI, {
  startDate: computed,
  endDate: computed,
})

AuthorizationLI.displayName = 'AuthorizationLI'

export default AuthorizationLI
