import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';

import {
  Authenticated,
  Pandy,
  FlashBannerStore,
  RouterContainer,
} from 'common';

import AuthorizationsStore from '../stores/AuthorizationsStore';
import TemporaryPermissionsSettingsStore from '../../stores/TemporaryPermissionsSettingsStore';

import RegistryContainer from '../../ui/registry/RegistryContainer';
import List from '../List';
import ActionNav from '../../navbar/ActionNav';

const AuthListView = observer(
  class AuthListView extends React.Component {
    constructor(props) {
      super(props);
      autorun(() => {
        const { store: { failure } = {} } = AuthorizationsStore;
        if (failure) FlashBannerStore.add(failure, 'danger');
        else this.setFilters(this.props);
      });
    }

    static propTypes = {
      match: PropTypes.shape({}).isRequired,
      location: PropTypes.shape({}).isRequired,
    };

    componentWillUnmount() {
      AuthorizationsStore.reset();
    }

    setFilters(props) {
      const tempPermissions = props.match.path === '/authorizations/temporary';
      AuthorizationsStore.setFilters(
        RouterContainer.parseParams(props.location.search),
        tempPermissions
      );
    }

    downloadCSV = e => {
      e.preventDefault();
      AuthorizationsStore.downloadCSV();
    };

    renderPandy() {
      if (AuthorizationsStore.temporaryPermissions) {
        let message;
        if (TemporaryPermissionsSettingsStore.enabled)
          message = (
            <p>We couldn&rsquo;t find any Temporary Permissions for you.</p>
          );
        else
          message = (
            <p>
              It looks like you have Temporary Permission toggled off right now.
              Visit <Link to="/settings/general">Settings</Link> to enable.
            </p>
          );

        return (
          <Pandy
            visible={
              !AuthorizationsStore.loading && AuthorizationsStore.size === 0
            }
            mood="happy"
          >
            <div className="speechBubble">
              <span>No Temporary Permissions. Sorry!</span>
            </div>
            {message}
          </Pandy>
        );
      }

      return (
        <Pandy
          visible={
            !AuthorizationsStore.loading && AuthorizationsStore.size === 0
          }
          mood="happy"
        >
          <div className="speechBubble">
            <span>No Authorizations. Sorry!</span>
          </div>
          <p>We couldn&rsquo;t find any Authorized Accesses for you.</p>
          <Authenticated permission="ACCESS_AUTH_CREATE">
            <Link to="/authorizations/create" className="button">
              Add an Authorization
            </Link>
          </Authenticated>
        </Pandy>
      );
    }

    render() {
      const {
        fullName,
        patientId,
        temporaryPermissions,
        userId,
      } = AuthorizationsStore;

      const title = temporaryPermissions
        ? 'Temporary Permission'
        : 'Authorization';
      const header = temporaryPermissions
        ? 'Temporary Permissions'
        : 'Authorized Accesses';
      const createPath = temporaryPermissions
        ? undefined
        : '/authorizations/create';

      return (
        <React.Fragment>
          <ActionNav
            secondary={false}
            clearFilter={() => RouterContainer.go(undefined, {})}
            createIcon="add_circle"
            createLabel="Add New Authorization"
            createPath={createPath}
            createPermission={['ACCESS_AUTH_CREATE']}
            count={AuthorizationsStore.totalElements}
            downloadCSV={this.downloadCSV}
            title={title}
            user={userId ? fullName : undefined}
            patient={patientId ? fullName : undefined}
          />
          <Authenticated permission="ACCESS_AUTH_SEARCH">
            <RegistryContainer
              store={AuthorizationsStore}
              title={header}
              className="registry"
            >
              {this.renderPandy()}
              <List
                store={AuthorizationsStore}
                title={header}
                data={AuthorizationsStore.results}
              />
            </RegistryContainer>
          </Authenticated>
        </React.Fragment>
      );
    }
  }
);

AuthListView.displayName = 'AuthListView';

export default AuthListView;
