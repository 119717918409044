import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ValueOrUnknown from '../ValueOrUnknown';

const FormattedEncounterDate = ({ encounter }) => {
  const time = encounter && (encounter.startTime || encounter.endTime);
  return (
    <ValueOrUnknown>
      {time &&
        moment(time)
          .format('l h:mm A')
          .replace(/ 0?0:00 [AP]M/, '')}
    </ValueOrUnknown>
  );
};

FormattedEncounterDate.propTypes = {
  encounter: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
};

FormattedEncounterDate.defaultProps = {
  encounter: null,
};

export default FormattedEncounterDate;
