import React, { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import styles from './index.module.scss'
import { Table, Column, Cell } from 'fixed-data-table-2'

import { DateCell } from '../../ui'
import ActionCell from './cells/ActionCell'
import LinkCell from './cells/LinkCell'

const HEIGHT = 400
const ROW_HEIGHT = 50
const tablePadding = 50

const AuditLogTable = ({ data, tablePager }) => {
  const table = useRef()
  const [width, setWidth] = useState(1)

  const setSize = useCallback(() => {
    const node = table.current
    setWidth(node.offsetParent.offsetWidth - tablePadding)
  }, [])

  useEffect(() => {
    setSize()
    window.addEventListener('resize', setSize)

    return () => {
      window.removeEventListener('resize', setSize)
    }
  }, [setSize])

  const pager = (h, v) => {
    const remain = data.length * ROW_HEIGHT - v
    if (remain < 2000) tablePager()
  }

  const setCellWidth = percentage => width * (percentage / 100)

  return (
    <div ref={table} className={styles.tableContainer}>
      <Table
        className={styles.table}
        rowsCount={data.length}
        rowHeight={ROW_HEIGHT}
        headerHeight={50}
        width={width}
        height={HEIGHT}
        onScrollStart={pager}
        onScrollEnd={pager}
        overflowX="hidden"
      >
        <Column
          header={() => <Cell>Date</Cell>}
          width={setCellWidth(8)}
          cell={({ rowIndex }) => <DateCell date={data[rowIndex].created} />}
        />
        <Column
          header={() => <Cell>Time</Cell>}
          width={setCellWidth(8)}
          cell={({ rowIndex }) => (
            <DateCell date={data[rowIndex].created} format="LTS" />
          )}
        />
        <Column
          header={() => <Cell>Protenus User</Cell>}
          width={setCellWidth(10)}
          cell={({ rowIndex }) => (
            <Cell>{data[rowIndex].createdBy?.fullName}</Cell>
          )}
        />
        <Column
          header={() => <Cell>Action</Cell>}
          width={setCellWidth(8)}
          cell={({ rowIndex }) => <Cell>{data[rowIndex].action}</Cell>}
        />
        <Column
          header={() => <Cell>IP</Cell>}
          width={setCellWidth(8)}
          cell={({ rowIndex }) => <Cell>{data[rowIndex].remoteAddr}</Cell>}
        />
        <Column
          width={setCellWidth(20)}
          header={<Cell>URL</Cell>}
          cell={({ rowIndex }) => {
            const link = data[rowIndex].referrer?.split('#')[1]
            return <LinkCell to={link} />
          }}
        />
        <Column
          width={setCellWidth(8)}
          header={<Cell>Action</Cell>}
          cell={({ rowIndex }) => (
            <ActionCell
              path={data[rowIndex].servletPath}
              method={data[rowIndex].method}
            />
          )}
        />
        <Column
          header={() => <Cell>Path</Cell>}
          width={setCellWidth(30)}
          cell={({ rowIndex }) => (
            <Cell className={styles.doubleLine}>
              {data[rowIndex].servletPath}
            </Cell>
          )}
        />
      </Table>
    </div>
  )
}

AuditLogTable.propTypes = {
  data: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.shape({
      created: PropTypes.string,
      createdBy: PropTypes.shape({}),
      duration: PropTypes.number,
      method: PropTypes.string,
      referrer: PropTypes.string,
      remoteAddr: PropTypes.string,
      servletPath: PropTypes.string,
    })
  ),
  tablePager: PropTypes.func,
}

AuditLogTable.defaultProps = {
  data: [],
  tablePager: () => {},
}

export default AuditLogTable
