import {
  ASSESSMENT_TIME_RANGES_ALL_LABEL,
  ASSESSMENT_TIME_RANGES_LABEL,
  ASSESSMENTS_ALL,
  ASSESSMENTS_CUSTOM,
  ASSESSMENTS_NONE,
} from './consts'
import moment from 'moment'
import { computed, decorate, observable, action } from 'mobx'

class AssessmentsPrintOptionsStore {
  constructor({ assessmentsFromCase, caseType }) {
    this.assessmentsFromCase = assessmentsFromCase
    this.type = caseType
    this.hasClusterReasons = assessmentsFromCase
      .map(a => a.clusterReasons?.length > 0)
      .includes(true)
  }
  // observables
  selectedMainOption = ASSESSMENTS_ALL
  selectedAssessmentsTimeRanges = []
  type = this.type
  hasClusterReasons = this.hasClusterReasons

  // statics
  allMainOptions = [
    { name: 'All', value: ASSESSMENTS_ALL },
    { name: 'Custom', value: ASSESSMENTS_CUSTOM },
    { name: 'None', value: ASSESSMENTS_NONE },
  ]

  // cleanup
  teardown = () => {
    this.selectedMainOption = ASSESSMENTS_ALL
    this.selectedAssessmentsTimeRanges = []
  }

  get mainOptions() {
    if ((this.assessmentsFromCase || []).length <= 1)
      return this.allMainOptions.filter(
        ({ value }) => value !== ASSESSMENTS_CUSTOM
      )
    return this.allMainOptions
  }

  get timeRangeOptions() {
    return (this.assessmentsFromCase || []).map(
      ({ minEventDate, startTime, maxEventDate, endTime }) => ({
        name: `${moment(minEventDate || startTime).format('l')} - ${moment(
          maxEventDate || endTime
        ).format('l')}`,
        value: `${moment(minEventDate || startTime).toISOString()},${moment(
          maxEventDate || endTime
        ).toISOString()}`,
      })
    )
  }

  get isCustomizing() {
    return this.selectedMainOption === ASSESSMENTS_CUSTOM
  }

  get timeRangesLabel() {
    return (this.selectedAssessmentsTimeRanges || []).length
      ? ASSESSMENT_TIME_RANGES_LABEL
      : ASSESSMENT_TIME_RANGES_ALL_LABEL
  }

  get filteredAssessmentsForPrint() {
    const {
      selectedMainOption,
      selectedAssessmentsTimeRanges,
      assessmentsFromCase,
    } = this
    if (selectedMainOption === ASSESSMENTS_ALL) return assessmentsFromCase
    if (selectedMainOption === ASSESSMENTS_NONE) return []
    if (!selectedAssessmentsTimeRanges.length) return assessmentsFromCase

    return (assessmentsFromCase || []).filter(assessment => {
      return selectedAssessmentsTimeRanges
        .map(range => range.value)
        .find(range => {
          const ranges = range ? range.split(',') : []
          return (
            moment(
              assessment.minEventDate || assessment.startTime
            ).isSameOrAfter(ranges[0]) &&
            moment(
              assessment.maxEventDate || assessment.endTime
            ).isSameOrBefore(ranges[1])
          )
        })
    })
  }

  get filteredAssessmentsCount() {
    return (this.filteredAssessmentsForPrint || []).length
  }

  handleMainOptionOnChange = opt => (this.selectedMainOption = opt.value)

  handleTimeRangesOnChange = values =>
    (this.selectedAssessmentsTimeRanges = values)
}

decorate(AssessmentsPrintOptionsStore, {
  selectedMainOption: observable,
  selectedAssessmentsTimeRanges: observable,
  type: observable,
  hasClusterReasons: observable,
  mainOptions: computed,
  timeRangeOptions: computed,
  isCustomizing: computed,
  timeRangesLabel: computed,
  filteredAssessmentsForPrint: computed,
  filteredAssessmentsCount: computed,
  handleMainOptionOnChange: action,
  handleTimeRangesOnChange: action,
})

export { AssessmentsPrintOptionsStore }
