import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as mobxPropTypes } from 'mobx-react'
import { groupPropType } from '../GroupQuickFilter/propTypes'
import queryString from 'query-string'
import { DateHelpers } from 'common'
import TimePlusCalendarSelector from '../TimePlusCalendarSelector'
import Tooltip from '../Tooltip'
import ExclusionMultiselect from '../Filters/ExclusionMultiselect'
import ExclusionDropdown from '../Filters/ExclusionDropdown'
import styles from './index.module.scss'
import FilterSectionHeader from '../FilterSectionHeader'
import {
  DIVERSION,
  FALSE_POSITIVE,
  GOOD_CATCH,
  POLICY,
  PRIVACY,
} from '../../utils/resolutionDescriptions'

const {
  TIME_PERIOD_VALUES: {
    ALL,
    DAY,
    WEEK,
    MONTH,
    NINETY_DAYS,
    QUARTER,
    YEAR,
    CUSTOM,
  },
} = DateHelpers

/**
 * Sort action options such that deprecated ones appear last.
 *
 * @param actions {Object[]} Action options.
 * @return {Object[]} Sorted action options.
 */
function sortActions(actions) {
  return actions
    .slice()
    .sort((action1, action2) =>
      action1.deprecated === action2.deprecated
        ? 0
        : action1.deprecated
        ? 1
        : -1
    )
}

const CaseDetailFilters = ({
  history,
  location,
  query,
  onMount,
  queryForToggle,
  queryForSelectionChange,
  canToggleOwner,
  caseOwnerOptions,
  caseOwnerSelections,
  caseOwnerExcluded,
  caseCreatedByOptions,
  caseCreatedBySelections,
  caseCreatedByExcluded,
  groupOptions,
  groupSelections,
  groupExcluded,
  handleUpdateQuickFilterOptions,
  rememberFilters,
  savedQuickFilterOptions,
  caseTypeOptions,
  caseTypeSelections,
  caseTypeExcluded,
  caseTypeExclusionDisabled,
  caseBundleOptions,
  caseBundleSelections,
  caseBundleExcluded,
  resolutionOptions,
  resolutionSelections,
  resolutionExcluded,
  resolutionDescriptionOptions,
  resolutionDescriptionSelections,
  resolutionDescriptionExcluded,
  resolvedByOptions,
  resolvedBySelections,
  resolvedByExcluded,
  caseActionOptions,
  caseActionSelections,
  caseActionExcluded,
  caseActionDateRangeSelection,
  caseActionDateAfter,
  caseActionDateBefore,
  suspicionScoreOptions,
  suspicionScoreSelection,
  suspicionScoreExcluded,
  disableAllExclusion,
}) => {
  const navigateOnChange = newQuery => {
    history.push({
      pathname: location.pathname,
      search: queryString.stringify({ ...query, ...newQuery }),
    })
  }

  useEffect(() => {
    // onMount changes on each render because it's an anonymous function, but we
    // only want to call it on the first mount.
    onMount()
    // apply any saved groups if they were passed as props
    if (!rememberFilters && savedQuickFilterOptions?.length) {
      handleUpdateQuickFilterOptions(
        null,
        'savedOptions',
        { ...query, group: savedQuickFilterOptions },
        location.pathname,
        history.replace
      )
    }
    // eslint-disable-next-line
  }, [])

  /**
   * Helper method to filter the resolution description options by the resolutions currently selected
   * in the filter.
   */
  const filterResolutionDescriptionOptions = () => {
    let options = [...resolutionDescriptionOptions]
    const hasViolationFilter = resolutionSelections.find(
      res => res.id === 'VIOLATION'
    )
    const hasNonViolationFilter = resolutionSelections.find(
      res => res.id === 'NOT_VIOLATION'
    )

    if (!resolutionExcluded) {
      if (!hasNonViolationFilter) {
        options = options.filter(
          option => ![FALSE_POSITIVE, GOOD_CATCH].includes(option.id)
        )
      }

      if (!hasViolationFilter) {
        options = options.filter(
          option => ![DIVERSION, POLICY, PRIVACY].includes(option.id)
        )
      }
    } else {
      if (hasNonViolationFilter) {
        options = options.filter(
          option => ![FALSE_POSITIVE, GOOD_CATCH].includes(option.id)
        )
      }

      if (hasViolationFilter) {
        options = options.filter(
          option => ![DIVERSION, POLICY, PRIVACY].includes(option.id)
        )
      }
    }

    return options
  }

  const filteredResolutionDescriptionOptions = filterResolutionDescriptionOptions()

  return (
    <section>
      <FilterSectionHeader icon="icon-assignment" title="Case Detail" />
      <ul className="caseDetailFilters">
        {/*Case Owner*/}
        <ExclusionMultiselect
          label="Case Owner"
          options={caseOwnerOptions}
          valueField="id"
          textField="name"
          value={caseOwnerSelections}
          isExcluding={caseOwnerExcluded}
          onChange={values => {
            navigateOnChange(queryForSelectionChange('owner', values))
          }}
          onToggleExclusion={() => {
            navigateOnChange(queryForToggle('owner'))
          }}
          disabled={!canToggleOwner}
          hideExclusion={disableAllExclusion}
        />

        {/*Case Creator*/}
        <ExclusionMultiselect
          label="Case Creator"
          options={caseCreatedByOptions}
          value={caseCreatedBySelections}
          isExcluding={caseCreatedByExcluded}
          onChange={values => {
            navigateOnChange(queryForSelectionChange('createdBy', values))
          }}
          onToggleExclusion={() => {
            navigateOnChange(queryForToggle('createdBy'))
          }}
          textField="name"
          valueField="id"
          hideExclusion={disableAllExclusion}
        />

        {/*Group*/}
        {groupOptions.length > 1 && (
          <ExclusionMultiselect
            label="Group"
            options={groupOptions}
            value={groupSelections}
            isExcluding={groupExcluded}
            onChange={values => {
              navigateOnChange(queryForSelectionChange('group', values))
            }}
            onToggleExclusion={() => {
              navigateOnChange(queryForToggle('group'))
            }}
            textField="name"
            valueField="id"
            hideExclusion={disableAllExclusion}
          />
        )}

        {/*Case Type (alert category)*/}
        <ExclusionMultiselect
          label="Case Type"
          options={caseTypeOptions}
          value={caseTypeSelections}
          isExcluding={caseTypeExcluded}
          onChange={values => {
            navigateOnChange(queryForSelectionChange('category', values))
          }}
          onToggleExclusion={() => {
            navigateOnChange(queryForToggle('category'))
          }}
          textField="name"
          valueField="id"
          hideExclusion={caseTypeExclusionDisabled || disableAllExclusion}
          multiselectProps={{
            itemComponent: CategoryItemComponent,
            tagComponent: CategoryItemComponent,
          }}
        />

        {/*Case Bundle*/}
        <ExclusionMultiselect
          label="Case Bundle"
          options={caseBundleOptions}
          value={caseBundleSelections}
          isExcluding={caseBundleExcluded}
          onChange={values => {
            navigateOnChange(queryForSelectionChange('bundle', values))
          }}
          onToggleExclusion={() => {
            navigateOnChange(queryForToggle('bundle'))
          }}
          textField="name"
          valueField="id"
          inclusionTerm={
            <>
              Case <strong>Is In </strong>bundle
            </>
          }
          exclusionTerm={
            <>
              Case <strong className="text-danger">Is Not In </strong> Bundle
            </>
          }
          hideExclusion={disableAllExclusion}
        />

        <ExclusionMultiselect
          label="Resolution"
          options={resolutionOptions}
          value={resolutionSelections}
          isExcluding={resolutionExcluded}
          onChange={values => {
            navigateOnChange(queryForSelectionChange('resolution', values))
          }}
          onToggleExclusion={() => {
            navigateOnChange(queryForToggle('resolution'))
          }}
          textField="name"
          valueField="id"
          hideExclusion={disableAllExclusion}
          data-cy="resolution--exclusion-multiselect"
        />

        <ExclusionMultiselect
          label="Resolution Description"
          disabled={filteredResolutionDescriptionOptions.length === 0}
          options={filteredResolutionDescriptionOptions}
          value={resolutionDescriptionSelections}
          isExcluding={resolutionDescriptionExcluded}
          onChange={values => {
            navigateOnChange(
              queryForSelectionChange('resolutionDescription', values)
            )
          }}
          onToggleExclusion={() => {
            navigateOnChange(queryForToggle('resolutionDescription'))
          }}
          textField="name"
          valueField="id"
          hideExclusion={disableAllExclusion}
        />

        {/*Resolved By*/}
        <ExclusionMultiselect
          label="Resolved By"
          options={resolvedByOptions}
          value={resolvedBySelections}
          isExcluding={resolvedByExcluded}
          onChange={values => {
            navigateOnChange(queryForSelectionChange('resolvedBy', values))
          }}
          onToggleExclusion={() => {
            navigateOnChange(queryForToggle('resolvedBy'))
          }}
          textField="name"
          valueField="id"
          inclusionTerm={
            <>
              Case <strong>Was </strong>Resolved By
            </>
          }
          exclusionTerm={
            <>
              Case <strong className="text-danger">Was Not </strong>Resolved By
            </>
          }
          hideExclusion={disableAllExclusion}
        />

        {/*Case actions*/}
        {caseActionOptions.length > 0 && (
          <>
            <ExclusionMultiselect
              label="Actions Taken"
              options={sortActions(caseActionOptions)}
              value={caseActionSelections}
              isExcluding={caseActionExcluded}
              onChange={values => {
                navigateOnChange(
                  queryForSelectionChange('actionsTaken', values)
                )
              }}
              onToggleExclusion={() => {
                navigateOnChange(queryForToggle('actionsTaken'))
              }}
              textField="name"
              valueField="id"
              inclusionTerm="Were"
              exclusionTerm="Were Not"
              groupBy={action => action.deprecated}
              groupComponent={({ item }) => {
                return item ? <span>{'Deprecated Actions'}</span> : null
              }}
              hideExclusion={disableAllExclusion}
            />

            {/*Actions taken during*/}
            <Tooltip
              content={
                caseActionExcluded
                  ? 'Cannot filter with exclusive case actions'
                  : null
              }
              placement="right"
            >
              <div className={styles.withoutExclusion}>
                <TimePlusCalendarSelector
                  createdInformation={[
                    caseActionDateAfter,
                    caseActionDateBefore,
                  ]}
                  label="Actions Taken During"
                  filterKey="actionsDate"
                  defaultValue={caseActionDateRangeSelection}
                  onCalendarChange={(
                    date,
                    valid,
                    beforeOrAfter,
                    filterKeyRoot
                  ) => {
                    if (valid) {
                      navigateOnChange(
                        queryForSelectionChange(
                          `${filterKeyRoot}${beforeOrAfter}`,
                          date
                        )
                      )
                    }
                  }}
                  onChangeDropdown={value => {
                    navigateOnChange(
                      queryForSelectionChange('actionsDateRange', value)
                    )
                  }}
                  hiddenCalendar={caseActionDateRangeSelection === CUSTOM}
                  disabled={caseActionExcluded}
                  options={[
                    ALL,
                    DAY,
                    WEEK,
                    MONTH,
                    NINETY_DAYS,
                    QUARTER,
                    YEAR,
                    CUSTOM,
                  ]}
                />
              </div>
            </Tooltip>
          </>
        )}

        {/*Suspicion score (single dropdown)*/}
        <ExclusionDropdown
          label="Suspicion Score"
          options={suspicionScoreOptions}
          value={suspicionScoreOptions.find(
            ({ value }) => value === suspicionScoreSelection
          )}
          isExcluding={suspicionScoreExcluded}
          onChange={value => {
            navigateOnChange(queryForSelectionChange('suspicionScore', value))
          }}
          onToggleExclusion={() => {
            navigateOnChange(queryForToggle('suspicionScore'))
          }}
          textField="label"
          valueField="value"
          hideExclusion={disableAllExclusion}
          data-cy="cases--adv-filters--suspicion-score"
        />
      </ul>
    </section>
  )
}

CaseDetailFilters.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
  query: PropTypes.object.isRequired,
  onMount: PropTypes.func.isRequired,

  queryForToggle: PropTypes.func.isRequired,
  queryForSelectionChange: PropTypes.func.isRequired,
  canToggleOwner: PropTypes.bool.isRequired,
  caseOwnerOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  caseOwnerSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  caseOwnerExcluded: PropTypes.bool,
  caseCreatedByOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  caseCreatedBySelections: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.object
  ).isRequired,
  caseCreatedByExcluded: PropTypes.bool,
  groupOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  groupSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  groupExcluded: PropTypes.bool.isRequired,
  handleUpdateQuickFilterOptions: PropTypes.func,
  rememberFilters: PropTypes.bool,
  savedQuickFilterOptions: groupPropType,
  caseTypeOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  caseTypeSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  caseTypeExcluded: PropTypes.bool,
  caseBundleOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  caseBundleSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  caseBundleExcluded: PropTypes.bool,
  resolutionOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  resolutionSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  resolutionExcluded: PropTypes.bool,
  resolutionDescriptionOptions: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.object
  ),
  resolutionDescriptionSelections: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.object
  ),
  resolutionDescriptionExcluded: PropTypes.bool,
  resolvedByOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  resolvedBySelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  resolvedByExcluded: PropTypes.bool,
  caseActionOptions: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  caseActionSelections: mobxPropTypes.arrayOrObservableArrayOf(PropTypes.object)
    .isRequired,
  caseActionExcluded: PropTypes.bool,
  caseActionDateRangeSelection: PropTypes.string,
  caseActionDateAfter: PropTypes.string,
  caseActionDateBefore: PropTypes.string,
  suspicionScoreOptions: mobxPropTypes.arrayOrObservableArrayOf(
    PropTypes.object
  ).isRequired,
  suspicionScoreSelection: PropTypes.string,
  suspicionScoreExcluded: PropTypes.bool,
  disableAllExclusion: PropTypes.bool,
  caseTypeExclusionDisabled: PropTypes.bool,
}

CaseDetailFilters.defaultProps = {
  groupOptions: [],
  groupSelections: [],
}

export default CaseDetailFilters

export function CategoryItemComponent({ item }) {
  if (!item) return null

  if (item.group) {
    return (
      <span>
        <strong>{item.group}:</strong> {item.name}
      </span>
    )
  }

  return <span>{item.name}</span>
}

CategoryItemComponent.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    group: PropTypes.string,
  }),
}
